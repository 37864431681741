import { urlWithQueryParams } from 'commons/src/api/fetch';
import { Resolution } from 'commons/src/models/commonEnums';
import { AggregationDigest } from '../models/eventAggregationModels';

export const fetchSpaceAggregation = async (
    spaceId: string,
    params: { from: string; to: string; resolution?: Resolution }
): Promise<AggregationDigest> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const url = urlWithQueryParams({
        path: `/aggregates/spaces/${spaceId}`,
        ...params,
    });
    // Api currently unavailable.
    return Promise.resolve({ digests: [] });
};

export default fetchSpaceAggregation;
