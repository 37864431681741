import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { getOrganizationMembers } from '../../../actions/organizationMemberActions';
import { Store } from '../../../reducers';
import InviteUserForm from './InviteUserForm';
import MemberList from './MemberList';

type Props = {
    ownRole?: string;
};

const UserManagement = ({ ownRole }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (ownRole) {
            dispatch(getOrganizationMembers());
        }
    }, [ownRole]);

    const { t: txt } = useTranslation();
    const roleAdminOrAbove = ownRole && userRoleAboveRequiredLevel(ownRole as Role, RequiredRoleLevel.ABOVE_ADMIN);

    return (
        <div>
            {roleAdminOrAbove && (
                <>
                    <h2 className="settings__header">{txt('AddUser')}</h2>
                    <InviteUserForm inModalView={false} />
                </>
            )}
            <MemberList />
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { selectedGroup },
    } = store;

    return {
        ownRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(UserManagement);
