import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import SubHeader from 'commons/src/components/headers/SubHeader';
import InputInfo from 'commons/src/components/input/InputInfo';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { businessPaths as paths, roleRestrictions } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { togglePartnerBranding } from '../../actions/partnerActions';
import { Store } from '../../reducers';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';
import CustomerTable from './customerList/CustomerTable';
import styles from './PartnerOverview.module.scss';

type Props = {
    customerListHasData: boolean;
    loading: boolean;
    partnerBranding: boolean;
    brandingLoading: boolean;
    brandingError?: ErrorType;
    logoImage?: string;
    error?: ErrorType;
};

const searchWordParam = 'searchWord';

const PartnerOverview = ({
    loading,
    partnerBranding,
    brandingError,
    brandingLoading,
    logoImage,
    error,
    customerListHasData,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState('');
    const [brandingOn, setBrandingOn] = useState(partnerBranding);
    const [downloadCustomerModalOpen, setDownloadCustomerModalOpen] = useState(false);
    const [brandingModalOpen, setBrandingModalOpen] = useState(false);
    const [newCustomerAdded, setNewCustomerAdded] = useState('');

    const onSearchUpdate = (text: string): void => {
        const textToLowerCase = text.trim().toLowerCase();
        setSearchText(textToLowerCase);
    };

    useEffect(() => {
        const customerAdded = searchParams.get('customerUserGroupId');
        if (customerAdded) setNewCustomerAdded(customerAdded);
        searchParams.delete('customerUserGroupId');
        setSearchParams(searchParams);
    }, []);

    useEffect((): void => {
        if (!brandingLoading && brandingOn !== partnerBranding) {
            setBrandingOn(partnerBranding);
        }
    }, [brandingLoading, brandingError, partnerBranding]);

    useEffect(() => {
        setSearchText(searchParams.get(searchWordParam) || '');
    }, [searchParams]);

    const toggleBranding = (): void => {
        if (logoImage || brandingOn) {
            setBrandingOn(!brandingOn);
            dispatch(togglePartnerBranding(!brandingOn));
        } else {
            setBrandingModalOpen(true);
        }
    };

    const actionButtons: ActionButton[] = [
        {
            testAttr: 'partner-download-customers',
            title: 'DownloadCSVTitle',
            icon: <MaterialIcon id="icon" name="download" />,
            id: 'partnerDownloadCustomers',
            color: 'secondary',
            onClick: (): void => setDownloadCustomerModalOpen(true),
            requiredRoleLevel: roleRestrictions.partner,
            requiredGroupTypes: [],
        },
        {
            testAttr: 'partner-add-customer',
            title: 'Partner.AddCustomer',
            id: 'partnerAddCustomer',
            color: 'primary',
            icon: <MaterialIcon name="add" />,
            onClick: (): void => navigate({ pathname: `/${paths.partnerAddCustomer}` }),
            requiredRoleLevel: roleRestrictions.partnerAddCustomer,
            requiredGroupTypes: [],
        },
    ];
    const redirectToAccountSettings = (): void => {
        navigate({ pathname: `/${paths.organization}` });
    };
    const closeBrandingModal = (): void => setBrandingModalOpen(false);

    return (
        <div className="partner-portal container">
            {brandingModalOpen && (
                <DeleteConfirmModal
                    title="Partner.NoLogoUploaded"
                    description={
                        <p className="modal__content__description text-medium">
                            {txt('Partner.UploadLogoInAccountSettings')}
                        </p>
                    }
                    onSubmit={redirectToAccountSettings}
                    onCancel={closeBrandingModal}
                    onSubmitText="Account"
                    onCancelText="Cancel"
                    loading={false}
                    regularSubmitButton
                />
            )}
            <SubHeader actionButtons={actionButtons} onSearchUpdate={onSearchUpdate} />
            <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                <div className={styles.brandingBar}>
                    <div className={styles.brandingDescription}>
                        {txt('Partner.Branding')}
                        <InputInfo infoText="Partner.BrandingDescription" infoDirectionDown />
                    </div>
                    <FlipButton onClick={toggleBranding} leftSelected={brandingOn} leftText="On" rightText="Off" />
                </div>
                {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
                {(!error || customerListHasData) && (
                    <CustomerTable
                        newCustomerId={newCustomerAdded}
                        searchWord={searchText}
                        downloadModalOpen={downloadCustomerModalOpen}
                        setDownloadModalOpen={setDownloadCustomerModalOpen}
                    />
                )}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { loading: userSettingsLoading },
        organizationProperties: { logoImage },
        requests: {
            [RequestType.GetOrganizationLogo]: { loading: getLogoLoading },
            [RequestType.FetchPartnerDetails]: { loading, error },
            [RequestType.TogglePartnerBranding]: { loading: brandingLoading, error: brandingError },
        },
        partner: { partnerBranding, customers },
    } = store;

    const customerList = customers || [];
    return {
        loading: (loading || userSettingsLoading || getLogoLoading) && customerList.length === 0,
        error,
        partnerBranding,
        brandingLoading,
        brandingError,
        logoImage,
        customerListHasData: customerList.length > 0,
    };
};

export default connect(mapStateToProps)(PartnerOverview);
