import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ALERT_CLICKED_ADD, ALERT_TOGGLED_ALERT } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userlane } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import IntegrationRow from 'commons/src/features/integrations/IntegrationRow';
import { ErrorType, Units } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { updateNotificationAlertActiveState } from '../../actions/thirdPartyIntegrationActions';
import { paths } from '../../constants';
import { ThirdPartyIntegrationAlert, ThirdPartyIntegrationAlertRule } from '../../models/common';
import { Store } from '../../reducers';
import IntegrationLogo from './IntegrationLogo';

export type Props = {
    notificationAlerts: ThirdPartyIntegrationAlert[];
    loading: boolean;
    error?: ErrorType;
    units: Units;
};

export const AlertsOverviewComponent = ({ notificationAlerts, loading, error, units }: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    if (error) {
        return <Error />;
    }

    const clickAddAlert = (): void => {
        navigate({ pathname: `/${paths.addAlert}` });
        analyticsLogger(ALERT_CLICKED_ADD, { pageType: PageType.Alert });
    };

    const editAlert = (id: string): void => {
        navigate({ pathname: `/${paths.alerts}/alert/${id}` });
    };

    const alertSensors = (rules: ThirdPartyIntegrationAlertRule[]): string =>
        [...new Set(rules.map(rule => txt(rule.sensorType)))].join(', ');

    const sortedAlerts = notificationAlerts.sort((alert1, alert2) => alert1.name.localeCompare(alert2.name));

    const alertRows = (): React.ReactElement[] =>
        sortedAlerts.map(alert => {
            const toggleActive = (active: boolean): void => {
                const { integrationType, integrationId, ...trigger } = alert;
                const payload = {
                    integrationType,
                    triggerId: alert.id,
                    integrationId,
                    trigger: { ...trigger, active, units },
                };
                analyticsLogger(ALERT_TOGGLED_ALERT, { pageType: PageType.Alert, toogle: active });
                dispatch(updateNotificationAlertActiveState(payload));
            };
            const integrationLogo = <IntegrationLogo integrationType={alert.integrationType} />;
            return (
                <IntegrationRow
                    key={alert.id}
                    name={alert.name}
                    id={alert.id}
                    active={alert.active}
                    firstRowLogo={integrationLogo}
                    updateIntegration={toggleActive}
                    onClick={editAlert}
                    firstRow={alertSensors(alert.rules)}
                />
            );
        });

    const actionButtons: ActionButton[] = [
        {
            onClick: clickAddAlert,
            title: 'NotificationAlerts.AddAlert',
            color: 'primary',
            testAttr: 'add-alert',
            id: 'addAlert',
            icon: <MaterialIcon name="add" />,
            requiredRoleLevel: roleRestrictions.alerts,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div>
            <SubHeader actionButtons={notificationAlerts.length < 30 ? actionButtons : []} />
            <h2 className="settings__header settings__header--no-margin-top">{txt('NotificationAlerts.SetupTitle')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                <Trans i18nKey="NotificationAlerts.SetupDescription">
                    <Link to={`/${paths.thirdParty}`}>{txt('Integrations.Integrations')}</Link>
                </Trans>
            </div>
            <ul className="list">
                <ReactPlaceHolder ready={sortedAlerts.length > 0 || !loading} customPlaceholder={userlane}>
                    {alertRows()}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        thirdPartyIntegrations: { notificationAlerts },
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading, error },
        },
        userSettings: { units },
    } = state;

    return {
        notificationAlerts,
        loading,
        error,
        units,
    };
};

export default connect(mapStateToProps)(AlertsOverviewComponent);
