import React, { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import TimePeriodSelector from 'commons/src/components/buttons/TimePeriodSelector';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import styles from './GraphActions.module.scss';
import useSpaceGraphActions from './useGraphActions';

type Props = {
    fetchDigestForPeriod: (period: SelectedPeriod) => void;
    fetchDataForPeriod: (period: SelectedPeriod) => void;
    selectedPeriod: SelectedPeriod;
    setSelectedPeriod: (period: SelectedPeriod) => void;
    spaceId: string;
    locationId: string;
    isFocusView: boolean;
    onChangeView: () => void;
};

const SpaceGraphActions = ({
    fetchDigestForPeriod,
    fetchDataForPeriod,
    selectedPeriod,
    setSelectedPeriod,
    spaceId,
    locationId,
    isFocusView,
    onChangeView,
}: Props): ReactElement => {
    const { spaceStartDate, timezone, dateFormat, selectGraphPeriod, onRefreshData } = useSpaceGraphActions({
        fetchDigestForPeriod,
        fetchDataForPeriod,
        setSelectedPeriod,
        selectedPeriod,
        spaceId,
        locationId,
    });

    // on redirect with graph time range in query params (used in redirects from heating cooling);
    const [params] = useSearchParams();
    const from = params.get('from');
    const to = params.get('to');

    return (
        <div className={styles.topBar}>
            <div className={styles.timeSelector}>
                <TimePeriodSelector
                    limitTimeSelector={false}
                    selectGraphPeriod={selectGraphPeriod}
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormat}
                    isMobile={false}
                    loading={false}
                    endedSegment={false}
                    segmentStartDate={spaceStartDate}
                    timeZone={timezone}
                    from={from}
                    to={to}
                />
            </div>
            <div className={styles.rightAligned}>
                <div className={styles.flipButton}>
                    <FlipButton
                        id="space-view"
                        onClick={onChangeView}
                        leftSelected={isFocusView}
                        leftText="Space.Focus"
                        rightText="Space.List"
                        testIdOff="toggle-space-plan-view"
                        testIdOn="toggle-space-view"
                    />
                </div>

                <TertiaryButton
                    onClick={onRefreshData}
                    title="Space.FetchLatestData"
                    icon={<MaterialIcon name="refresh" />}
                />
            </div>
        </div>
    );
};

export default SpaceGraphActions;
