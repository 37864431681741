import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { roleRestrictions } from 'commons/src/constants';
import { TabOption } from 'commons/src/models/menuModels';
import { fetchThirdPartyIntegrations } from '../../actions/thirdPartyIntegrationActions';
import { paths } from '../../constants';
import { Store } from '../../reducers';

type Props = {
    loadingThirdPartyIntegrations: boolean;
};

export const AlertsPageLayoutComponent = ({ loadingThirdPartyIntegrations }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect((): void => {
        if (!loadingThirdPartyIntegrations) {
            dispatch(fetchThirdPartyIntegrations());
        }
    }, []);

    const tabs: TabOption[] = [
        {
            text: 'NotificationAlerts.MyAlerts',
            id: 'myAlerts',
            testAttr: 'my-alerts',
            path: `/${paths.alerts}`,
            requiredRoleLevel: roleRestrictions.alerts,
            route: paths.alerts,
            requiredGroupTypes: [],
        },
        {
            text: 'NotificationAlerts.History',
            path: `/${paths.history}`,
            id: 'alertHistory',
            testAttr: 'alert-history',
            requiredRoleLevel: roleRestrictions.alerts,
            route: paths.history,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt('NotificationAlerts.Alerts')} tabs={tabs} />
            <div className="page-wrapper__medium">
                <Outlet />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations },
        },
    } = state;
    return {
        loadingThirdPartyIntegrations,
    };
};

export default connect(mapStateToProps)(AlertsPageLayoutComponent);
