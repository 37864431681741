import React, { KeyboardEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setMeasurementSystem } from 'commons/src/commonFunctions';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import CheckBox from 'commons/src/components/input/Checkbox';
import Input from 'commons/src/components/input/Input';
import InputLabel from 'commons/src/components/input/InputLabel';
import { IntercomAPI } from 'commons/src/components/Intercom';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import UnitSelector from 'commons/src/components/units/UnitSelector';
import { Units } from 'commons/src/models/commonTypeScript';
import { addWebhook, deleteWebhook, testWebhook, updateWebhook } from '../../../actions/integrationActions';
import { KeyValuePairType, Webhook, WebhookEventTypes } from '../../../models/common';
import { Store } from '../../../reducers';
import { removeEmptyKeyValuePair } from '../../../sagas/segmentPropertiesSaga';
import { filterWebhookEventTypes } from './filterWebhookEventTypes';
import LocationsSelector from './LocationsSelector';
import MapInput, { pairsWithError } from './MapInput';
import WebhookEvents from './WebhookEvents';

export type Props = {
    units: Units;
    webhooks: Webhook[];
    deleteLoading: boolean;
    saveLoading: boolean;
    testWebhookLoading: boolean;
};

export const WebhookFormComponent = ({
    units,
    webhooks,
    deleteLoading,
    saveLoading,
    testWebhookLoading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const { webhookId } = useParams<'webhookId'>();

    const eventTypes = filterWebhookEventTypes(false);
    const webhookToEdit = webhookId && webhooks.length > 0 ? webhooks.find(hook => hook.id === webhookId) : undefined;
    const [name, setName] = useState(webhookToEdit ? webhookToEdit.name : '');
    const [url, setUrl] = useState(webhookToEdit ? webhookToEdit.url : '');
    const getSensorUnits = (): Units => {
        if (webhookToEdit && webhookToEdit.sensorUnits) return webhookToEdit.sensorUnits;
        return {
            tempUnit: units.tempUnit,
            radonUnit: units.radonUnit,
            pressureUnit: units.pressureUnit,
            vocUnit: units.vocUnit,
        };
    };

    const [sensorUnits, setSensorUnits] = useState(getSensorUnits());
    const [measurementUnit] = useState(
        webhookToEdit && webhookToEdit.measurementSystem ? webhookToEdit.measurementSystem : setMeasurementSystem(units)
    );
    const [labels, setLabels] = useState<KeyValuePairType[]>(webhookToEdit ? webhookToEdit.webhookLabels : []);
    const [headers, setHeaders] = useState<KeyValuePairType[]>(webhookToEdit ? webhookToEdit.webhookHeaders : []);
    const [active, setActive] = useState(webhookToEdit ? webhookToEdit.active : false);
    const [selectedLocations, setSelectedLocations] = useState<string[]>(webhookToEdit ? webhookToEdit.locations : []);
    const [subscribedToAll, setSubscribedToAll] = useState(webhookToEdit ? webhookToEdit.subscribedToAll : false);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [selectedEventTypes, setSelectedEventTypes] = useState<WebhookEventTypes[]>(
        webhookToEdit && webhookToEdit.eventTypes !== undefined ? webhookToEdit.eventTypes : eventTypes
    );

    const updateInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value, id } = e.currentTarget;
        if (id === 'webhook-name') setName(value);
        if (id === 'webhook-url') setUrl(value);
    };
    const updateActive = (): void => {
        setActive(!active);
    };

    const validHookUrl = url.indexOf('https://') === 0;
    const validatePairs = (pairs: KeyValuePairType[], hasHttpHeaderKey: boolean): boolean => {
        let lastPairIsEmpty = false;
        let pairsCopy: KeyValuePairType[] = [];
        if (pairs.length > 0) {
            const lastPair = pairs[pairs.length - 1];
            lastPairIsEmpty = lastPair.key.length === 0 && lastPair.value.length === 0;
            pairsCopy = [...pairs];
            pairsCopy.pop();
        }
        const pairToValidate = lastPairIsEmpty ? pairsCopy : pairs;
        return pairsWithError(pairToValidate, hasHttpHeaderKey);
    };

    const validateWebhook = (): boolean => {
        const validName = name.length > 0;
        const validEventType = selectedEventTypes.length > 0;
        const invalidLabelPairs = validatePairs(labels, false);
        const invalidHeaderPairs = validatePairs(headers, true);
        if (validHookUrl && validName && validEventType && !invalidHeaderPairs && !invalidLabelPairs) {
            return true;
        }
        setDisplayValidation(true);
        return false;
    };

    const saveWebhook = (): void => {
        const hookIsValid = validateWebhook();
        if (hookIsValid) {
            const updatedWebhookDetails = {
                name,
                url,
                measurementSystem: measurementUnit,
                sensorUnits,
                webhookLabels: removeEmptyKeyValuePair(labels),
                webhookHeaders: removeEmptyKeyValuePair(headers),
                active,
                subscribedToAll,
                locations: subscribedToAll ? [] : selectedLocations,
                eventTypes: selectedEventTypes,
            };
            if (webhookToEdit) {
                dispatch(updateWebhook({ ...webhookToEdit, ...updatedWebhookDetails }));
            } else {
                IntercomAPI('trackEvent', 'add-webhook', {
                    webhookName: name,
                });
                dispatch(addWebhook(updatedWebhookDetails));
            }
        }
    };
    const onDeleteClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (webhookToEdit && webhookToEdit.id) {
            setDisplayDeleteModal(true);
        }
    };

    const closeDeleteModal = (): void => setDisplayDeleteModal(false);
    const onDeleteHook = (): void => {
        if (webhookToEdit && webhookToEdit.id) {
            dispatch(deleteWebhook(webhookToEdit.id));
        }
    };

    const onTestHook = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (webhookId) dispatch(testWebhook(webhookId));
    };

    const onToggleEventType = (event: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>): void => {
        const type = event.currentTarget.id as WebhookEventTypes;

        const filteredEventTypes = selectedEventTypes.filter(it => it !== type);
        if (filteredEventTypes.length === selectedEventTypes.length)
            setSelectedEventTypes([...selectedEventTypes, type]);
        else setSelectedEventTypes(filteredEventTypes);
    };

    const webhookEventTypeCheckboxes = (): React.ReactElement[] =>
        eventTypes.map(type => (
            <CheckBox
                label={type}
                onChange={onToggleEventType}
                checked={selectedEventTypes.includes(type)}
                key={`webhook-type-${type}`}
                id={type}
                testId={type}
            />
        ));

    const headerKeyHint = headers.some(header => header.key.includes('_'));

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            {displayDeleteModal && (
                <DeleteConfirmModal
                    title="Webhooks.DeleteWebhook"
                    description={txt('Webhooks.DeleteDescription', { name })}
                    onSubmit={onDeleteHook}
                    onCancel={closeDeleteModal}
                    onSubmitText="Delete"
                    onCancelText="Cancel"
                    loading={deleteLoading}
                />
            )}
            <div className="settings-details-container webhooks-form">
                <form>
                    <div className="form__row">
                        <div className="form__field">
                            <Input
                                type="text"
                                id="webhook-name"
                                validate={displayValidation && name.length === 0}
                                isValid={name.length > 0}
                                hint="Webhooks.NameHint"
                                label="Name"
                                maxLength={25}
                                currentValue={name}
                                onChange={updateInput}
                                testId="webhook-name"
                            />
                        </div>
                        <div className="form__field">
                            {webhookToEdit && (
                                <div className="input-container">
                                    <InputLabel htmlFor="webhook-id" label="Id" />
                                    <div className="form__attr input-container__string" id="webhook-name">
                                        {webhookToEdit.id}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field">
                            <Input
                                type="text"
                                id="webhook-url"
                                validate={displayValidation && !validHookUrl}
                                isValid={validHookUrl}
                                label="Webhooks.Url"
                                hint="Webhooks.UrlHint"
                                currentValue={url}
                                maxLength={255}
                                onChange={updateInput}
                                testId="webhook-url"
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__field">
                            <div className="form__attr form__attr--element form__attr--element--full-width">
                                <InputLabel htmlFor="deviceSelector" label="Webhooks.SelectLocations" />
                                <LocationsSelector
                                    setSelectedLocations={setSelectedLocations}
                                    selectedLocations={selectedLocations}
                                    subscribedToAll={subscribedToAll}
                                    setSubscribedToAll={setSubscribedToAll}
                                />
                            </div>
                        </div>
                        <div className="form__field">
                            <UnitSelector sensorUnits={sensorUnits} setSensorUnits={setSensorUnits} />
                        </div>
                    </div>
                    <div className="form__row">
                        <InputLabel
                            htmlFor="deviceSeventTypeSelectorelector"
                            label="Webhooks.EventTypes"
                            infoText="Webhooks.EventTypesInfo"
                        />
                        <div className="webhooks-form__event-types-container">{webhookEventTypeCheckboxes()}</div>
                        {displayValidation && selectedEventTypes.length === 0 && (
                            <span className="input-container__error">{txt('Webhooks.EventTypeHint')}</span>
                        )}
                    </div>

                    <div className="inline-header-lined inline-header-lined--extra-margin" />
                    <MapInput
                        id="header-selector"
                        updatePair={setHeaders}
                        pairs={headers}
                        keyHeader="Webhooks.HttpHeaderKey"
                        keyHint={headerKeyHint ? 'Webhooks.HeaderInvalidCharacter' : 'Webhooks.HeaderKeyRequiredHint'}
                        valueHeader="Webhooks.HttpHeaderValue"
                        valueHint="Webhooks.HeaderValueHint"
                        addButtonText="Webhooks.AddHeader"
                        displayValidation={displayValidation}
                        hasHttpHeaderKey
                        maxValueLength={1000}
                        maxKeyLength={25}
                    />
                    <div className="inline-header-lined inline-header-lined--extra-margin" />
                    <MapInput
                        id="label-selector"
                        updatePair={setLabels}
                        pairs={labels}
                        displayValidation={displayValidation}
                        keyHeader="CustomLabels.LabelKey"
                        keyHint="CustomLabels.LabelKeyRequiredHint"
                        valueHeader="CustomLabels.LabelValue"
                        valueHint="CustomLabels.LabelValueHint"
                        addButtonText="CustomLabels.AddLabel"
                        maxValueLength={64}
                        maxKeyLength={64}
                    />
                    <div className="inline-header-lined inline-header-lined--extra-margin" />
                    <div className="form__row form__row--centered-padded">
                        <div>
                            <InputLabel htmlFor="enable" label="Webhooks.Enable" />
                            <FlipButton
                                id="enable"
                                onClick={updateActive}
                                leftSelected={active}
                                leftText="On"
                                rightText="Off"
                            />
                        </div>
                    </div>
                    <div className="form__row form__button-container">
                        {webhookToEdit && (
                            <div className="form__attr--element centered">
                                <PrimaryButton
                                    onClick={onDeleteClick}
                                    type="button"
                                    title="Delete"
                                    color="alert"
                                    loading={deleteLoading}
                                    testAttr="add-webhook"
                                />
                            </div>
                        )}
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                id="webhook-save-button"
                                onClick={saveWebhook}
                                type="button"
                                title="Save"
                                loading={saveLoading}
                                color="primary"
                                testAttr="add-webhook"
                                testId="add-webhook"
                            />
                        </div>
                    </div>
                    {webhookToEdit && (
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                color="secondary"
                                onClick={onTestHook}
                                type="button"
                                title="Webhooks.Test"
                                testAttr="add-webhook"
                                loading={testWebhookLoading}
                            />
                        </div>
                    )}
                </form>
                {webhookToEdit && (
                    <>
                        <div className="inline-header-lined inline-header-lined--extra-margin" />
                        <WebhookEvents />
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { units },
        webhooks: { webhooks },
        requests: {
            DELETE_WEBHOOK: { loading: deleteLoading },
            UPDATE_WEBHOOK: { loading: updateLoading },
            ADD_WEBHOOK: { loading: addLoading },
            TEST_WEBHOOK: { loading: testWebhookLoading },
        },
    } = state;
    return {
        units,
        testWebhookLoading,
        webhooks,
        deleteLoading,
        saveLoading: updateLoading || addLoading,
    };
};

export default connect(mapStateToProps)(WebhookFormComponent);
