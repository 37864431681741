import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_SENSOR_DATA_EXPORT } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DatePicker from 'commons/src/components/DatePicker';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { dateFormats, deviceTypes, sensorFullNameKey, sensorOrder } from 'commons/src/constants';
import { csvSensors, csvVirtualSensors } from 'commons/src/DeviceAndSensorLists';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { downloadSensorDataCSV } from '../../../actions/downloadCSVActions';
import { CommonDevice } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import styles from './SpacesCsvModal.module.scss';

type StateProps = {
    downloadCsvLoading: boolean;
    downloadCsvError?: ErrorType;
};

type PassedProps = {
    dateFormat: keyof typeof dateFormats;
    locationId: string;
    devices: CommonDevice[];
    onClose: () => void;
};

export type Props = StateProps & PassedProps;

const SpacesCsvModal = ({
    dateFormat,
    devices,
    onClose,
    locationId,
    downloadCsvLoading,
    downloadCsvError,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [displayValidationStartDate, setDisplayValidationStartDate] = useState(false);
    const [displayValidationEndDate, setDisplayValidationEndDate] = useState(false);
    const [displayValidationSelectedSensors, setDisplayValidationSelectedSensors] = useState(false);
    const [selectedSensors, setSelectedSensors] = useState<SensorTypes[]>([]);
    const [selectedVirtualSensors, setSelectedVirtualSensors] = useState<SensorTypes[]>([]);
    const [includeEndedSegments, setIncludeEndedSegments] = useState(false);

    const onEndDateChange = (date: Moment): void => {
        const beforeStartMonth = moment(date).isBefore(moment(startDate));
        let endOfSelectedMonth = date.endOf('month');
        if (endOfSelectedMonth > moment()) endOfSelectedMonth = moment();
        setEndDate(endOfSelectedMonth);
        if (beforeStartMonth) setStartDate(undefined);

        setDisplayValidationEndDate(false);
        setDisplayValidationStartDate(false);
    };

    const onStartDateChange = (date: Moment): void => {
        const overOneYear = moment(date).startOf('month').isBefore(moment(endDate).subtract(1, 'year'));
        const afterEndMonth = moment(date).isAfter(moment(endDate));
        const startOfSelectedMonth = date.startOf('month');
        setStartDate(startOfSelectedMonth);
        if (overOneYear || afterEndMonth) setEndDate(undefined);

        setDisplayValidationStartDate(false);
        setDisplayValidationEndDate(false);
    };

    const outsideDateRange = (day: Moment): boolean => {
        return moment(day).isAfter(moment().endOf('month'));
    };

    const sensorsInBuilding: SensorTypes[] = devices
        .reduce((sensors: SensorTypes[], device) => {
            const deviceSensors = ((deviceTypes[device.deviceType].sensors as SensorTypes[]) || []).filter(
                sensor => !sensors.includes(sensor as SensorTypes)
            );
            return [...sensors, ...deviceSensors];
        }, [])
        .filter(sensor => csvSensors.includes(sensor))
        .sort((a, b) => sensorOrder.indexOf(a) - sensorOrder.indexOf(b));

    const toggleSensor = (e: SyntheticEvent<HTMLInputElement>): void => {
        const sensor = e.currentTarget.value;
        const sensorInList = selectedSensors.includes(sensor as SensorTypes);
        let updatedSensorList;
        if (sensorInList) updatedSensorList = selectedSensors.filter(sensorElement => sensorElement !== sensor);
        else updatedSensorList = [...selectedSensors, sensor as SensorTypes];
        setSelectedSensors(updatedSensorList);

        if (sensor === 'checkAllSensors') {
            if (sensorsInBuilding.length !== updatedSensorList.length - 1) setSelectedSensors(sensorsInBuilding);
            else setSelectedSensors([]);
        }
        setDisplayValidationSelectedSensors(false);
    };
    const toggleVirtualSensor = (e: SyntheticEvent<HTMLInputElement>): void => {
        const sensor = e.currentTarget.value;
        const sensorInList = selectedVirtualSensors.includes(sensor as SensorTypes);
        let updatedVirtualSensorList;
        if (sensorInList)
            updatedVirtualSensorList = selectedVirtualSensors.filter(sensorElement => sensorElement !== sensor);
        else updatedVirtualSensorList = [...selectedVirtualSensors, sensor as SensorTypes];
        setSelectedVirtualSensors(updatedVirtualSensorList);

        if (sensor === 'checkAllVirtualSensors') {
            if (csvVirtualSensors.length !== updatedVirtualSensorList.length - 1)
                setSelectedVirtualSensors(csvVirtualSensors);
            else setSelectedVirtualSensors([]);
        }
        setDisplayValidationSelectedSensors(false);
    };

    const prevLoadingRef = useRef(downloadCsvLoading);

    useEffect((): void => {
        if (prevLoadingRef.current && !downloadCsvLoading && !downloadCsvError) {
            onClose();
        }
        prevLoadingRef.current = downloadCsvLoading;
    }, [downloadCsvLoading, prevLoadingRef]);

    const renderSensorCheckbox = (
        sensors: SensorTypes[],
        selected: SensorTypes[],
        toggle: (e: SyntheticEvent<HTMLInputElement>) => void
    ): React.ReactElement[] =>
        sensors.map(type => (
            <div className={styles.checkbox} key={`sensor-option-list-${type}`}>
                <CheckBox
                    label={
                        <div className="notification-alert__icons">
                            <SensorIcon sensor={type} />
                            <div className="notification-alert__icons__text">{txt(sensorFullNameKey(type))}</div>
                        </div>
                    }
                    labelIsElement
                    key={type}
                    id={type}
                    checked={selected.includes(type)}
                    onChange={toggle}
                />
            </div>
        ));

    const validateCsvDownloadInput = (): boolean => {
        const sensorSelected = selectedSensors.length === 0 && selectedVirtualSensors.length === 0;
        const startDatePicked = startDate === undefined;
        const endDatePicked = endDate === undefined;
        const overOneYear = moment(endDate).endOf('month').isAfter(moment(startDate).add(1, 'year'));

        setDisplayValidationStartDate(startDatePicked);
        setDisplayValidationEndDate(endDatePicked || overOneYear);
        setDisplayValidationSelectedSensors(sensorSelected);
        return !sensorSelected && !startDatePicked && !endDatePicked && !overOneYear;
    };

    const downloadCSV = (): void => {
        const validInput = validateCsvDownloadInput();
        const from = moment(startDate).format('YYYY-MM-DD');
        const to = moment(endDate).format('YYYY-MM-DD');
        if (validInput) {
            dispatch(
                downloadSensorDataCSV(
                    {
                        from,
                        to,
                        sensors: selectedSensors,
                        virtualSensors: selectedVirtualSensors,
                        includeEndedSegments,
                    },
                    locationId
                )
            );
            analyticsLogger(BUILDING_SENSOR_DATA_EXPORT, {
                pageType: PageType.Building,
                from,
                to,
                selectedSensors,
                selectedVirtualSensors,
                includeEndedSegments,
                location: locationId,
                numberOfDevices: devices.length,
            });
        }
    };

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="CsvExport.ModalHeader" onClose={onClose} />
            <div className={styles.subHeader}>{txt('CsvExport.ModalSubHeader')}</div>
            <div className={styles.subHeader}>
                <MaterialIcon extraClass={styles.icon} name="info" />
                {txt('CsvExport.SubHeaderInfo')}
            </div>
            <div className="modal__content__body modal__content__body--full-width">
                <div className={styles.timeRangeBox}>
                    <div className="text-left text-paragraph text-medium">{txt('CsvExport.TimeRange')}</div>
                    <div className="radon-report__form__section__row">
                        <DatePicker
                            id="startMonth"
                            label="CsvExport.StartMonth"
                            onChange={onStartDateChange}
                            dateFormat={dateFormat}
                            selectedDate={startDate}
                            disabledDate={outsideDateRange}
                            displayDateValidation={displayValidationStartDate}
                            mandatory
                            month
                            testId="end-month"
                        />
                        <DatePicker
                            id="endMonth"
                            label="CsvExport.EndMonth"
                            onChange={onEndDateChange}
                            dateFormat={dateFormat}
                            selectedDate={endDate}
                            disabledDate={outsideDateRange}
                            displayDateValidation={displayValidationEndDate}
                            mandatory
                            month
                            testId="end-month"
                        />
                    </div>
                </div>
                <div className={styles.sensorsBox}>
                    {sensorsInBuilding && (
                        <>
                            <CheckBox
                                label="CsvExport.AllSensors"
                                id="checkAllSensors"
                                checked={sensorsInBuilding.length === selectedSensors.length}
                                onChange={toggleSensor}
                            />
                            <div className={styles.sensors}>
                                {renderSensorCheckbox(sensorsInBuilding, selectedSensors, toggleSensor)}
                            </div>
                        </>
                    )}
                    <CheckBox
                        label="CsvExport.AllVirtualSensors"
                        id="checkAllVirtualSensors"
                        checked={csvVirtualSensors.length === selectedVirtualSensors.length}
                        onChange={toggleVirtualSensor}
                    />
                    <div className={styles.sensors}>
                        {renderSensorCheckbox(csvVirtualSensors, selectedVirtualSensors, toggleVirtualSensor)}
                    </div>
                    <CheckBox
                        label="CsvExport.IncludeEndedSegments"
                        id="includeEndedSegments"
                        checked={includeEndedSegments}
                        onChange={(): void => setIncludeEndedSegments(toggle => !toggle)}
                    />
                </div>
            </div>
            <div className="modal__content__buttons">
                <PrimaryButton
                    title="CsvExport.Export"
                    loading={downloadCsvLoading}
                    onClick={(): void => downloadCSV()}
                    color="primary"
                    testId="modal-button"
                />
            </div>
            {downloadCsvError && (
                <div className={styles.errorWrapper}>
                    <ResponseBox text={`ErrorCodes.${downloadCsvError.error}`} subtext="CsvExport.CsvExportError" />
                </div>
            )}
            {displayValidationSelectedSensors && (
                <div className="error-message error-message--center">{txt('CsvExport.NoSensorSelectedError')}</div>
            )}
            <div className={styles.exportText}>
                <div className="text-center text-paragraph text-medium">{txt('CsvExport.ExportHelpText')}</div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [BusinessRequestType.DownloadSensorDataCsv]: { loading: downloadCsvLoading, error: downloadCsvError },
        },
    } = state;
    return {
        downloadCsvLoading,
        downloadCsvError,
    };
};

export default connect(mapStateToProps)(SpacesCsvModal);
