import { RequestActionType } from 'commons/src/actions/requestActions';
import { BleMode, ViewDisplayUnits } from 'commons/src/models/commonEnums';
import {
    BuildingConfiguration,
    BuildingType,
    DeviceType,
    DeviceWithKeyInfo,
    LocationDetailsType,
    UpdateBuildingConfig,
} from 'commons/src/models/commonTypeScript';
import { BusinessActionRequestType, KeyValuePairType, ResetConnection } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

export enum LocationActionType {
    FetchLocationLabels = 'FETCH_LOCATION_LABELS',
    GetLocationConfigInit = 'GET_LOCATION_CONFIG/INIT',
    UpdateLocationConfigInit = 'UPDATE_LOCATION_CONFIG/INIT',
    UpdateLocationConfigWithDeviceTypeInit = 'UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE/INIT',
    UpdateLocationLabelsInit = 'UPDATE_LOCATION_LABELS/INIT',
    FetchLocationResetConnectionsInit = 'FETCH_LOCATION_RESET_CONNECTIONS/INIT',
    FetchVirtualDevicesSuccess = 'FETCH_VIRTUAL_DEVICES/SUCCESS',
    FetchVirtualDevicesInit = 'FETCH_VIRTUAL_DEVICES/INIT',
    FetchBuildingInit = 'FETCH_BUILDING/INIT',
    FetchBuildingSuccess = 'FETCH_BUILDING_SUCCESS',
}

export enum ActionType {
    UpdateLocationDetailsInit = 'UPDATE_LOCATION_DETAILS/INIT',
}

export const FETCH_LOCATION_LABELS_SUCCESS = 'FETCH_LOCATION_LABELS_SUCCESS';
export const SET_LOCATION_LABELS_SUCCESS = 'SET_LOCATION_LABELS_SUCCESS';
export const GET_LOCATION_CONFIG_SUCCESS = 'GET_LOCATION_CONFIG_SUCCESS';
export const UPDATE_LOCATION_CONFIG_SUCCESS = 'UPDATE_LOCATION_CONFIG_SUCCESS';
export const UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS = 'UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS';
export const FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS = 'FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS';

export interface FetchLocationLabelsSuccess {
    type: typeof FETCH_LOCATION_LABELS_SUCCESS;
    locationId: string;
    labels: KeyValuePairType[];
}

export interface SetLocationLabelsSuccess {
    type: typeof SET_LOCATION_LABELS_SUCCESS;
    locationId: string;
    labels: KeyValuePairType[];
}

export interface GetLocationConfigSuccess {
    type: typeof GET_LOCATION_CONFIG_SUCCESS;
    config: BuildingConfiguration;
    locationId: string;
}

export interface GetLocationConfig {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.GetLocationConfiguration;
    type: LocationActionType.GetLocationConfigInit;
    locationId: string;
}

export interface UpdateLocationConfig {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateLocationConfiguration;
    type: LocationActionType.UpdateLocationConfigInit;
    config: UpdateBuildingConfig;
    locationId: string;
}

export interface UpdateLocationConfigWithDeviceType {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateLocationConfigurationWithDeviceType;
    type: LocationActionType.UpdateLocationConfigWithDeviceTypeInit;
    config: {
        selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
        screens?: {
            [deviceType: string]: {
                selectedScreens: { screenIndex: number; insights: string[] }[];
                measurementUnits: ViewDisplayUnits;
            };
        };
        bleMode?: BleMode;
    };
    locationId: string;
}

export interface UpdateLocationLabels {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.UpdateLocationLabels;
    type: LocationActionType.UpdateLocationLabelsInit;
    locationId: string;
    labels: KeyValuePairType[];
}

export interface UpdateLocationConfigSuccess {
    type: typeof UPDATE_LOCATION_CONFIG_SUCCESS;
    config: UpdateBuildingConfig;
    locationId: string;
}

export interface FetchLocationResetConnectionsSuccess {
    type: typeof FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS;
    resetConnections: ResetConnection[];
}

export const getLocationConfig = (locationId: string): GetLocationConfig => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.GetLocationConfiguration,
    type: LocationActionType.GetLocationConfigInit,
    locationId,
});

export const getLocationConfigSuccess = (
    config: BuildingConfiguration,
    locationId: string
): GetLocationConfigSuccess => ({
    type: GET_LOCATION_CONFIG_SUCCESS,
    config,
    locationId,
});

export const updateLocationLabels = (locationId: string, labels: KeyValuePairType[]): UpdateLocationLabels => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateLocationLabels,
    type: LocationActionType.UpdateLocationLabelsInit,
    locationId,
    labels,
});

export const updateLocationConfig = (config: UpdateBuildingConfig, locationId: string): UpdateLocationConfig => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateLocationConfiguration,
    type: LocationActionType.UpdateLocationConfigInit as LocationActionType.UpdateLocationConfigInit,
    config,
    locationId,
});

export const updateLocationConfigWithDeviceType = (
    config: {
        selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
        screens?: {
            [deviceType: string]: {
                selectedScreens: { screenIndex: number; insights: string[] }[];
                measurementUnits: ViewDisplayUnits;
            };
        };
        bleMode?: BleMode;
    },
    locationId: string
): UpdateLocationConfigWithDeviceType => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateLocationConfigurationWithDeviceType,
    type: LocationActionType.UpdateLocationConfigWithDeviceTypeInit,
    config,
    locationId,
});

export const updateLocationConfigSuccess = (
    config: UpdateBuildingConfig,
    locationId: string
): UpdateLocationConfigSuccess => ({
    type: UPDATE_LOCATION_CONFIG_SUCCESS,
    config,
    locationId,
});

export interface UpdateLocationConfigWithDeviceTypeSuccess {
    type: typeof UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS;
    config: BuildingConfiguration;
    locationId: string;
}

export const updateLocationConfigWithDeviceTypeSuccess = (
    config: BuildingConfiguration,
    locationId: string
): UpdateLocationConfigWithDeviceTypeSuccess => ({
    type: UPDATE_LOCATION_CONFIG_WITH_DEVICE_TYPE_SUCCESS,
    config,
    locationId,
});

export interface UpdateLocationDetails extends BusinessActionRequestType {
    type: ActionType.UpdateLocationDetailsInit;
    locationDetails: LocationDetailsType;
}
export const updateLocationDetails = (locationDetails: LocationDetailsType): UpdateLocationDetails => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.UpdateLocationDetails,
    type: ActionType.UpdateLocationDetailsInit as ActionType.UpdateLocationDetailsInit,
    locationDetails,
});
export interface FetchLocationLabels extends BusinessActionRequestType {
    type: LocationActionType.FetchLocationLabels;
    locationId: string;
}
export const fetchLocationLabels = (locationId: string): FetchLocationLabels => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchLocationLabels,
    type: LocationActionType.FetchLocationLabels,
    locationId,
});

export const fetchLocationLabelsSuccess = (
    locationId: string,
    labels: KeyValuePairType[]
): FetchLocationLabelsSuccess => ({
    type: FETCH_LOCATION_LABELS_SUCCESS,
    locationId,
    labels,
});

export const setLocationLabelsSuccess = (locationId: string, labels: KeyValuePairType[]): SetLocationLabelsSuccess => ({
    type: SET_LOCATION_LABELS_SUCCESS,
    locationId,
    labels,
});

export interface FetchLocationResetConnections extends BusinessActionRequestType {
    type: LocationActionType.FetchLocationResetConnectionsInit;
    locationId: string;
}
export const fetchLocationResetConnections = (locationId: string): FetchLocationResetConnections => ({
    requestActionType: RequestActionType.Init,
    requestType: RequestType.FetchLocationResetConnections,
    type: LocationActionType.FetchLocationResetConnectionsInit,
    locationId,
});

export const fetchLocationResetConnectionsSuccess = (
    resetConnections: ResetConnection[]
): FetchLocationResetConnectionsSuccess => ({
    type: FETCH_LOCATION_RESET_CONNECTIONS_SUCCESS,
    resetConnections,
});

export interface FetchVirtualDevices {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchVirtualDevices;
    type: LocationActionType.FetchVirtualDevicesInit;
    locationId: string;
}

export const fetchVirtualDevices = (locationId: string): FetchVirtualDevices => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchVirtualDevices,
    type: LocationActionType.FetchVirtualDevicesInit,
    locationId,
});

export interface FetchVirtualDevicesSuccess {
    type: LocationActionType.FetchVirtualDevicesSuccess;
    devices: { [serialNumber: string]: DeviceType };
}
export const fetchVirtualDevicesSuccess = (devices: {
    [serialNumber: string]: DeviceType;
}): FetchVirtualDevicesSuccess => ({
    type: LocationActionType.FetchVirtualDevicesSuccess,
    devices,
});

export interface FetchBuilding {
    requestActionType: RequestActionType.Init;
    requestType: RequestType.FetchBuilding;
    type: LocationActionType.FetchBuildingInit;
    locationId: string;
}
export const fetchBuilding = (locationId: string): FetchBuilding => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: RequestType.FetchBuilding,
    type: LocationActionType.FetchBuildingInit,
    locationId,
});

export interface FetchBuildingSuccess {
    type: LocationActionType.FetchBuildingSuccess;
    location: BuildingType;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    locationId: string;
}
export const fetchBuildingSuccess = (
    location: BuildingType,
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo },
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo },
    locationId: string
): FetchBuildingSuccess => ({
    type: LocationActionType.FetchBuildingSuccess,
    location,
    devicesWithKeyInfo,
    hubsWithKeyInfo,
    locationId,
});

export type BuildingReducerActions =
    | FetchLocationLabelsSuccess
    | SetLocationLabelsSuccess
    | GetLocationConfigSuccess
    | UpdateLocationConfigSuccess
    | UpdateLocationConfigWithDeviceTypeSuccess
    | FetchLocationResetConnectionsSuccess
    | FetchVirtualDevicesSuccess
    | FetchBuildingSuccess;
