import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { validateLocationRfRegion, validateLocationRfRegionClearState } from '../../actions/LocationActions';
import TertiaryButton from '../../components/buttons/TertiaryButton';
import MultiAttrDropdown from '../../components/dropdown/MultipleAttrDropdown';
import { userIsHbs } from '../../components/findUserType';
import spinner from '../../img/spinner';
import { LocationRfRegionResponse, LocationType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import RegionChangeResponseBox from './RegionChangeResponseBox';

type StateProps = {
    locations: LocationType[];
    loading: boolean;
    rfRegionResponse?: LocationRfRegionResponse;
    rfRegionValidationLoading: boolean;
};

type ParentProps = {
    value: string | null;
    displayValidation: boolean;
    validSelection: boolean;
    onSelectLocation: (arg0: { id: string; locationName: string }) => void;
    buttonOnClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
};

type Props = ParentProps & StateProps;

export const PickExistingLocationComponent = (props: Props): React.ReactElement => {
    const {
        locations,
        loading,
        value,
        displayValidation,
        validSelection,
        onSelectLocation,
        buttonOnClick,
        rfRegionResponse,
        rfRegionValidationLoading,
    } = props;
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect(
        () => (): void => {
            dispatch(validateLocationRfRegionClearState());
        },
        []
    );

    const dropdownOnSelect = (location: { id: string; inputValue: string }): void => {
        const { id, inputValue } = location;
        onSelectLocation({ id, locationName: inputValue });

        if (userIsHbs()) {
            const selectedLocation = locations && locations.find(loc => loc.id === id);
            const countryCode = selectedLocation && selectedLocation.countryCode;
            dispatch(validateLocationRfRegion(countryCode || '', id));
        }
    };

    const options = !loading
        ? locations.map(location => ({
              inputValue: location.name,
              id: location.id,
          }))
        : [];

    return (
        <div className="form form--medium-padding">
            <MultiAttrDropdown
                id="pickExistingLocation"
                title="Location"
                defaultOption="ChooseLocation"
                value={value}
                options={options}
                loading={loading}
                hint="LocationHint"
                validate={displayValidation}
                isValid={validSelection}
                onSelect={dropdownOnSelect}
                testAttr="location-dropdown"
                inputInfo="LocationRfRegion.InfoText"
            />
            {rfRegionValidationLoading ? (
                <div className="centered">{spinner}</div>
            ) : (
                <RegionChangeResponseBox
                    rfRegionResponse={rfRegionResponse}
                    onFailText="LocationRfRegion.InvalidChange"
                    onSuccessText="LocationRfRegion.ValidChangeLocation"
                    onFailSubText={txt('LocationRfRegion.InvalidChangeDescription')}
                    onSuccessSubText={txt('LocationRfRegion.ValidChangeLocationDescription', {
                        region: rfRegionResponse ? txt(`${rfRegionResponse.newRfRegion}`) : '',
                    })}
                />
            )}
            {buttonOnClick && (
                <TertiaryButton id="changeLocationButton" title="AddNewLocation" onClick={buttonOnClick} />
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading, rfRegionResponse },
        commonRequests: {
            [CommonRequestType.ValidateLocationRfRegion]: { loading: rfRegionValidationLoading },
        },
    } = state;

    return {
        locations,
        loading,
        rfRegionValidationLoading,
        rfRegionResponse,
    };
};

export default connect(mapStateToProps)(PickExistingLocationComponent);
