import {
    DashboardSensorData,
    DashboardTile,
    DeviceWithKeyInfo,
    FullDeviceData,
    Group,
    LocationType,
    Units,
} from '../models/commonTypeScript';
import { PollingTile } from './DashboardSensorData';
import { CommonRequestType, RequestState } from './requestReducer';
import { Store } from './index';

export const dashboardSensorData = (store: Store): DashboardSensorData => ({
    sensorData: store.dashboardSensorData.sensorData,
    minValues: store.dashboardSensorData.minValues,
    averageValues: store.dashboardSensorData.averageValues,
});
export const pollingTiles = (store: Store): { [tileId: string]: PollingTile } => store.dashboardSensorData.pollingTiles;
export const dashboardTiles = (state: Store): DashboardTile[] => state.dashboardData.dashboardTiles;
export const getDevice = (store: Store, serialNumber: string): { device: FullDeviceData } => ({
    device: store.devicePage.devices[serialNumber],
});
export const deviceData = (store: Store, serialNumber: string): DashboardSensorData => ({
    sensorData: store.deviceSensorData.sensorData[serialNumber],
    minValues: store.deviceSensorData.minValues[serialNumber],
    averageValues: store.deviceSensorData.averageValues[serialNumber],
    extraSeries: store.deviceSensorData.extraSeries && store.deviceSensorData.extraSeries[serialNumber],
    aggregateTypes: store.deviceSensorData.aggregateTypes && store.deviceSensorData.aggregateTypes[serialNumber],
});

export const virtualDeviceData = (store: Store, serialNumber: string): DashboardSensorData => ({
    sensorData: store.deviceSensorData.virtualSensorData[serialNumber],
    minValues: store.deviceSensorData.virtualMinValues[serialNumber],
    averageValues: store.deviceSensorData.virtualAverageValues[serialNumber],
    extraSeries: store.deviceSensorData.extraVirtualSeries && store.deviceSensorData.extraVirtualSeries[serialNumber],
});
export const pollingDeviceInterval = (store: Store): string | undefined => store.deviceSensorData.pollingDeviceInterval;
export const getDevicesWithKeyInfoSelector = (store: Store): { [serialNumber: string]: DeviceWithKeyInfo } =>
    store.devices.devicesWithKeyInfo;
export const getHubsWithKeyInfoSelector = (store: Store): { [serialNumber: string]: DeviceWithKeyInfo } =>
    store.devices.hubsWithKeyInfo;

export const segmentData = (store: Store, serialNumber: string): DashboardSensorData => ({
    sensorData: store.endedSegmentData.sensorData[serialNumber],
    minValues: store.endedSegmentData.minValues[serialNumber],
    averageValues: store.endedSegmentData.averageValues[serialNumber],
});

export const getCommonRequestsSelector = (store: Store, requestType: CommonRequestType): RequestState => {
    return store.commonRequests[requestType];
};

export const locations = (store: Store): LocationType[] => store.locations.locations;
export const isLoggedIn = (store: Store): boolean => store.login.isLoggedIn;
export const demoMode = (state: Store): boolean => state.userSettings.demoMode;
export const getUnits = (state: Store): Units => state.userSettings.units;
export const getGroups = (state: Store): Group[] => state.userSettings.groups;
