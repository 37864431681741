import { TFunction } from 'i18next';
import { all, call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { getOrganizationLogo } from 'business-dashboard/src/actions/organizationPropertiesActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { getSubscriptionForGroup, selectGroup } from '../../actions/selectGroupActions';
import {
    FETCH_USER_INFO,
    fetchPartnerPropsSuccess,
    fetchDeviceDetails,
    fetchSpecsSuccess,
    fetchUserInfoError,
    fetchUserInfoSuccess,
    SettingsActionType,
    CreatePropertyValue,
    createPropertyValueSuccess,
    CreatePropertyDefinition,
    createPropertyDefinitionSuccess,
    UpdatePropertyValue,
    deletePropertyValueSuccess,
    updatePropertyValueSuccess,
    DeletePropertyDefinition,
    deletePropertyDefinitionSuccess,
    UpdatePropertyDefinition,
    updatePropertyDefinitionSuccess,
} from '../../actions/SettingsActions';
import { getUserProfile, UserPersonalInfoResponse } from '../../api/accountsApiUser';
import {
    createPropertyDefinition,
    createPropertyValue,
    deletePropertyDefinition,
    deletePropertyValue,
    fetchPartnerLogo,
    getSpecs,
    getUserInfo,
    updatePropertyDefinition,
    updatePropertyValue,
} from '../../api/updateSettings';
import { isConsumerAppPage } from '../../commonFunctions';
import { getSelectedGroupFromStorage } from '../../components/findUserType';
import { Consumer } from '../../constants';
import i18next from '../../i18n';
import { GroupType } from '../../models/commonEnums';
import {
    ConfigResponse,
    DashboardLocale,
    ErrorType,
    Group,
    PartnerLogoDetails,
    SelectedGroup,
    UserResponse,
} from '../../models/commonTypeScript';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

export function* updateSelectedUserGroupStorage(
    groups: Group[],
    activeUserGroupId?: string
): Generator<PutEffect | SelectedGroup> {
    const currentSelectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    // If userGroupId exist in groups -> Do nothing
    const userGroupIds: string[] = groups.map(group => group.id);
    const validUserGroupId: boolean = !!currentSelectedGroup && userGroupIds.includes(currentSelectedGroup.userGroupId);

    if (validUserGroupId) {
        return currentSelectedGroup;
    }

    // If missing value, or shouldBeUpgraded  -> first Org, else Consumer
    const firstFoundAccount: Group | undefined = isConsumerAppPage()
        ? groups.find(group => group.groupType === GroupType.consumer)
        : groups.find(group => group.id === activeUserGroupId) ||
          groups.find(group => group.groupType !== GroupType.consumer) ||
          groups.find(group => group.groupType === GroupType.consumer);

    const selectedGroup = {
        organizationId: firstFoundAccount?.organizationId || Consumer,
        userGroupId: firstFoundAccount?.id || Consumer,
        groupType: firstFoundAccount?.groupType || GroupType.consumer,
    };

    yield put(selectGroup(selectedGroup));
    return selectedGroup;
}

export function* fetchUserInfoSaga(): Generator<
    PutEffect | CallEffect | Promise<UserResponse> | Promise<TFunction>,
    void,
    UserResponse & UserPersonalInfoResponse & SelectedGroup & PartnerLogoDetails
> {
    try {
        const userCognitoResponse = yield call(getUserProfile);
        const userInfoResponse = yield call(getUserInfo);

        // This should be set explicitly by user, only change if fault is detected
        const { language, groups } = userInfoResponse;
        const { userGroupId } = yield call(updateSelectedUserGroupStorage, groups, userInfoResponse.activeUserGroupId);

        let languageString = language ? language.slice(0, 2) : language;
        if (languageString === 'nn') languageString = 'nb';
        yield i18next.changeLanguage(languageString);

        if (userInfoResponse.groups.length > 0) {
            const selectedGroup =
                userGroupId && userInfoResponse.groups.find((group: Group) => group.id === userGroupId);
            if (selectedGroup && selectedGroup.groupType === GroupType.business) {
                yield put(getSubscriptionForGroup());
            }
            if (selectedGroup && selectedGroup.groupType === GroupType.partner) {
                yield put(getOrganizationLogo());
            } else if (
                selectedGroup &&
                selectedGroup.groupType === GroupType.business &&
                selectedGroup.managedByUserGroup
            ) {
                const partnerProps = yield call(fetchPartnerLogo);
                yield put(fetchPartnerPropsSuccess(partnerProps));
            }

            yield put(fetchDeviceDetails());
        }

        yield put(
            fetchUserInfoSuccess({
                ...(userInfoResponse as UserResponse),
                name: userCognitoResponse.name,
                email: userCognitoResponse.email,
                language: languageString as DashboardLocale,
            })
        );
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(fetchUserInfoError(asErrorType));
    }
}

// ************ Specs ************

export function* fetchSpecsSaga(): Generator<PutEffect | CallEffect, void, ConfigResponse> {
    try {
        const response = yield call(getSpecs);
        yield put(requestSuccess(CommonRequestType.FetchDeviceDetails, RequestActionType.Success));
        yield put(fetchSpecsSuccess(response));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.FetchDeviceDetails, RequestActionType.Error));
    }
}

type CreatePropertyValueGenerator = Generator<CallEffect | PutEffect, void, { id: string }>;

export function* createPropertyValueSaga({
    propertyId,
    value,
    propertyType,
}: CreatePropertyValue): CreatePropertyValueGenerator {
    try {
        const { id } = yield call(createPropertyValue, propertyId, value);
        yield put(createPropertyValueSuccess(propertyId, { id, value }, propertyType));
        yield put(requestSuccess(CommonRequestType.CreatePropertyValue, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.CreatePropertyValue, RequestActionType.Error));
    }
}

type CreatePropertyDefinitionGenerator = Generator<CallEffect | PutEffect, void, { id: string }>;
export function* createPropertyDefinitionSaga({
    name,
    propertyType,
}: CreatePropertyDefinition): CreatePropertyDefinitionGenerator {
    try {
        const { id } = yield call(createPropertyDefinition, name);
        yield put(createPropertyDefinitionSuccess(id, name, propertyType));
        yield put(requestSuccess(CommonRequestType.CreatePropertyDefinition, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.CreatePropertyDefinition, RequestActionType.Error));
    }
}

export function* updatePropertyDefinitionSaga({ definitionId, name }: UpdatePropertyDefinition): Generator {
    try {
        yield call(updatePropertyDefinition, definitionId, name);
        yield put(updatePropertyDefinitionSuccess(definitionId, name));
        yield put(requestSuccess(CommonRequestType.UpdatePropertyDefinition, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.UpdatePropertyDefinition, RequestActionType.Error));
    }
}

export function* updatePropertyValueSaga({ propertyId, valueId, name }: UpdatePropertyValue): Generator {
    try {
        yield call(updatePropertyValue, propertyId, valueId, name);
        yield put(updatePropertyValueSuccess(propertyId, valueId, name));
        yield put(requestSuccess(CommonRequestType.UpdatePropertyValue, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.UpdatePropertyValue, RequestActionType.Error));
    }
}

export function* deletePropertyValueSaga({ propertyId, valueId }: UpdatePropertyValue): Generator {
    try {
        yield call(deletePropertyValue, propertyId, valueId);
        yield put(deletePropertyValueSuccess(propertyId, valueId));
        yield put(requestSuccess(CommonRequestType.DeletePropertyValue, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.DeletePropertyValue, RequestActionType.Error));
    }
}

export function* deletePropertyDefinitionSaga({ definitionId }: DeletePropertyDefinition): Generator {
    try {
        yield call(deletePropertyDefinition, definitionId);
        yield put(deletePropertyDefinitionSuccess(definitionId));
        yield put(requestSuccess(CommonRequestType.DeletePropertyDefinition, RequestActionType.Success));
    } catch (error) {
        const asErrorType: ErrorType = toErrorType(error);
        yield put(requestError(asErrorType, CommonRequestType.DeletePropertyDefinition, RequestActionType.Error));
    }
}

export default function* getUserInfoSagas(): Generator {
    yield all([
        takeEvery(FETCH_USER_INFO, fetchUserInfoSaga),
        takeEvery(SettingsActionType.FetchSpecs, fetchSpecsSaga),
        takeEvery(SettingsActionType.UpdateUserPreferences, fetchSpecsSaga),
        takeEvery(SettingsActionType.CreatePropertyValue, createPropertyValueSaga),
        takeEvery(SettingsActionType.CreatePropertyDefinition, createPropertyDefinitionSaga),
        takeEvery(SettingsActionType.UpdatePropertyValue, updatePropertyValueSaga),
        takeEvery(SettingsActionType.DeletePropertyValue, deletePropertyValueSaga),
        takeEvery(SettingsActionType.UpdatePropertyDefinition, updatePropertyDefinitionSaga),
        takeEvery(SettingsActionType.DeletePropertyDefinition, deletePropertyDefinitionSaga),
    ]);
}
