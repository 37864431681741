import React, { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalHeader from 'commons/src/components/modals/ModalHeader';
import { renewMqttClientCertificate } from '../../../actions/mqttIntegrationActions';
import { MqttClient, MqttClientCertificate } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

export type PassedProps = {
    onClose: () => void;
    onUploadClick: () => void;
    onDownloadCertificate: () => void;
    isOpen: boolean;
    client: MqttClient | undefined;
};

type StateProps = {
    certificate: MqttClientCertificate | undefined;
    loading: boolean;
};

export type Props = PassedProps & StateProps;

export const RenewOptionsModal = ({
    onClose,
    onUploadClick,
    onDownloadCertificate,
    isOpen,
    client,
    certificate,
    loading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const downloadLinkRef: RefObject<HTMLAnchorElement> = React.createRef();

    useEffect(() => {
        if (!loading && certificate) {
            const downloadLink = downloadLinkRef.current;
            if (downloadLink) {
                downloadLink.click();
                onDownloadCertificate();
            }
        }
    }, [loading, certificate]);

    const onGetCertificateClick = (): void => {
        if (client) dispatch(renewMqttClientCertificate(client.id));
    };

    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="Mqtt.RenewCert" onClose={onClose} />
            <div className="modal__content__body modal__content__body--center">{txt('Mqtt.RenewOptions')}</div>

            <div className="certificate__button-container">
                <div className="form__attr--element">
                    <PrimaryButton
                        id="get-aws-cert-button"
                        icon={<MaterialIcon name="download" />}
                        onClick={onGetCertificateClick}
                        type="button"
                        title="Mqtt.DownloadCert"
                        color="primary"
                        testAttr="get-mqtt-aws-cert"
                        loading={loading}
                    />
                    <a
                        href={certificate && certificate.downloadUrl}
                        download={certificate && certificate.fileName}
                        ref={downloadLinkRef}
                        hidden
                    >
                        {txt('Mqtt.Certificate')}
                    </a>
                </div>
                <div className="form__attr--element">
                    <PrimaryButton
                        color="secondary"
                        id="upload-cert-button"
                        icon={<MaterialIcon name="upload" />}
                        onClick={onUploadClick}
                        type="button"
                        title="Mqtt.UploadCert"
                        loading={loading}
                        testAttr="upload-cert"
                    />
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        mqtt: { certificate },
        requests: {
            [BusinessRequestType.RenewMqttClientCertificate]: { loading: renewLoading },
            [BusinessRequestType.UploadMqttClientCertificate]: { loading: uploadLoading },
        },
    } = state;
    return {
        certificate,
        loading: renewLoading || uploadLoading,
    };
};

export default connect(mapStateToProps)(RenewOptionsModal);
