import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fetchDashboardDeviceTile, fetchDashboardSensor, updateDashboardLayout } from '../../actions/DashboardActions';
import { fetchIndoorVsOutdoorTileData, fetchOutdoorTileData } from '../../actions/OutdoorActions';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_ADDED_TILE } from '../../analytics/AnalyticsEvents';
import { getDashboardPath } from '../../commonFunctions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import MultiAttrDropdown from '../../components/dropdown/MultipleAttrDropdown';
import { userIsHbs } from '../../components/findUserType';
import PageHeader from '../../components/headers/PageHeader';
import SubHeader from '../../components/headers/SubHeader';
import { mediumFormLoader } from '../../components/placeholder';
import ResponseBox from '../../components/responseMessages/ResponseBox';
import {
    availableSensorOptions,
    paths,
    roleRestrictions,
    sensorFullNameKey,
    sensorGraphPeriods,
} from '../../constants';
import { SensorTypes } from '../../models/commonEnums';
import {
    DashboardProps,
    DashboardTile,
    DeviceDetailsResponse,
    DeviceWithKeyInfo,
    LocationType,
    SelectedPeriod,
} from '../../models/commonTypeScript';
import { ActionButton } from '../../models/menuModels';
import { Store } from '../../reducers';
import ColumnsFlipButton from './ColumnsFlipButton';

type Props = {
    locations: LocationType[];
    devicesWithKeyInfo: Record<string, DeviceWithKeyInfo>;
    loading: boolean;
    newModuleLoading: boolean;
    dashboardTiles: DashboardTile[];
    statusMessage: string;
    updateDashboardError: boolean;
    canAddOutdoorTile: boolean;
    dashboardProps: DashboardProps | undefined;
    deviceDetails: { [deviceType: string]: DeviceDetailsResponse };
};

export const AddModuleTypeComponent = ({
    locations,
    dashboardTiles,
    newModuleLoading,
    dashboardProps,
    loading,
    statusMessage,
    updateDashboardError,
    canAddOutdoorTile,
    deviceDetails,
    devicesWithKeyInfo,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type: tileType } = useParams<'type'>() as { type: string };
    const [deviceDisabled, setDeviceDisabled] = useState(true);
    const [sensorDisabled, setSensorDisabled] = useState(true);
    const [sensorName, setSensorName] = useState('');
    const [sensorTileWidth, setSensorTileWidth] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState<LocationType | null | undefined>(null);
    const [selectedDevice, setSelectedDevice] = useState<DeviceWithKeyInfo | null | undefined>(null);
    const [selectedSensor, setSelectedSensor] = useState<
        { serialNumber: string; selectedInterval: SelectedPeriod; sensorType: SensorTypes } | null | undefined
    >(null);
    const [validate, setValidate] = useState(false);

    const onSelectSensor = (selected: { id: string; inputValue: string }, newDeviceSerialNumber?: string): void => {
        const newSensorName = selected.inputValue;
        const sensorObject = availableSensorOptions.find(sensor => sensor.type === selected.id);
        const newSerialNumber = newDeviceSerialNumber || (selectedDevice && selectedDevice.serialNumber);
        if (newSerialNumber && sensorObject) {
            const newSelectedSensor = {
                serialNumber: newSerialNumber,
                selectedInterval: sensorGraphPeriods.week,
                sensorType: sensorObject.type as SensorTypes,
            };
            setSensorName(newSensorName);
            setSelectedSensor(newSelectedSensor);
        }
    };

    const onSelectDevice = (newSelectedDevice: { id: string }): void => {
        const deviceWithKeyInfo = devicesWithKeyInfo[newSelectedDevice.id];
        if (!selectedDevice || (selectedDevice && newSelectedDevice.id !== selectedDevice.serialNumber)) {
            setSelectedDevice(deviceWithKeyInfo);
            setSensorDisabled(false);
            setSelectedSensor(null);
            setSensorName('');
        }
        const sensorOptionsForDevice = deviceDetails[deviceWithKeyInfo.type].sensors;
        if (sensorOptionsForDevice && sensorOptionsForDevice.length === 1) {
            onSelectSensor(
                {
                    id: sensorOptionsForDevice[0],
                    inputValue: txt(sensorFullNameKey(sensorOptionsForDevice[0])),
                },
                newSelectedDevice.id
            );
        }
    };

    const onSelectLocation = (newLocation: { id: string; inputValue: string }): void => {
        const locationData = locations && locations.find(location => location.id === newLocation.id);
        if (locationData && (!selectedLocation || newLocation.id !== selectedLocation.id)) {
            setDeviceDisabled(false);
            setSensorDisabled(true);
            setSelectedLocation(locationData);
            setSelectedDevice(null);
            setSelectedSensor(null);
            setSensorName('');
            if (locationData.devices.length === 1) {
                onSelectDevice({ id: locationData.devices[0] });
            }
        }
    };

    useEffect((): void => {
        if (locations.length === 1) {
            onSelectLocation({ id: locations[0].id, inputValue: locations[0].name });
        }
    }, [locations]);

    const setTileWidth = (option: number, e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setSensorTileWidth(option);
    };

    const goBack = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        navigate({ pathname: `/${paths.addTile}` });
    };

    const validateForm = (): boolean => {
        switch (tileType) {
            case 'add-location-tile':
            case 'add-outdoor-tile':
                return !!selectedLocation;
            case 'add-device-tile':
                return !!(selectedLocation && selectedDevice);
            case 'add-sensor-tile':
            case 'add-sensor-value-tile':
            case 'add-indoor-vs-outdoor-tile':
                return !!(selectedLocation && selectedDevice && selectedSensor);
            default:
                return false;
        }
    };

    const addTile = (): void => {
        let tileData;

        const formIsValid = validateForm();
        setValidate(!formIsValid);
        const dashboardId = dashboardProps && dashboardProps.id;
        if (formIsValid && !newModuleLoading && dashboardId) {
            const tileId: string = uuidv4();
            switch (tileType) {
                case 'add-location-tile':
                    if (selectedLocation) {
                        tileData = {
                            type: 'location',
                            id: tileId,
                            width: 1,
                            ref: selectedLocation.id,
                            content: { ...selectedLocation, locationId: selectedLocation.id },
                        };
                        dispatch(
                            updateDashboardLayout({
                                tiles: [...dashboardTiles, tileData],
                                newModule: true,
                                dashboardId,
                            })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            type: tileData.type,
                            width: tileData.width,
                        });
                    }
                    break;
                case 'add-device-tile':
                    if (selectedDevice) {
                        tileData = {
                            type: 'device',
                            id: tileId,
                            width: 1,
                            ref: selectedDevice.serialNumber,
                        };
                        dispatch(
                            fetchDashboardDeviceTile({
                                newModule: true,
                                dashboardId,
                                tiles: dashboardTiles,
                                tileData,
                                serialNumber: selectedDevice.serialNumber,
                            })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            type: tileData.type,
                            width: tileData.width,
                        });
                    }
                    break;
                case 'add-sensor-tile':
                    if (selectedSensor) {
                        tileData = {
                            type: 'sensor',
                            id: tileId,
                            width: sensorTileWidth,
                            ref: `${selectedSensor.serialNumber}.${selectedSensor.sensorType}`,
                        };

                        dispatch(
                            fetchDashboardSensor({
                                sensor: selectedSensor,
                                tiles: dashboardTiles,
                                sensorData: tileData,
                                newModule: true,
                                dashboardId,
                            })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            sensorType: selectedSensor.sensorType,
                            ...tileData,
                        });
                    }
                    break;
                case 'add-sensor-value-tile':
                    if (selectedDevice && selectedSensor) {
                        tileData = {
                            type: 'sensorValue',
                            id: tileId,
                            width: 1,
                            ref: `${selectedSensor.serialNumber}.${selectedSensor.sensorType}`,
                        };
                        dispatch(
                            fetchDashboardDeviceTile({
                                newModule: true,
                                dashboardId,
                                tiles: dashboardTiles,
                                tileData,
                                serialNumber: selectedSensor.serialNumber,
                                sensor: selectedSensor.sensorType,
                            })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            type: tileData.type,
                            width: tileData.width,
                            sensorType: selectedSensor.sensorType,
                        });
                    }
                    break;
                case 'add-outdoor-tile':
                    if (selectedLocation) {
                        tileData = {
                            type: 'outdoor',
                            id: tileId,
                            width: 1,
                            ref: selectedLocation.id,
                        };
                        dispatch(
                            fetchOutdoorTileData({ tiles: dashboardTiles, tileData, newModule: true, dashboardId })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            ...tileData,
                        });
                    }

                    break;
                case 'add-indoor-vs-outdoor-tile':
                    if (selectedSensor && selectedLocation) {
                        tileData = {
                            type: 'in_vs_out',
                            id: tileId,
                            width: 1,
                            ref: `${selectedLocation.id}.${selectedSensor.serialNumber}.${selectedSensor.sensorType}`,
                        };
                        dispatch(
                            fetchIndoorVsOutdoorTileData({
                                tiles: dashboardTiles,
                                tileData,
                                newModule: true,
                                dashboardId,
                            })
                        );
                        analyticsLogger(DASHBOARD_ADDED_TILE, {
                            pageType: PageType.Dashboard,
                            ...tileData,
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    };
    const page = tileType;

    type DropdownList = { inputValue: string; id: string }[];
    const locationList = !loading
        ? locations.reduce((list: DropdownList, location) => {
              if (location.devices.length > 0) {
                  return [...list, { inputValue: location.name, id: location.id }];
              }
              return list;
          }, [])
        : [];

    const deviceList: DropdownList = selectedLocation
        ? selectedLocation.devices.map(serialNumber => ({
              inputValue: devicesWithKeyInfo[serialNumber] && devicesWithKeyInfo[serialNumber].segmentName,
              id: serialNumber,
          }))
        : [];

    const devicesSensors: SensorTypes[] = (selectedDevice && deviceDetails[selectedDevice.type].sensors) || [];
    let sensorList: DropdownList = devicesSensors.map((option: string) => {
        const specifiedName =
            (userIsHbs() && option === SensorTypes.radonShortTermAvg) || option === SensorTypes.hourlyRadon
                ? txt(`RadonSpecified.${option}`)
                : '';
        return {
            id: option,
            inputValue: `${txt(sensorFullNameKey(option))} ${specifiedName}`,
        };
    });

    if (page === 'add-indoor-vs-outdoor-tile') {
        sensorList = sensorList && sensorList.filter(sensor => ['temp', 'humidity', 'pressure'].includes(sensor.id));
    }

    const notAllowedToAddOutdoorTile = page === 'add-outdoor-tile' && !canAddOutdoorTile;
    const errorText = notAllowedToAddOutdoorTile ? 'NotAllowedToAddTileText' : statusMessage;
    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => navigate({ pathname: getDashboardPath() }),
            testAttr: 'close-add-tile',
            color: 'secondary',
            id: 'closeAddTile',
            title: txt('Close'),
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
    ];
    const onGoBack = (): void => {
        navigate({ pathname: `/${paths.addTile}` });
    };

    return (
        <div {...{ [`data-${page}`]: true }}>
            <PageHeader title={txt(page)} />
            <div className="page-wrapper page-wrapper--grid-layout">
                <SubHeader onGoBack={onGoBack} backTitle="AddNewTile" actionButtons={actionButtons} />
            </div>
            <div className="page-wrapper page-wrapper--grid-layout">
                <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                    <div className="page-wrapper__inner page-wrapper__inner--slim add-new-device">
                        <form className="add-new-device__form add-new-module">
                            {(updateDashboardError || notAllowedToAddOutdoorTile) && !newModuleLoading && (
                                <ResponseBox text={errorText} />
                            )}
                            {page === 'add-sensor-tile' && (
                                <ColumnsFlipButton checked={sensorTileWidth} chooseOption={setTileWidth} />
                            )}
                            <MultiAttrDropdown
                                id="locationSelector"
                                title="Location"
                                defaultOption="SelectLocation"
                                value={selectedLocation && selectedLocation.name}
                                options={locationList}
                                loading={false}
                                onSelect={onSelectLocation}
                                disabled={false}
                                isValid={false}
                                validate={!selectedLocation && validate}
                                hint="SelectLocation"
                                testAttr="location-dropdown"
                            />
                            {page !== 'add-location-tile' && page !== 'add-outdoor-tile' && (
                                <MultiAttrDropdown
                                    id="deviceSelector"
                                    title="Device"
                                    defaultOption="DeviceHint"
                                    value={selectedDevice ? selectedDevice.segmentName : ''}
                                    options={deviceList}
                                    loading={false}
                                    onSelect={onSelectDevice}
                                    disabled={deviceDisabled}
                                    isValid={false}
                                    validate={!selectedDevice && validate}
                                    hint="DeviceHint"
                                    testAttr="device-dropdown"
                                />
                            )}
                            {(page === 'add-sensor-tile' ||
                                page === 'add-sensor-value-tile' ||
                                page === 'add-indoor-vs-outdoor-tile') && (
                                <MultiAttrDropdown
                                    id="sensorSelector"
                                    title="Sensor"
                                    defaultOption="SensorHint"
                                    value={sensorName}
                                    options={sensorList}
                                    loading={false}
                                    onSelect={onSelectSensor}
                                    disabled={sensorDisabled}
                                    isValid={false}
                                    validate={!sensorName && validate}
                                    hint="SensorHint"
                                    testAttr="sensor-dropdown"
                                />
                            )}
                            <div className="add-new-device__form__buttons">
                                <PrimaryButton
                                    color="secondary"
                                    id="cancel"
                                    title="Cancel"
                                    loading={false}
                                    disabled={false}
                                    onClick={goBack}
                                    testAttr="cancel-add-tile-button"
                                />
                                <PrimaryButton
                                    id="submit"
                                    title="AddTile"
                                    loading={newModuleLoading}
                                    color="primary"
                                    onClick={addTile}
                                    disabled={notAllowedToAddOutdoorTile}
                                    testAttr="add-tile-button"
                                />
                            </div>
                        </form>
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        dashboardData: {
            dashboardTiles,
            newModuleLoading,
            updateDashboardError,
            statusMessage,
            dashboardProps,
            loading: loadingDashboardProps,
        },
        locations: { locations, loading },
        devices: { devicesWithKeyInfo },
        config: { deviceDetails },
    } = state;
    const canAddOutdoorTile = userIsHbs() || dashboardTiles.find(tile => tile.type === 'outdoor') === undefined;
    return {
        locations,
        deviceDetails,
        devicesWithKeyInfo,
        loading: loading || loadingDashboardProps,
        dashboardTiles,
        newModuleLoading,
        statusMessage,
        updateDashboardError,
        canAddOutdoorTile,
        dashboardProps,
    };
};

export default connect(mapStateToProps)(AddModuleTypeComponent);
