import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { pollDeviceData, renameSegment, stopPollDeviceData } from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { DEVICE_VIEWED_DETAILS } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { paths, roleRestrictions, sensorGraphPeriods } from 'commons/src/constants';
import DeviceEditNameHeader from 'commons/src/features/devicePage/DeviceEditNameHeader';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, FullDeviceData } from 'commons/src/models/commonTypeScript';
import { TabOption } from 'commons/src/models/menuModels';
import { fetchSegmentProperties } from '../../actions/segmentPropertiesActions';
import { Store } from '../../reducers';

export type Props = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    devices: { [device: string]: DeviceWithKeyInfo };
    devicesLoading: boolean;
    fetching: boolean;
    loadingApp: boolean;
    isLoggedIn: boolean;
};

export const DevicePageLayoutComponent = ({
    devicePageDevices,
    fetching,
    loadingApp,
    isLoggedIn,
    devicesLoading,
    devices,
}: Props): React.ReactElement => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();

    const endedSegment = false;
    const deviceKeyInfo = devices[serialNumber];
    const locationId = deviceKeyInfo && deviceKeyInfo.locationId;
    const device = devicePageDevices[serialNumber] || {};

    useEffect(() => {
        dispatch(fetchSegmentProperties(serialNumber));
    }, []);

    useEffect(() => {
        if (serialNumber === undefined) {
            navigate(pageNotFoundUrl('serialNumberNotDefined'));
        } else if (!(loadingApp && !isLoggedIn) && !fetching) {
            dispatch(
                pollDeviceData({
                    serialNumber,
                    selectedInterval: sensorGraphPeriods.week,
                    fetching: !devicePageDevices[serialNumber],
                    loading: !devicePageDevices[serialNumber],
                })
            );
        }
        analyticsLogger(DEVICE_VIEWED_DETAILS, { pageType: PageType.Device });
        return (): void => {
            dispatch(stopPollDeviceData());
        };
    }, [isLoggedIn]);

    useEffect(() => {
        if ((fetching && serialNumber === undefined) || (!devicesLoading && !devices[serialNumber])) {
            navigate(pageNotFoundUrl('serialNumberNotDefinedOrNotInDevices', { serialNumber }));
        }
    }, [devicePageDevices, fetching, devicesLoading]);

    const changeSegmentName = (name: string): void => {
        const segmentId = endedSegment ? serialNumber : 'latest';
        dispatch(renameSegment(serialNumber, segmentId, name, locationId));
    };

    const onBackClicked = (): void => {
        let returnPath: string =
            device && device.deviceType && isVirtualDevice(device.deviceType) ? 'control' : 'devices';
        if (device?.spaceId) {
            returnPath = `spaces/${device.spaceId}`;
        }
        const path = locationId ? `${paths.buildings}/${locationId}/${returnPath}` : paths.buildings;
        navigate(`/${path}`);
    };

    const tabOptions: TabOption[] = [
        {
            text: 'Overview',
            id: 'deviceOverview',
            path: generatePath(`/${paths.devicePage}`, { serialNumber }),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'device-overview',
            route: paths.devicePage,
            requiredGroupTypes: [],
        },
        {
            text: 'Settings',
            id: 'deviceSettings',
            path: generatePath(`/${paths.devicePage}/change-location`, { serialNumber }),
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            testAttr: 'device-settings',
            route: `${paths.devicePage}/change-location`,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div className="single-device">
            <PageHeader
                title={device && device.segmentName}
                subHeaderClick={onBackClicked}
                subHeader={device && device.spaceId ? txt('Spaces.BackToSpace') : device.locationName}
                customHeader={
                    <DeviceEditNameHeader name={device && device.segmentName} renameSegment={changeSegmentName} />
                }
                tabs={tabOptions}
            />
            <Outlet />
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        devicePage: { devices: devicePageDevices, fetching },
        devices: { devicesWithKeyInfo, loading: devicesLoading },
        app: { loading: loadingApp },
        login: { isLoggedIn },
    } = state;

    return {
        devices: devicesWithKeyInfo,
        devicePageDevices,
        fetching,
        devicesLoading,
        loadingApp,
        isLoggedIn,
    };
};

export default connect(mapStateToProps)(DevicePageLayoutComponent);
