import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment, { unitOfTime } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { fetchCustomDeviceSegment } from 'commons/src/actions/DeviceActions';
import { customPeriodName, graphResolutions } from 'commons/src/constants';
import SensorGraph from 'commons/src/features/devicePage/SensorGraph';
import IconNoData from 'commons/src/img/icon-no-data';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import { AverageSensorPeriodValues, MinSensorPeriodValues, SensorData } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

dayjs.extend(timezone);
dayjs.extend(utc);

type DurationConstructor = unitOfTime.DurationConstructor;

type StateProps = {
    sensorData?: { [serialNumber: string]: SensorData };
    minValues?: MinSensorPeriodValues;
    averageValues?: AverageSensorPeriodValues;
    dateFormat: string;
};
export type ParentProps = {
    selectedPoint: string;
    selectedHighThreshold: { id: string; inputValue: string };
    selectedLowThreshold?: { id: string; inputValue: string };
    sensorType: SensorTypes;
    unit: string;
    serialNumber: string;
    resolution: Resolution;
    timeZone?: string;
};

type Props = StateProps & ParentProps;

const TrendOverTimeDeviceGraph = ({
    selectedPoint,
    sensorType,
    unit,
    selectedHighThreshold,
    selectedLowThreshold,
    minValues = {},
    averageValues = {},
    dateFormat,
    sensorData = {},
    serialNumber,
    resolution,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const deviceData = sensorData[serialNumber];
    const deviceMinValues = minValues[serialNumber];
    const deviceAverageValues = averageValues[serialNumber];
    const chartData = deviceData && deviceData[sensorType];
    const minSensorValues = deviceMinValues && deviceMinValues[sensorType];
    const averageSensorValues = deviceAverageValues && deviceAverageValues[sensorType];
    const lowThreshold = selectedLowThreshold ? parseInt(selectedLowThreshold.id, 10) : undefined;
    const thresholds = [parseInt(selectedHighThreshold.id, 10)];
    if (lowThreshold) {
        thresholds.unshift(lowThreshold);
        if (sensorType === SensorTypes.humidity) {
            // humidity requires 4 thresholds to prevent orange in the graph
            thresholds.push(parseInt(selectedHighThreshold.id, 10));
            thresholds.unshift(lowThreshold);
        }
    }
    const selectedInterval = timeZone && {
        name: customPeriodName,
        label: '48hours',
        ...graphResolutions.full,
        startDate:
            resolution === Resolution.hour
                ? moment.tz(selectedPoint, timeZone)
                : moment.utc(selectedPoint).tz(timeZone),
        endDate:
            resolution === Resolution.hour
                ? moment.tz(selectedPoint, timeZone).add(1, resolution as DurationConstructor)
                : moment
                      .utc(selectedPoint)
                      .tz(timeZone)
                      .add(1, resolution as DurationConstructor),
        number: 1,
        period: resolution === Resolution.hour ? 'hours' : 'days',
    };

    useEffect(() => {
        if (serialNumber && selectedInterval) {
            dispatch(fetchCustomDeviceSegment(serialNumber, selectedInterval));
        }
    }, [serialNumber]);
    if (!serialNumber) return <div />;

    return (
        <div className="building-insight__card__section building-insight__card__section--sub building-insight__card__section--breakdown__graph-background">
            {chartData && selectedInterval ? (
                <SensorGraph
                    chartData={chartData}
                    selectedInterval={selectedInterval}
                    thresholds={thresholds}
                    chartHeight={170}
                    sensorType={sensorType}
                    unit={unit}
                    minValues={minSensorValues}
                    averageValues={averageSensorValues}
                    dateFormat={dateFormat}
                    timeZone={timeZone}
                />
            ) : (
                <div className="centered">
                    <div className="centered__content">
                        {IconNoData}
                        <div>{txt('Loading')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        deviceSensorData: { sensorData, minValues, averageValues },
        userSettings: { dateFormat },
    } = state;
    return { sensorData, minValues, averageValues, dateFormat };
};

export default connect(mapStateToProps)(TrendOverTimeDeviceGraph);
