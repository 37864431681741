import React, { KeyboardEvent, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { listSensorOrder } from 'commons/src/commonFunctions';
import CheckBox from 'commons/src/components/input/Checkbox';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { sensorFullNameKey } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';

type Props = {
    sensors: SensorTypes[];
    updateSelectedSensors: (sensors: SensorTypes[]) => void;
    selectedDevices: DeviceWithKeyInfo[];
    selectedSensors: SensorTypes[];
    sensorWarning?: { sensors: SensorTypes[]; text: string | React.ReactElement };
    showAllSensors?: boolean;
    sensorNotToAutoSelect?: SensorTypes[];
};

export const SensorSelectorComponent = (props: Props): React.ReactElement => {
    const {
        sensors,
        updateSelectedSensors,
        selectedDevices,
        selectedSensors,
        sensorWarning,
        showAllSensors,
        sensorNotToAutoSelect,
    } = props;

    const { t: txt } = useTranslation();
    const sensorOptionsInDevices = showAllSensors
        ? sensors
        : sensors.filter(sensor =>
              selectedDevices.find(
                  device => listSensorOrder[device.type] && listSensorOrder[device.type].includes(sensor)
              )
          );

    const toggleSensor = (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>): void => {
        const sensor = e.currentTarget.value;
        const sensorInList = selectedSensors.includes(sensor as SensorTypes);
        let updatedSensorList;
        if (sensorInList) updatedSensorList = [...selectedSensors].filter(sensorElement => sensorElement !== sensor);
        else updatedSensorList = [...selectedSensors, sensor as SensorTypes];
        updateSelectedSensors(updatedSensorList);
    };

    useEffect(() => {
        if (selectedDevices.length > 0 && selectedSensors.length === 0) {
            const sensorAutoSelected = sensorNotToAutoSelect
                ? sensorOptionsInDevices.filter(sensor => !sensorNotToAutoSelect.includes(sensor))
                : sensorOptionsInDevices;
            updateSelectedSensors(sensorAutoSelected);
        } else if (selectedDevices.length === 0) {
            updateSelectedSensors([]);
        }
    }, [selectedDevices, sensorWarning, sensorNotToAutoSelect]);

    useEffect(() => {
        const selectedSensorsNotInDeviceSelection = selectedSensors.filter(
            sensor => !sensorOptionsInDevices.includes(sensor)
        );
        if (selectedDevices.length > 0 && selectedSensorsNotInDeviceSelection.length > 0) {
            updateSelectedSensors(
                [...selectedSensors].filter(sensorElement => sensorOptionsInDevices.includes(sensorElement))
            );
        }
    }, [sensorOptionsInDevices]);

    const displayedSensorOptions = selectedDevices.length > 0 ? sensorOptionsInDevices : sensors;
    const sensorCheckboxes = displayedSensorOptions
        .sort((sensorA, sensorB) => sensorA.localeCompare(sensorB))
        .map(type => (
            <div className="form__field form__field--padded-small" key={`sensor-option-list-${type}`}>
                <CheckBox
                    id={type}
                    label={
                        <div className="notification-alert__icons">
                            <div className="device-svg-icons notification-alert__icons__circle">
                                <SensorIcon sensor={type} />
                            </div>
                            <div className="notification-alert__icons__text">
                                <span className="notification-alert__icons__text--lowercase">
                                    {txt(sensorFullNameKey(type))}
                                </span>
                            </div>
                        </div>
                    }
                    labelIsElement
                    checked={selectedSensors.includes(type)}
                    onChange={toggleSensor}
                    disabled={!sensorOptionsInDevices.includes(type)}
                />
            </div>
        ));

    const displaySensorWarning =
        sensorWarning && sensorWarning.sensors.some(sensor => selectedSensors.includes(sensor));

    return (
        <div>
            <div className="form__row form__row--padded-small">{sensorCheckboxes}</div>
            {sensorWarning && displaySensorWarning && <ResponseBox greenBox text="" subtext={sensorWarning.text} />}
        </div>
    );
};

export default SensorSelectorComponent;
