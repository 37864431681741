import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_HEATING_COOLING_INSIGHT_FETCHED } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import SectionHeader from 'commons/src/components/headers/SectionHeader';
import { IntercomAPI } from 'commons/src/components/Intercom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { DeviceTypeNames, Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { AnyDeviceType, BuildingType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { getHeatingCoolingData } from '../../../actions/buildingOptimizationActions';
import { HeatingCoolingData } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import styles from './EnergyInsightPage.module.scss';
import EnergyInsightTimeSelector from './EnergyInsightTimeSelector';
import HeatingCoolingCSVDownload from './HeatingCoolingCSVDownload';
import HeatingCoolingGraph from './HeatingCoolingGraph';

type EnergyInsightSelectedPeriod = {
    to: string;
    from: string;
    name: TimePeriod;
    resolution: Resolution;
    locationId?: string;
};

type StateProps = {
    selectedPeriodInStore?: { from: string; to: string; name: TimePeriod; locationId: string; resolution: Resolution };
    heatingCoolingData?: HeatingCoolingData;
    dateFormat: keyof typeof dateFormats;
    loading: boolean;
    language: string;
    error?: ErrorType;
    buildings: { [buildingId: string]: BuildingType };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type ParentProps = {
    locationName: string;
    openingHoursEnabled: boolean;
};
type Props = StateProps & ParentProps;

const defaultTimeSpanSelected: TimePeriod = TimePeriod.week;
const initialSelectedPeriod: EnergyInsightSelectedPeriod = {
    to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    from: moment().subtract(1, defaultTimeSpanSelected).format('YYYY-MM-DD'),
    name: defaultTimeSpanSelected,
    resolution: Resolution.day,
};

const EnergyInsightPage = ({
    selectedPeriodInStore,
    heatingCoolingData,
    dateFormat,
    loading,
    locationName,
    language,
    openingHoursEnabled,
    error,
    devicesWithKeyInfo,
    buildings,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const { buildingId } = useParams<'buildingId'>() as { buildingId: string };
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const useSelectedPeriodFromStore =
        !!heatingCoolingData &&
        selectedPeriodInStore?.locationId === buildingId &&
        selectedPeriodInStore?.name !== TimePeriod.custom;
    const [selectedPeriod, setSelectedPeriod] = useState<EnergyInsightSelectedPeriod>(
        selectedPeriodInStore && useSelectedPeriodFromStore ? selectedPeriodInStore : initialSelectedPeriod
    );
    const building = buildings[buildingId];
    const physicalDevices =
        building?.devices.filter(snr => {
            const deviceType = devicesWithKeyInfo[snr] && devicesWithKeyInfo[snr].type;
            const deviceIsAnAirthingsDevice =
                deviceType && ![DeviceTypeNames.bacnet].includes(deviceType as DeviceTypeNames);
            return deviceType && deviceIsAnAirthingsDevice && !isVirtualDevice(deviceType as AnyDeviceType);
        }) || [];

    useEffect(() => {
        if (!useSelectedPeriodFromStore) {
            const payload = {
                ...initialSelectedPeriod,
                locationId: buildingId,
            };
            analyticsLogger(BUILDING_HEATING_COOLING_INSIGHT_FETCHED, { payload });
            dispatch(getHeatingCoolingData(payload));
        }
    }, []);

    const selectPeriod = (period: TimePeriod, from: string, to: string, resolution: Resolution): void => {
        setSelectedPeriod({
            from,
            to,
            name: period,
            resolution,
        });
        const payload = {
            from,
            to,
            name: period,
            locationId: buildingId,
            resolution,
        };
        analyticsLogger(BUILDING_HEATING_COOLING_INSIGHT_FETCHED, { payload });
        dispatch(getHeatingCoolingData(payload));
    };

    const triggerIntercomSurvey = (): void => {
        IntercomAPI('startSurvey', 171639);
    };

    useEffect(() => {
        if (building?.timezone) {
            Highcharts.setOptions({
                time: {
                    useUTC: false,
                    timezone: building?.timezone,
                    timezoneOffset: -moment.tz(building?.timezone).utcOffset(),
                },
            });
        }
    }, [building?.timezone]);

    return (
        <div className="container">
            <SectionHeader headerName="BuildingEnergy.EnergyOptimization" headerType={3} initialHeader />
            <div className={styles.energyDescription}>{txt('BuildingEnergy.EnergyDescription')}</div>
            <SectionHeader
                headerName="BuildingEnergy.HeatingAndCooling"
                infoText="BuildingEnergy.HeaderInfoText"
                headerType={4}
                endOfLineButton={
                    <TertiaryButton onClick={triggerIntercomSurvey} title="BuildingEnergy.HeatingCoolingFeedback" />
                }
            />
            <div className={styles.timeSelectorWrapper}>
                <EnergyInsightTimeSelector selectedPeriod={selectedPeriod.name} setSelectedPeriod={selectPeriod} />
                {heatingCoolingData && downloadModalOpen && (
                    <HeatingCoolingCSVDownload
                        dateFormat={dateFormat}
                        heatingCoolingData={heatingCoolingData}
                        selectedPeriod={{
                            toDate: selectedPeriod.to,
                            fromDate: selectedPeriod.from,
                            resolution: selectedPeriod.resolution,
                            name: selectedPeriod.name,
                        }}
                        setDownloadModalOpen={setDownloadModalOpen}
                        locationName={locationName}
                    />
                )}
                {heatingCoolingData && openingHoursEnabled && (
                    <PrimaryButton
                        color="secondary"
                        disabled={loading}
                        onClick={(): void => setDownloadModalOpen(true)}
                        title="CsvStrings.Csv"
                        icon={<MaterialIcon name="download" />}
                    />
                )}
            </div>
            <HeatingCoolingGraph
                locationId={buildingId}
                data={heatingCoolingData || []}
                dateFormat={dateFormat}
                loading={loading}
                language={language}
                error={error}
                openingHoursEnabled={openingHoursEnabled}
                numberOfDevicesInLocation={physicalDevices.length}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, language },
        buildings: { buildings },
        devices: { devicesWithKeyInfo },
        buildingOptimization: { heatingCoolingData },
        requests: {
            [BusinessRequestType.GetHeatingCoolingData]: { loading, error },
        },
    } = state;
    return {
        selectedPeriodInStore: heatingCoolingData?.selectedPeriod,
        heatingCoolingData: heatingCoolingData?.data,
        dateFormat,
        loading,
        language,
        error,
        buildings,
        devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(EnergyInsightPage);
