import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { pollDeviceData, renameSegment, stopPollDeviceData } from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { DEVICE_VIEWED_DETAILS } from 'commons/src/analytics/AnalyticsEvents';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { paths, sensorGraphPeriods } from 'commons/src/constants';
import DeviceEditNameHeader from 'commons/src/features/devicePage/DeviceEditNameHeader';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { FullDeviceData, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { TabOption } from 'commons/src/models/menuModels';
import { Store } from 'commons/src/reducers';

export type Props = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    devicesLoading: boolean;
    fetching: boolean;
    loadingApp: boolean;
    isLoggedIn: boolean;
};

export const DevicePageComponent = ({
    isLoggedIn,
    devicePageDevices,
    loadingApp,
    fetching,
    devicesLoading,
    devices,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const deviceKeyInfo = devices[serialNumber];

    useEffect(() => {
        if (serialNumber === undefined) {
            navigate(pageNotFoundUrl('serialNumberNotDefined'));
        } else if (!(loadingApp && !isLoggedIn) && !fetching) {
            dispatch(
                pollDeviceData({
                    serialNumber,
                    selectedInterval: sensorGraphPeriods.week,
                    fetching: !devicePageDevices[serialNumber],
                    loading: !devicePageDevices[serialNumber],
                })
            );
        }
        analyticsLogger(DEVICE_VIEWED_DETAILS, { pageType: PageType.Device });
        return (): void => {
            dispatch(stopPollDeviceData());
        };
    }, [isLoggedIn]);

    useEffect(() => {
        if ((fetching && serialNumber === undefined) || (!devicesLoading && !devices[serialNumber])) {
            navigate(pageNotFoundUrl('serialNumberNotDefinedOrNotInDevices', { serialNumber }));
        }
    }, [serialNumber, devicePageDevices, fetching, devicesLoading]);

    const renameDevice = (name: string): void => {
        const segmentId = 'latest';
        dispatch(renameSegment(serialNumber, segmentId, name, deviceKeyInfo.locationId));
    };

    const onBackClicked = (): void => {
        navigate(`/${paths.devices}`);
    };

    const tabOptions: TabOption[] = [
        {
            text: 'Overview',
            id: 'deviceOverview',
            path: generatePath('/devices/:serialNumber', { serialNumber }),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'device-overview',
            route: 'devices/:serialNumber',
            requiredGroupTypes: [],
        },
        {
            text: 'Settings',
            id: 'deviceSettings',
            path: generatePath('/devices/:serialNumber/change-location', { serialNumber }),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'device-settings',
            route: 'devices/:serialNumber/change-location',
            requiredGroupTypes: [],
        },
    ];
    return (
        <div className="single-device">
            <PageHeader
                title={deviceKeyInfo && deviceKeyInfo.segmentName}
                subHeaderClick={onBackClicked}
                subHeader={txt('AllDevices')}
                customHeader={
                    <DeviceEditNameHeader
                        name={deviceKeyInfo && deviceKeyInfo.segmentName}
                        renameSegment={renameDevice}
                    />
                }
                tabs={tabOptions}
            />
            <Outlet />
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        devicePage: { devices: devicePageDevices, fetching },
        devices: { devicesWithKeyInfo, loading: devicesLoading },
        app: { loading: loadingApp },
        login: { isLoggedIn },
    } = state;
    return {
        devices: devicesWithKeyInfo,
        devicePageDevices,
        fetching,
        devicesLoading,
        loadingApp,
        isLoggedIn,
    };
};

export default connect(mapStateToProps)(DevicePageComponent);
