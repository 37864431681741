import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchCustomDeviceSegment,
    pollDeviceData,
    PollDeviceDataPayload,
    stopPollDeviceData,
} from '../../actions/DeviceActions';
import Error from '../../components/errorComponents/Error';
import { pageNotFoundUrl } from '../../components/errorComponents/PageNotFound';
import { getSelectedGroupFromStorage } from '../../components/findUserType';
import { sensorGraphPeriods } from '../../constants';
import { FullDeviceData, SensorData, SelectedPeriod, SelectedGroup } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import DevicePageBody from './DevicePageBody';

type Props = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    sensorData: { [serialNumber: string]: SensorData };
    userSettingsLoading: boolean;
    devicePageLoading: boolean;
    dateFormat: string;
    fetching: boolean;
    loadingApp: boolean;
    isLoggedIn: boolean;
    error: boolean;
};

const AppDevicePageComponent = ({
    devicePageDevices,
    devicePageLoading,
    dateFormat,
    sensorData,
    error,
    fetching,
    loadingApp,
    userSettingsLoading,
    isLoggedIn,
}: Props): React.ReactElement => {
    const { device: serialNumber } = useParams() as { device: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>(sensorGraphPeriods.week);
    const selectedGroupInLocalStorage: SelectedGroup | null = getSelectedGroupFromStorage();

    const onPollDeviceData = (payload: PollDeviceDataPayload, withPolling?: boolean): void => {
        dispatch(pollDeviceData(payload, withPolling));
    };

    const fetchSensorData = (setFetching: boolean, setLoading: boolean): void => {
        if (selectedGroupInLocalStorage?.userGroupId) {
            onPollDeviceData(
                {
                    serialNumber,
                    selectedInterval: sensorGraphPeriods.week,
                    fetching: setFetching,
                    loading: setLoading,
                },
                false
            );
        }
    };

    const fetchCustomSegment = (segmentId: string, timeRange: SelectedPeriod): void => {
        dispatch(fetchCustomDeviceSegment(serialNumber, timeRange));
    };

    const stopPollingDeviceData = (): void => {
        dispatch(stopPollDeviceData());
    };

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (serialNumber === undefined) {
            navigate(pageNotFoundUrl('serialNumberNotDefined'));
        } else if (!loadingApp && isLoggedIn && !fetching) {
            fetchSensorData(!devicePageDevices[serialNumber], !devicePageDevices[serialNumber]);
        }
        if (rootElement) {
            rootElement.className = 'no-margin';
        }
    }, [loadingApp]);

    const updateSelectedPeriod = (value: SelectedPeriod): void => setSelectedPeriod(value);

    const device = devicePageDevices[serialNumber] || {};
    const chartData = sensorData[serialNumber] || {};

    const firstSensor = Object.keys(chartData)[0];
    const missingChartData = !firstSensor || (firstSensor && !chartData[firstSensor][selectedPeriod.name]);
    if (error && !fetching && !devicePageLoading && missingChartData) {
        return <Error />;
    }

    return (
        <div className="container">
            <DevicePageBody
                deviceId={serialNumber}
                devicePageLoading={devicePageLoading || userSettingsLoading}
                fetching={fetching}
                dateFormat={dateFormat}
                device={device}
                endedSegment={false}
                lastRecord={device && device.latestSample}
                fetchCustomSegment={fetchCustomSegment}
                serialNumber={serialNumber}
                selectedPeriod={selectedPeriod}
                updateSelectedPeriod={updateSelectedPeriod}
                onPollDeviceData={onPollDeviceData}
                stopPollingDeviceData={stopPollingDeviceData}
                fetchedDevices={devicePageDevices}
                withPolling={false}
            />
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        devicePage: { devices: devicePageDevices, loading: devicePageLoading, fetching, errorCode },
        deviceSensorData: { sensorData },
        userSettings: { dateFormat, loading: userSettingsLoading },
        app: { loading: loadingApp, error: appError },
        login: { isLoggedIn },
    } = state;

    return {
        devicePageDevices,
        devicePageLoading,
        userSettingsLoading,
        fetching,
        dateFormat,
        sensorData,
        loadingApp,
        isLoggedIn,
        error: !!errorCode || appError,
    };
};

export default connect(mapStateToProps)(AppDevicePageComponent);
