import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { FetchLocationsHistory, updateLocationDetailsSuccess } from 'commons/src/actions/LocationActions';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { fetchAllDevices } from 'commons/src/api/devices';
import {
    AllDevicesResponse,
    BuildingConfiguration,
    BuildingResponseType,
    BuildingType,
    DevicesWithKeyInfoResponse,
    DeviceType,
    HubData,
    LocationType,
} from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import { isLoggedIn } from 'commons/src/reducers/reducerShortcuts';
import displayAlertBoxSaga from 'commons/src/sagas/displayAlertBox';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import { getDevicesFromLocationResponse, transformResponseToLocationType } from 'commons/src/sagas/Locations/locations';
import {
    ActionType,
    FetchBuilding,
    FetchBuildingSuccess,
    fetchBuildingSuccess,
    FetchLocationLabels,
    FetchLocationLabelsSuccess,
    fetchLocationLabelsSuccess,
    FetchLocationResetConnections,
    FetchLocationResetConnectionsSuccess,
    fetchLocationResetConnectionsSuccess,
    FetchVirtualDevices,
    fetchVirtualDevicesSuccess,
    FetchVirtualDevicesSuccess,
    GetLocationConfig,
    GetLocationConfigSuccess,
    getLocationConfigSuccess,
    LocationActionType,
    setLocationLabelsSuccess,
    UpdateLocationConfig,
    updateLocationConfigSuccess,
    UpdateLocationConfigWithDeviceType,
    UpdateLocationConfigWithDeviceTypeSuccess,
    updateLocationConfigWithDeviceTypeSuccess,
    UpdateLocationDetails,
    UpdateLocationLabels,
} from '../actions/locationActions';
import updateLocation, {
    getBuilding,
    getBuildingConfig,
    getBuildingResetConnections,
    getLocationLabels,
    updateBuildingConfig,
    updateBuildingConfigWithDeviceType,
    updateLocationLabels,
} from '../api/buildingApi';
import { LabelsPayload, ResetConnection } from '../models/common';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';
import { createKeyValueMap, createKeyValuePairs } from './segmentPropertiesSaga';

export function* updateLocationDetailsSaga({ locationDetails }: UpdateLocationDetails): Generator {
    try {
        yield call(updateLocation, locationDetails);
        yield put(updateLocationDetailsSuccess(locationDetails));
        yield put(requestSuccess(RequestType.UpdateLocationDetails, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'Building.BuildingUpdated', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateLocationDetails, RequestActionType.Error));
    }
}

type FetchLocationLabelsSagaReturnType = Generator<
    CallEffect<{ labels: LabelsPayload }> | PutEffect<FetchLocationLabelsSuccess> | RequestActions,
    void,
    { labels: LabelsPayload }
>;

export function* fetchLocationLabelsSaga({ locationId }: FetchLocationLabels): FetchLocationLabelsSagaReturnType {
    try {
        const response = yield call(getLocationLabels, locationId);
        yield put(fetchLocationLabelsSuccess(locationId, createKeyValuePairs(response.labels)));
        yield put(requestSuccess(RequestType.FetchLocationLabels, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchLocationLabels, RequestActionType.Error));
    }
}

export function* updateLocationLabelsSaga({ locationId, labels }: UpdateLocationLabels): Generator {
    try {
        yield call(updateLocationLabels, locationId, createKeyValueMap(labels));
        yield put(setLocationLabelsSuccess(locationId, labels));
        yield put(requestSuccess(RequestType.UpdateLocationLabels, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateLocationLabels, RequestActionType.Error));
    }
}

type GetLocationConfigSagaReturnType = Generator<
    CallEffect<BuildingConfiguration> | PutEffect<GetLocationConfigSuccess> | RequestActions,
    void,
    BuildingConfiguration
>;

export function* getLocationConfigSaga({ locationId }: GetLocationConfig): GetLocationConfigSagaReturnType {
    try {
        const response = yield call(getBuildingConfig, locationId);
        yield put(getLocationConfigSuccess(response, locationId));
        yield put(requestSuccess(RequestType.GetLocationConfiguration, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.GetLocationConfiguration, RequestActionType.Error));
    }
}

export function* updateLocationConfigSaga({ locationId, config }: UpdateLocationConfig): Generator {
    try {
        if (config) {
            yield call(updateBuildingConfig, locationId, config);
            yield put(updateLocationConfigSuccess(config, locationId));
        }
        yield put(requestSuccess(RequestType.UpdateLocationConfiguration, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'FeaturesUpdated', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.UpdateLocationConfiguration, RequestActionType.Error));
    }
}

type UpdateLocationConfigWithDeviceTypeSagaReturnType = Generator<
    | CallEffect<void>
    | CallEffect<BuildingConfiguration>
    | CallEffect
    | PutEffect<UpdateLocationConfigWithDeviceTypeSuccess>
    | RequestActions,
    void,
    BuildingConfiguration
>;

export function* updateLocationConfigWithDeviceTypeSaga({
    locationId,
    config,
}: UpdateLocationConfigWithDeviceType): UpdateLocationConfigWithDeviceTypeSagaReturnType {
    try {
        yield call(updateBuildingConfigWithDeviceType, locationId, config);
        const updatedBuildingConfig = yield call(getBuildingConfig, locationId);
        yield put(updateLocationConfigWithDeviceTypeSuccess(updatedBuildingConfig, locationId));
        yield put(requestSuccess(RequestType.UpdateLocationConfigurationWithDeviceType, RequestActionType.Success));
        yield call(displayAlertBoxSaga, 'FeaturesUpdated', false, true);
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(
                errorAsErrorType,
                RequestType.UpdateLocationConfigurationWithDeviceType,
                RequestActionType.Error
            )
        );
    }
}

type FetchResetConnectionsSagaReturnType = Generator<
    CallEffect<{ devices: ResetConnection[] }> | PutEffect<FetchLocationResetConnectionsSuccess> | RequestActions,
    void,
    { devices: ResetConnection[] }
>;

export function* fetchResetConnectionsSaga({
    locationId,
}: FetchLocationResetConnections): FetchResetConnectionsSagaReturnType {
    try {
        const response = yield call(getBuildingResetConnections, locationId);
        yield put(fetchLocationResetConnectionsSuccess(response.devices));
        yield put(requestSuccess(RequestType.FetchLocationResetConnections, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchLocationResetConnections, RequestActionType.Error));
    }
}

type GetBuildingSagaReturnType = Generator<
    | CallEffect<BuildingResponseType>
    | SelectEffect
    | CallEffect<{ [serialNumber: string]: DeviceType }>
    | CallEffect<DevicesWithKeyInfoResponse>
    | PutEffect<FetchBuildingSuccess>
    | RequestActions
    | CallEffect<LocationType[]>,
    void,
    BuildingResponseType &
        LocationType[] &
        DevicesWithKeyInfoResponse & { [serialNumber: string]: DeviceType } & HubData[]
>;

export function* getBuildingSaga({ locationId }: FetchBuilding): GetBuildingSagaReturnType {
    try {
        const location = yield call(getBuilding, locationId);
        const locationsWithDeviceRef: LocationType[] | BuildingType[] = transformResponseToLocationType([location]);
        const { devicesWithKeyInfo, hubsWithKeyInfo } = getDevicesFromLocationResponse([location]);
        yield put(
            fetchBuildingSuccess(
                locationsWithDeviceRef[0] as BuildingType,
                devicesWithKeyInfo,
                hubsWithKeyInfo,
                locationId
            )
        );
        yield put(requestSuccess(RequestType.FetchBuilding, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchBuilding, RequestActionType.Error));
    }
}

type GetVirtualDevicesSagaReturnType = Generator<
    | CallEffect
    | SelectEffect
    | RequestActions
    | CallEffect<BuildingType[]>
    | PutEffect<FetchVirtualDevicesSuccess>
    | PutEffect<FetchLocationsHistory>,
    void,
    AllDevicesResponse
>;
export function* getVirtualDevicesSaga({ locationId }: FetchVirtualDevices): GetVirtualDevicesSagaReturnType {
    try {
        const loggedIn = yield select(isLoggedIn);
        if (loggedIn) {
            const allDevices = yield call(fetchAllDevices, locationId);
            yield put(fetchVirtualDevicesSuccess(allDevices.devices));
            yield put(requestSuccess(RequestType.FetchVirtualDevices, RequestActionType.Success));
        }
        yield put(requestSuccess(RequestType.FetchVirtualDevices, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, RequestType.FetchVirtualDevices, RequestActionType.Error));
    }
}

export default function* EditLocationDetailsSagas(): Generator {
    yield all([
        takeEvery(ActionType.UpdateLocationDetailsInit, updateLocationDetailsSaga),
        takeEvery(LocationActionType.FetchLocationLabels, fetchLocationLabelsSaga),
        takeEvery(LocationActionType.GetLocationConfigInit, getLocationConfigSaga),
        takeEvery(LocationActionType.FetchBuildingInit, getBuildingSaga),
        takeEvery(LocationActionType.FetchVirtualDevicesInit, getVirtualDevicesSaga),
        takeEvery(LocationActionType.UpdateLocationConfigInit, updateLocationConfigSaga),
        takeEvery(LocationActionType.UpdateLocationConfigWithDeviceTypeInit, updateLocationConfigWithDeviceTypeSaga),
        takeEvery(LocationActionType.FetchLocationResetConnectionsInit, fetchResetConnectionsSaga),
        takeEvery(LocationActionType.UpdateLocationLabelsInit, updateLocationLabelsSaga),
    ]);
}
