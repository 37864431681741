import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorFullNameKey } from '../../constants';

import SensorIcon from './SensorIcon';

export type Props = {
    sensorType: string;
    textUppercase?: boolean;
};

const SensorLabel = ({ sensorType, textUppercase }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <div className="sensor__block unset-width flex flex-center">
            <div className="sensor__icon">
                <SensorIcon sensor={sensorType} />
            </div>
            <span
                className={classNames('sensor__name', {
                    'text-uppercase': textUppercase,
                    'text-capitalize': !textUppercase,
                })}
            >
                {txt(sensorFullNameKey(sensorType))}
            </span>
        </div>
    );
};

export default SensorLabel;
