import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnumStatusColors } from '../../models/commonEnums';
import SensorStatusDot from '../sensors/SensorStatusDot';

const NoConnection = ({ text, translated }: { text?: string; translated?: boolean }): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <span>
            <SensorStatusDot color={EnumStatusColors.red} />
            <span>{translated && text ? text : txt(text || 'NoHubConnection')}</span>
        </span>
    );
};

export default NoConnection;
