import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteLocation } from 'commons/src/actions/LocationActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_VIEWED_SETTINGS } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Error from 'commons/src/components/errorComponents/Error';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { squareTilesPlaceholder } from 'commons/src/components/placeholder';
import { paths, roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { Role } from 'commons/src/models/commonEnums';
import { BuildingType, ErrorType } from 'commons/src/models/commonTypeScript';
import { BusinessRequestTypesUsedInCommons } from 'commons/src/reducers/requestReducer';
import { fetchBuilding, fetchLocationLabels, getLocationConfig } from '../../../actions/locationActions';
import { fetchSpaces } from '../../../actions/spaceActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import ConfigureDevicesPage from '../configureDevices/ConfigureDevicesPage';
import CustomLabels from './CustomLabels';
import EditBuilding from './EditBuilding';
import ResetConnection from './ResetConnection';

export type Props = {
    loading: boolean;
    error: boolean;
    configLoading: boolean;
    labelsLoading: boolean;
    buildings: { [buildingId: string]: BuildingType };
    deleteLocationLoading: boolean;
    deleteLocationError?: ErrorType;
    userRole?: Role;
};

export const BuildingSettingsComponent = ({
    error,
    loading,
    configLoading,
    labelsLoading,
    buildings,
    deleteLocationError,
    deleteLocationLoading,
    userRole,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { buildingId: locationId } = useParams<'buildingId'>() as { buildingId: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const building = buildings[locationId];
    const locationError = !loading && !building;

    const [locationFetched, setLocationFetched] = useState(!!building);
    const [buildingSettingsOpen, setBuildingSettingsOpen] = useState(false);
    const [customLabelsOpen, setCustomLabelsOpen] = useState(false);
    const [resetConnectionOpen, setResetConnectionOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (deleteLoading && !deleteLocationLoading && !deleteLocationError) {
            navigate(`/${paths.buildings}`);
        }
        if (deleteLoading && !deleteLocationLoading && deleteLocationError) {
            setDeleteLoading(false);
        }
    }, [deleteLocationLoading, deleteLocationError]);

    useEffect(() => {
        if (!building) {
            dispatch(fetchBuilding(locationId));
            dispatch(fetchSpaces(locationId));
        } else analyticsLogger(BUILDING_VIEWED_SETTINGS, { pageType: PageType.Building, buildingName: building.name });
    }, []);

    useEffect((): void => {
        dispatch(fetchLocationLabels(locationId));
        dispatch(getLocationConfig(locationId));
    }, [locationId]);

    useEffect((): void => {
        if (!locationFetched && building) {
            setLocationFetched(true);
            analyticsLogger(BUILDING_VIEWED_SETTINGS, { pageType: PageType.Building, buildingName: building.name });
        }
    }, [building]);

    useEffect((): void => {
        if (deleteLocationError && !deleteLocationLoading && deleteModalOpen) {
            setDeleteModalOpen(false);
        }
    }, [deleteLocationError]);

    const onDeleteOpenClose = (): void => {
        setDeleteModalOpen(!deleteModalOpen);
    };
    const deleteBuilding = (): void => {
        setDeleteLoading(true);
        dispatch(deleteLocation(locationId));
    };

    if (error) {
        return <Error />;
    }

    if (locationError && !deleteLoading) {
        navigate(pageNotFoundUrl('buildingNotFound'));
    }

    const canDeleteBuilding = userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editDeviceOrBuilding);
    const buildingHasCurrentMeasurements = building && building.devices ? building.devices.length > 0 : false;
    const deleteBuildingDescription = buildingHasCurrentMeasurements
        ? txt('Building.MoveDevicesBeforeDeleteBuilding')
        : txt('Building.DeleteBuildingDescription');

    return (
        <div className="page-wrapper__medium" data-building-devices-page>
            {deleteModalOpen && (
                <DeleteConfirmModal
                    title="Building.DeleteBuilding"
                    description={deleteBuildingDescription}
                    onSubmit={deleteBuilding}
                    onCancel={onDeleteOpenClose}
                    onSubmitText="Delete"
                    onCancelText="Close"
                    loading={deleteLocationLoading}
                    hideDelete={buildingHasCurrentMeasurements}
                />
            )}
            <ReactPlaceholder ready={!!building} customPlaceholder={squareTilesPlaceholder}>
                <h2 className="settings__header">{txt('Building.Building')}</h2>
                <div className="settings-details-container settings-details-container--margin">
                    <CollapsableSectionHeader
                        openSection={setBuildingSettingsOpen}
                        headerIsOpen={buildingSettingsOpen}
                        headerText="Building.BuildingSettings"
                        subtext="Building.BuildingSettingsSubText"
                        testAttr="building-settings"
                    />
                    {buildingSettingsOpen && (
                        <div className="page-wrapper__inner page-wrapper__inner--slim">
                            <EditBuilding
                                closeOnSave={false}
                                onClose={(): void => setBuildingSettingsOpen(false)}
                                building={building}
                                locationId={locationId}
                            />
                        </div>
                    )}
                </div>
                <div className="settings-details-container settings-details-container--margin">
                    <CollapsableSectionHeader
                        openSection={setCustomLabelsOpen}
                        headerIsOpen={customLabelsOpen}
                        headerText="CustomLabels.CustomLabels"
                        subtext="CustomLabels.CustomLabelsSubText"
                        loading={customLabelsOpen && labelsLoading}
                    />
                    {customLabelsOpen && !labelsLoading && (
                        <div className="page-wrapper__inner page-wrapper__inner--slim">
                            <CustomLabels locationId={locationId} />
                        </div>
                    )}
                </div>
                <div className="settings-details-container settings-details-container--margin">
                    <CollapsableSectionHeader
                        openSection={setResetConnectionOpen}
                        headerIsOpen={resetConnectionOpen}
                        headerText="ResetConnection.ResetConnection"
                        subtext="ResetConnection.ResetConnectionSubText"
                        loading={resetConnectionOpen && configLoading}
                    />
                    {resetConnectionOpen && !configLoading && (
                        <div className="page-wrapper__inner page-wrapper__inner--slim">
                            <ResetConnection locationId={locationId} />
                        </div>
                    )}
                </div>
                <ConfigureDevicesPage building={building} />
                {canDeleteBuilding && (
                    <div className="change-location__form__buttons">
                        <PrimaryButton onClick={onDeleteOpenClose} color="alert" title="Delete" />
                    </div>
                )}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        requests: {
            [RequestType.FetchBuilding]: { loading: loadingBuildings, error: errorFetchBuildings },
            [RequestType.FetchLocationLabels]: { loading: labelsLoading },
            [RequestType.GetLocationConfiguration]: { loading: configLoading },
            [BusinessRequestTypesUsedInCommons.DeleteLocation]: {
                loading: deleteLocationLoading,
                error: deleteLocationError,
            },
        },
        userSettings: { loading: loadingUserInfo, selectedGroup },
        buildings: { buildings },
    } = state;
    return {
        deleteLocationLoading,
        deleteLocationError,
        loading: loadingUserInfo || loadingBuildings,
        error: !!errorFetchBuildings,
        buildings,
        labelsLoading,
        configLoading,
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(BuildingSettingsComponent);
