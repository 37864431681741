import React, { SyntheticEvent, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { CUSTOM_THRESHOLD_RESET, CUSTOM_THRESHOLD_SAVED } from 'commons/src/analytics/AnalyticsEvents';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import { colors } from 'commons/src/constants';
import { ThresholdsResourceScope, SensorTypes, ThresholdType } from 'commons/src/models/commonEnums';
import { ErrorType, ThresholdRange, SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { fetchSpaces, fetchSpaceSensorData } from '../../../../actions/spaceActions';
import { updateThresholdsApi, UpdateThresholdsRequest } from '../../../../api/thresholdsApi';
import { SpaceThresholds, SortedThresholdOptions } from '../../../../models/spaceModels';
import styles from './SpaceThresholdsModal.module.scss';

export type UseSpaceThresholdModalProps = {
    thresholds: SpaceThresholds;
    locationId: string;
    spaceId: string;
    selectedPeriod: SelectedPeriod;
    sensorType: string;
    onClose: () => void;
};
type SelectedThresholdScope = { id: string; scope: ThresholdsResourceScope; inputValue: string } | null;

export type UseSpaceThresholdModalReturn = {
    isModified: boolean;
    setIsModified: (value: boolean) => void;
    thresholdScopeOptions: Option[];
    sortedThresholdOptions: SortedThresholdOptions;
    selectedValues: number[];
    setSelectedValues: (value: number[]) => void;
    thumbColors: string[];
    sliderConfig: { min: number; max: number; step: number };
    loading: boolean;
    error: ErrorType | undefined;
    resetToDefault: () => void;
    handleThresholdScopeChange: (args: { id: string; inputValue: string }) => void;
    selectedThresholdScope: SelectedThresholdScope | null;
    descriptionKey: string;
    isValidNumberInput: (index: number) => boolean;
    validateInputValues: () => boolean;
    determineColorClass: (thresholdType: ThresholdType) => string;
    updateThreshold: (event: SyntheticEvent<HTMLInputElement>, index: number) => void;
    onSave: () => void;
    normalizeRanges: (ranges: ThresholdRange[], min: number, max: number) => ThresholdRange[];
    getButtonText: () => string;
};

const thresholdOrder: ThresholdType[] = Object.values(ThresholdType);
const useSpaceThresholdModal = ({
    thresholds,
    locationId,
    spaceId,
    selectedPeriod,
    sensorType,
    onClose,
}: UseSpaceThresholdModalProps): UseSpaceThresholdModalReturn => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const thresholdScopeOptions: Option[] = [
        { id: ThresholdsResourceScope.USER_GROUP, inputValue: txt('CustomThresholdScope.AccountDefault') },
        { id: ThresholdsResourceScope.LOCATION, inputValue: txt('CustomThresholdScope.CustomBuilding') },
        { id: ThresholdsResourceScope.SPACE, inputValue: txt('CustomThresholdScope.CustomSpace') },
    ];
    const userGroupId = getSelectedGroupFromStorage()?.userGroupId ?? '';
    const idMapping: Record<ThresholdsResourceScope, string> = {
        [ThresholdsResourceScope.USER_GROUP]: userGroupId,
        [ThresholdsResourceScope.LOCATION]: locationId,
        [ThresholdsResourceScope.SPACE]: spaceId,
    };
    const activeScope = thresholds?.thresholdOptions?.activeScope || ThresholdsResourceScope.USER_GROUP;
    const [isModified, setIsModified] = useState(false);
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [selectedThresholdScope, setSelectedThresholdScope] = useState<SelectedThresholdScope>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | undefined>(undefined);
    const [thumbColors, setThumbColors] = useState<string[]>([]);
    const [sliderConfig] = useState({
        min: thresholds?.customOptions?.minSelectableValue || 0,
        max: thresholds?.customOptions?.maxSelectableValue || 100,
        step: thresholds?.customOptions?.thresholdDelta || 1,
    });

    useEffect(() => {
        const defaultScope = activeScope || ThresholdsResourceScope.USER_GROUP;
        const defaultOption = thresholdScopeOptions?.find(option => option.id === defaultScope);
        if (defaultOption) {
            setSelectedThresholdScope({
                id: idMapping[defaultScope],
                scope: defaultScope,
                inputValue: defaultOption.inputValue,
            });
        } else {
            setSelectedThresholdScope(null);
        }
    }, [activeScope]);

    const sortedThresholdOptions = useMemo(() => {
        const scope = selectedThresholdScope?.scope || ThresholdsResourceScope.USER_GROUP;
        const optionsForScope = thresholds?.thresholdOptions?.options?.[scope];

        if (optionsForScope) {
            return [...optionsForScope].sort((a, b) => thresholdOrder.indexOf(a.type) - thresholdOrder.indexOf(b.type));
        }

        return thresholds?.customOptions?.defaultThresholdOptions || [];
    }, [selectedThresholdScope, thresholds]);

    const prevLoadingRef: React.MutableRefObject<boolean> = useRef(loading);
    useEffect(() => {
        if (prevLoadingRef.current && !loading && !error) {
            onClose();
        }
        prevLoadingRef.current = loading;
    }, [loading, prevLoadingRef]);

    const determineColorValue = (thresholdType: ThresholdType): string => {
        return [ThresholdType.LOW_ALERT, ThresholdType.HIGH_ALERT].includes(thresholdType)
            ? colors.graphRed
            : colors.yellowGlow;
    };

    useEffect(() => {
        const color = sortedThresholdOptions?.map(option => determineColorValue(option.type));
        setThumbColors(color);
        setSelectedValues(sortedThresholdOptions.map(option => option.value));
    }, [selectedThresholdScope, thresholds]);

    const updateThresholds = useCallback(async (request: UpdateThresholdsRequest): Promise<void> => {
        setLoading(true);
        await updateThresholdsApi(request)
            .then(() => {
                setLoading(false);
                dispatch(fetchSpaces(locationId));
                dispatch(fetchSpaceSensorData(locationId, spaceId, selectedPeriod));
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    }, []);

    const logThresholdSaved = (request: UpdateThresholdsRequest): void => {
        analyticsLogger(CUSTOM_THRESHOLD_SAVED, {
            resourceScope: request.resourceScope,
            id: request.id,
            sensors: request.sensors.map(sensor => ({
                sensor: sensor.sensor,
                thresholds: sensor.thresholds.map(threshold => ({
                    thresholdType: threshold.thresholdType,
                    value: threshold.value,
                })),
            })),
        });
    };

    const onSave = (): void => {
        if (!selectedThresholdScope?.id) {
            return;
        }
        const request: UpdateThresholdsRequest = {
            resourceScope: selectedThresholdScope?.scope as ThresholdsResourceScope,
            id: selectedThresholdScope?.id,
            sensors: [
                {
                    sensor: sensorType as keyof typeof SensorTypes,
                    thresholds: Object.entries(selectedValues).map(([index, value]) => ({
                        thresholdType: sortedThresholdOptions[parseInt(index, 10)].type,
                        value,
                    })),
                },
            ],
        };
        logThresholdSaved(request);
        updateThresholds(request).catch(() => setIsModified(false));
    };

    const determineColorClass = (thresholdType: ThresholdType): string => {
        return [ThresholdType.LOW_ALERT, ThresholdType.HIGH_ALERT].includes(thresholdType)
            ? styles.dotColorRed
            : styles.dotColorYellow;
    };
    const updateThreshold = (event: SyntheticEvent<HTMLInputElement>, index: number): void => {
        const { value } = event.currentTarget;
        const numericValue = Number(value);
        if (Number.isNaN(numericValue)) return;
        const updatedValues = [...selectedValues];
        if (numericValue < sliderConfig.min || numericValue > sliderConfig.max) return;
        updatedValues[index] = numericValue;
        setSelectedValues(updatedValues);
        setIsModified(true);
    };
    const getButtonText = (): string => {
        const customScope = selectedThresholdScope?.scope || ThresholdsResourceScope.USER_GROUP;
        return txt(`CustomThresholds.ThresholdsText${customScope}`);
    };

    const descriptionKey = `CustomThresholds.${sensorType}Description`;

    const isValidNumberInput = (index: number): boolean => {
        const currentValue = selectedValues[index];
        const previousValue = selectedValues[index - 1];
        const nextValue = selectedValues[index + 1];

        return (
            currentValue !== undefined &&
            (previousValue === undefined || currentValue > previousValue) &&
            (nextValue === undefined || currentValue < nextValue)
        );
    };

    const validateInputValues = (): boolean =>
        sortedThresholdOptions.every((_, index) => selectedValues[index] !== undefined);

    const resetToDefault = (): void => {
        analyticsLogger(CUSTOM_THRESHOLD_RESET, {});
        const defaultThresholdOptions = thresholds?.customOptions?.defaultThresholdOptions;
        if (defaultThresholdOptions && defaultThresholdOptions.length > 0) {
            const sortedDefaultValues = [...defaultThresholdOptions].sort(
                (a, b) => thresholdOrder.indexOf(a.type) - thresholdOrder.indexOf(b.type)
            );
            const defaultValues = sortedDefaultValues.map(option => option.value);
            setSelectedValues(defaultValues);
        } else {
            setSelectedValues([]);
        }
    };

    const handleThresholdScopeChange = ({ id, inputValue }: { id: string; inputValue: string }): void => {
        const updatedId = idMapping[id as ThresholdsResourceScope];
        setSelectedThresholdScope({ id: updatedId, scope: id as ThresholdsResourceScope, inputValue });
        setIsModified(true);
    };

    const normalizeRanges = (ranges: ThresholdRange[], min: number, max: number): ThresholdRange[] => {
        return ranges?.map(range => ({
            from: range.from !== undefined ? range.from : min,
            to: range.to !== undefined ? range.to : max,
            rating: range.rating,
        }));
    };

    return {
        isModified,
        setIsModified,
        thresholdScopeOptions,
        sortedThresholdOptions,
        selectedValues,
        setSelectedValues,
        thumbColors,
        sliderConfig,
        loading,
        error,
        resetToDefault,
        handleThresholdScopeChange,
        selectedThresholdScope,
        descriptionKey,
        isValidNumberInput,
        validateInputValues,
        determineColorClass,
        updateThreshold,
        onSave,
        normalizeRanges,
        getButtonText,
    };
};

export default useSpaceThresholdModal;
