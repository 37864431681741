import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLocationsStatus, stopPollingLocationsStatus } from 'commons/src/actions/LocationsStatusActions';
import { analyticsLogger, mixpanelRegisterSuperProperties, PageType } from 'commons/src/analytics';
import { BUILDING_CLICKED_ADD, BUILDING_VIEWED_OVERVIEW } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { buildingTilesPlaceholder } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import { hasFeatureToggle } from 'commons/src/features/featureToggle/filterOnFeatureToggles';
import { FeatureToggleEnum, GroupType } from 'commons/src/models/commonEnums';
import { Group, LocationType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { getBuildingsFocusedSensor } from '../../actions/buildingsTimeOverThresholdActions';
import { paths } from '../../constants';
import { BuildingFocusedSensorData } from '../../models/buildingModels';
import { Store } from '../../reducers';
import RequestType from '../../reducers/BusinessRequestType';
import BuildingsFocusSelector from '../insights/timeOverThreshold/BuildingsFocusSelector';
import style from './Buildings.module.scss';
import NoBuildings from './NoBuildings';
import SortedBuildings from './SortedBuildings';

type StateProps = {
    loading: boolean;
    locations: Array<LocationType>;
    error: boolean;
    setFocusedSensorDataLoading: boolean;
    focusedSensorDataLoading: boolean;
    locationsData: { [locationId: string]: BuildingFocusedSensorData };
    selectedGroup?: Group;
};

export type Props = StateProps;

export const BuildingsComponent = ({
    loading,
    locations,
    error,
    setFocusedSensorDataLoading,
    focusedSensorDataLoading,
    locationsData,
    selectedGroup,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedGroup) {
            mixpanelRegisterSuperProperties({
                organizationId: selectedGroup.organizationId,
                groupId: selectedGroup.id,
                organization: selectedGroup.organizationName,
                role: selectedGroup.role,
                groupType: selectedGroup.groupType,
                managedBy: selectedGroup.managedByUserGroup,
            });
            if (
                selectedGroup.groupType === GroupType.partner &&
                !hasFeatureToggle(FeatureToggleEnum.oldPartnerView, selectedGroup.features)
            ) {
                navigate({ pathname: `/${paths.partnerPage}` });
            }
        }
    }, [selectedGroup]);

    useEffect((): void => {
        analyticsLogger(BUILDING_VIEWED_OVERVIEW, { pageType: PageType.Building });
    }, []);

    useEffect(() => {
        if (selectedGroup) {
            dispatch(getBuildingsFocusedSensor());
            dispatch(fetchLocationsStatus());
        }
        return (): void => {
            dispatch(stopPollingLocationsStatus());
        };
    }, [selectedGroup]);

    if (error) {
        return <Error />;
    }

    const addBuilding = (): void => {
        navigate({ pathname: `/${paths.addBuilding}` });
        analyticsLogger(BUILDING_CLICKED_ADD, { pageType: PageType.Building });
    };

    const goToEmailSummary = (): void => {
        navigate({ pathname: `/${paths.emailSummary}` });
    };

    const actionButtons: ActionButton[] = [
        {
            testAttr: 'go-to-email-summary',
            title: 'EmailSummary.EmailSummary',
            id: 'goToEmailSummary',
            icon: <MaterialIcon name="email" />,
            color: 'secondary',
            onClick: goToEmailSummary,
            requiredRoleLevel: roleRestrictions.createEditEmailSummary,
            requiredGroupTypes: [],
        },
        {
            testAttr: 'add-building',
            title: 'Building.AddBuilding',
            id: 'addBuilding',
            icon: <MaterialIcon name="add" />,
            color: 'primary',
            onClick: addBuilding,
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div data-buildings-page>
            <PageHeader title={txt('Buildings')} />
            {locations.length > 0 && (
                <div className="page-wrapper">
                    <SubHeader actionButtons={actionButtons} />
                </div>
            )}
            <ReactPlaceholder ready={!loading} customPlaceholder={buildingTilesPlaceholder}>
                {locations.length > 0 && (
                    <div className="page-wrapper">
                        <h2 className={style.subHeader}>{txt('BuildingInsight.TimeOverThreshold')}</h2>
                        <BuildingsFocusSelector />
                    </div>
                )}
                <ReactPlaceholder
                    ready={
                        !setFocusedSensorDataLoading &&
                        (!focusedSensorDataLoading || Object.keys(locationsData).length > 0)
                    }
                    customPlaceholder={buildingTilesPlaceholder}
                >
                    {locations.length > 0 ? <SortedBuildings /> : <NoBuildings />}
                </ReactPlaceholder>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { loading, locations, error },
        userSettings: { loading: loadingUser, selectedGroup },
        buildingsOverview: { locationsData },
        requests: {
            [RequestType.GetBuildingsFocusedSensorData]: { loading: focusedSensorDataLoading },
            [RequestType.BuildingsThresholdsSetFocusedSensor]: { loading: setFocusedSensorLoading },
        },
    } = state;
    return {
        loading: loading || loadingUser,
        locations,
        error,
        setFocusedSensorDataLoading: setFocusedSensorLoading,
        focusedSensorDataLoading,
        locationsData,
        selectedGroup,
    };
};
export default connect(mapStateToProps)(BuildingsComponent);
