import React, { useMemo } from 'react';
import { Slider as MuiSlider } from '@mui/material';
import { ThresholdSlidercolorMap, colors } from 'commons/src/constants';
import { ThresholdRange } from '../../models/commonTypeScript';

interface Props {
    value: number[];
    onChange: (event: Event, val: number[]) => void;
    ranges?: ThresholdRange[];
    thumbColors?: string[];
    min: number;
    max: number;
    step?: number;
    showGradient?: boolean;
    size?: 'small' | 'medium';
    track?: 'normal' | 'inverted';
    className?: string;
    valueLabelDisplay?: 'on' | 'auto' | 'off';
    marks?: Array<{ value: number; label: string }>;
}

const CustomSlider: React.FC<Props> = ({
    value: thumbPositions,
    onChange,
    ranges = [],
    min,
    max,
    step = 1,
    showGradient = true,
    size = 'small',
    thumbColors,
    track = 'normal',
    className,
    valueLabelDisplay = 'off',
    marks,
}: Props): React.ReactElement => {
    const generateGradient = useMemo(() => {
        if (!showGradient) return colors.greyNepal;
        const extendedRanges = ranges.map((range, index) => {
            if (index === 0) {
                return { ...range, to: thumbPositions[0] };
            }
            if (index === 1) {
                return { ...range, from: thumbPositions[0], to: thumbPositions[1] };
            }
            if (index === 2) {
                return { ...range, from: thumbPositions[1], to: thumbPositions[2] };
            }
            if (index === 3) {
                return { ...range, from: thumbPositions[2], to: thumbPositions[3] };
            }
            if (index === 4) {
                return { ...range, from: thumbPositions[3] };
            }
            return range;
        });
        const stops: string[] = [];
        let currentStart = min;
        extendedRanges.forEach(range => {
            const start = range.from !== undefined ? range.from : currentStart;
            const end = range.to !== undefined ? range.to : max;
            const startPercent = ((start - min) / (max - min)) * 100;
            const endPercent = ((end - min) / (max - min)) * 100;
            stops.push(`${ThresholdSlidercolorMap[range.rating]} ${startPercent}% ${endPercent}%`);
            currentStart = end;
        });
        return `linear-gradient(to right, ${stops.join(', ')})`;
    }, [showGradient, ranges, min, max, thumbPositions]);

    const handleInternalSliderChange = (event: Event, val: number | number[]): void => {
        const updatedValues = Array.isArray(val) ? [...val] : [val];
        if (updatedValues[0] < min) updatedValues[0] = min;
        if (updatedValues[updatedValues.length - 1] > max) {
            updatedValues[updatedValues.length - 1] = max;
        }
        if (updatedValues.length > 1 && updatedValues[1] <= updatedValues[0]) {
            updatedValues[1] = updatedValues[0] + step;
        }
        onChange(event, updatedValues as number[]);
    };

    return (
        <MuiSlider
            className={className}
            value={thumbPositions}
            min={min}
            max={max}
            step={step}
            size={size}
            track={track}
            marks={marks}
            valueLabelDisplay={valueLabelDisplay}
            onChange={handleInternalSliderChange}
            sx={{
                '& .MuiSlider-rail': {
                    height: 5,
                    background: generateGradient,
                    opacity: 1,
                },
                '& .MuiSlider-track': {
                    height: 8,
                    backgroundColor: 'transparent',
                    opacity: 1,
                    border: '1px solid white',
                },
                '& .MuiSlider-valueLabel': {
                    left: 'unset',
                    top: 'unset',
                    bottom: -58,
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    opacity: 0.8,
                    '&:before': {
                        position: 'absolute',
                        content: '""',
                        width: 8,
                        height: 8,
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, -50%) rotate(45deg)',
                        backgroundColor: 'inherit',
                    },
                    '& > *': {
                        transform: 'rotate(0deg)',
                    },
                },
                '& .MuiSlider-thumb': {
                    width: 16,
                    height: 16,
                    ...thumbColors?.reduce((acc, color, index) => {
                        return {
                            ...acc,
                            [`&[data-index='${index}']`]: {
                                backgroundColor: color,
                            },
                        };
                    }, {}),
                },
            }}
        />
    );
};

export default CustomSlider;
