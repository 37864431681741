import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { fetchApiClients } from 'commons/src/actions/apiClientActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { INTEGRATION_VIEWED_INTEGRATION } from 'commons/src/analytics/AnalyticsEvents';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { fetchWebhooks } from '../../actions/integrationActions';
import { fetchMqttClients } from '../../actions/mqttIntegrationActions';
import { fetchThirdPartyIntegrations } from '../../actions/thirdPartyIntegrationActions';
import { Store } from '../../reducers';
import { BusinessRequestType } from '../../reducers/BusinessRequestType';
import IntegrationMenuConfig from './IntegrationMenuConfig';

type Props = {
    loadingApiClients: boolean;
    loadingMqttClients: boolean;
    loadingThirdPartyIntegrations: boolean;
    loadingWebhooks: boolean;
    loadingUserSettings: boolean;
};

export const IntegrationsPageLayoutComponent = ({
    loadingApiClients,
    loadingMqttClients,
    loadingThirdPartyIntegrations,
    loadingWebhooks,
    loadingUserSettings,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingApiClients) {
            dispatch(fetchApiClients());
        }
        if (!loadingThirdPartyIntegrations) {
            dispatch(fetchThirdPartyIntegrations());
        }
        if (!loadingWebhooks) {
            dispatch(fetchWebhooks());
        }
        analyticsLogger(INTEGRATION_VIEWED_INTEGRATION, {
            pageType: PageType.Integration,
            page: 'Overview',
        });
    }, []);

    useEffect(() => {
        if (!(loadingMqttClients || loadingUserSettings)) {
            dispatch(fetchMqttClients());
        }
    }, [loadingUserSettings]);

    return (
        <>
            <PageHeader title={txt('Integrations.Integrations')} tabs={IntegrationMenuConfig} />
            <div className="page-wrapper__medium">
                <Outlet />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { loading: loadingUserSettings },
        requests: {
            [BusinessRequestType.FetchWebhooks]: { loading: loadingWebhooks },
            [BusinessRequestType.FetchThirdPartyIntegrations]: { loading: loadingThirdPartyIntegrations },
            [BusinessRequestType.FetchMqttClients]: { loading: loadingMqttClients },
        },
        commonRequests: {
            [CommonRequestType.FetchApiClients]: { loading: loadingApiClients },
        },
    } = state;
    return {
        loadingUserSettings,
        loadingWebhooks,
        loadingApiClients,
        loadingThirdPartyIntegrations,
        loadingMqttClients,
    };
};
export default connect(mapStateToProps)(IntegrationsPageLayoutComponent);
