import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from 'commons/src/components/input/Checkbox';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';
import ThresholdSlider, { Config } from './ThresholdSlider';

type ThresholdsProps = {
    sensorType: SensorTypes;
    setThresholdValue: (sensor: SensorTypes, thresholds: (number | undefined)[]) => void;
    setEnabledThreshold: (sensor: SensorTypes, enabled: boolean) => void;
    enabled?: boolean;
    thresholds: (number | undefined)[];
    config: Config;
};

const SensorThresholdLabel = ({
    sensorType,
    config,
}: {
    sensorType: SensorTypes;
    config: Config;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="inlet-air-thresholds__icons">
            <div className="device-svg-icons inlet-air-thresholds__icons__circle">
                <SensorIcon sensor={sensorType} />
            </div>
            <div className="inlet-air-thresholds__icons__text">
                <span className="inlet-air-thresholds__icons__unit">
                    {[txt(sensorType), ` (${sensorUnits[config.unit]})`]}
                </span>
            </div>
        </div>
    );
};

const ThresholdWrapper = (props: ThresholdsProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { setThresholdValue, setEnabledThreshold, enabled, thresholds, config, sensorType } = props;

    const min = thresholds[0] || config.recommended.min;
    const max = thresholds[1] || config.recommended.max;

    const updateSlider = (newThresholds: number | (number | undefined)[]): void =>
        setThresholdValue(sensorType, newThresholds as (number | undefined)[]);

    const toggleEnabled = (e: SyntheticEvent<HTMLInputElement>): void =>
        setEnabledThreshold(sensorType, e.currentTarget.checked);

    const {
        recommended: { min: recommendedMin, max: recommendedMax },
    } = config;

    return (
        <>
            <div className="form__row form__row--padded-small">
                {txt(config.descriptionKey, {
                    min: recommendedMin.toString(),
                    max: recommendedMax.toString(),
                })}
            </div>
            <div className="form__row form__row--padded-small">
                <div className="form__field form__field--single-width">
                    <CheckBox
                        onChange={toggleEnabled}
                        checked={!!enabled}
                        id={`enabled-${sensorType}`}
                        label={<SensorThresholdLabel sensorType={sensorType} config={config} />}
                        labelIsElement
                    />
                </div>
            </div>
            {enabled && (
                <div className="form__row form__row--padded-small">
                    <ThresholdSlider value={[min, max]} onChange={updateSlider} config={config} />
                </div>
            )}
        </>
    );
};

export default ThresholdWrapper;
