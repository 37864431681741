import React, { SyntheticEvent, FormEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { changePassword } from '../../../actions/SettingsActions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Input from '../../../components/input/Input';
import ResponseBox from '../../../components/responseMessages/ResponseBox';
import { PASSWORD_REGEX } from '../../../constants';
import { ErrorType } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';

type Props = {
    loading: boolean;
    error?: ErrorType;
};

export const ChangePasswordComponent = ({ loading, error }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);
    const [validateNewPassword, setValidateNewPassword] = useState(false);
    const [confirmedPasswordIsValid, setConfirmedPasswordIsValid] = useState(false);
    const [validateConfirmedPassword, setValidateConfirmedPassword] = useState(false);
    const [validateOldPassword, setValidateOldPassword] = useState(false);

    const { t: txt } = useTranslation();

    const setInputValidation = (errorProp: string): void => {
        setValidateOldPassword(errorProp === 'invalid_request_user_change_password_invalid_old_password');
    };

    const onChangeCurrentPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const oldPasswordParam = e.currentTarget.value.trim();

        setOldPassword(oldPasswordParam);
        setValidateOldPassword(false);
    };

    const onBlurCurrentPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const oldPasswordParam = e.currentTarget.value.trim();

        setOldPassword(oldPasswordParam);
        setValidateOldPassword(oldPasswordParam.length === 0);
    };

    const onChangeNewPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const newPasswordParam = e.currentTarget.value.trim();

        setNewPassword(newPasswordParam);
        setValidateNewPassword(false);
        setConfirmedPasswordIsValid(newPasswordParam === confirmedPassword);
    };

    const onBlurNewPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const newPasswordParam = e.currentTarget.value.trim();

        setNewPassword(newPasswordParam);
        setNewPasswordIsValid(PASSWORD_REGEX.test(newPasswordParam));
        setValidateNewPassword(true);
        setConfirmedPasswordIsValid(newPasswordParam === confirmedPassword);
    };

    const onChangeConfirmPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const confirmedPasswordParam = e.currentTarget.value.trim();

        setConfirmedPassword(confirmedPasswordParam);
        setValidateConfirmedPassword(false);
    };

    const onBlurConfirmPassword = (e: SyntheticEvent<HTMLInputElement>): void => {
        const confirmedPasswordParam = e.currentTarget.value.trim();

        setConfirmedPassword(confirmedPasswordParam);
        setValidateConfirmedPassword(true);
        setConfirmedPasswordIsValid(confirmedPasswordParam === newPassword);
    };

    useEffect(() => {
        if (!loading && error) {
            setInputValidation(error.error);
        }
    }, [error, loading]);

    const validateForm = (): boolean => {
        const oldPasswordIsValid = oldPassword.length > 0;
        const newPasswordIsValidParam = PASSWORD_REGEX.test(newPassword);
        const confirmedPasswordIsValidParam = newPassword === confirmedPassword;

        setValidateOldPassword(!oldPasswordIsValid);
        setValidateNewPassword(!newPasswordIsValidParam);
        setValidateConfirmedPassword(!confirmedPasswordIsValidParam);
        setConfirmedPasswordIsValid(confirmedPasswordIsValid);
        setNewPasswordIsValid(newPasswordIsValid);

        return oldPasswordIsValid && newPasswordIsValidParam && confirmedPasswordIsValidParam;
    };

    const onSubmitPassword = (e: FormEvent<HTMLFormElement> | SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (validateForm()) {
            dispatch(
                changePassword({
                    oldPassword,
                    newPassword: confirmedPassword,
                })
            );
        }
    };

    return (
        <div>
            <h2 className="settings__header">{txt('ChangePassword')}</h2>
            <div className="settings-details-container">
                <form id="changePasswordForm" onSubmit={onSubmitPassword}>
                    <div className="form__field form__field--single-width">
                        <Input
                            type="password"
                            id="currentPassword"
                            label="CurrentPassword"
                            required
                            hint="CurrentPasswordHint"
                            isValid={false}
                            autoComplete="off"
                            currentValue={oldPassword}
                            validate={validateOldPassword}
                            onChange={onChangeCurrentPassword}
                            onBlur={onBlurCurrentPassword}
                            testId="current-password"
                        />
                    </div>
                    <div className="form__field form__field--single-width">
                        <Input
                            type="password"
                            id="newPassword"
                            label="NewPassword"
                            required
                            hint="PasswordHint"
                            isValid={newPasswordIsValid}
                            autoComplete="off"
                            currentValue={newPassword}
                            validate={validateNewPassword}
                            onChange={onChangeNewPassword}
                            onBlur={onBlurNewPassword}
                            testId="new-password"
                        />
                    </div>
                    <div className="form__field form__field--single-width">
                        <Input
                            type="password"
                            id="confirmPassword"
                            label="ConfirmPassword"
                            required
                            currentValue={confirmedPassword}
                            hint="PasswordConfirmHint"
                            isValid={confirmedPasswordIsValid}
                            autoComplete="off"
                            validate={validateConfirmedPassword}
                            onChange={onChangeConfirmPassword}
                            onBlur={onBlurConfirmPassword}
                            testId="confirmed-password"
                        />
                    </div>
                    {error && <ResponseBox text="error" subtext={txt(`ErrorCodes.${error.error}`)} />}
                    <div className="settings__row">
                        <PrimaryButton
                            id="submitNewPasswordButton"
                            title="ChangePassword"
                            type="submit"
                            loading={loading}
                            onClick={onSubmitPassword}
                            disabled={false}
                            color="primary"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { loading, changePasswordError },
    } = store;
    return {
        error: changePasswordError,
        loading,
    };
};

export default connect(mapStateToProps)(ChangePasswordComponent);
