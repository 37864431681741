import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BuildingInsight } from '../../../models/buildingModels';
import { SensorBreachThresholds } from '../../../models/common';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';

type ParentProps = {
    onSave: (selectedSensor: SensorTypes) => void;
};

type StateProps = {
    thresholdBreachInsights: BuildingInsight[];
    loading: boolean;
    thresholdOptions?: SensorBreachThresholds;
};

export type Props = ParentProps & StateProps;

export const AddInsightSensor = ({
    onSave,
    loading,
    thresholdOptions,
    thresholdBreachInsights,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedSensor, setSelectedSensor] = useState<SensorTypes | undefined>(undefined);
    const sensorOptions = thresholdOptions ? Object.keys(thresholdOptions).map(key => key as SensorTypes) : [];
    const sensorOptionsNotAlreadyInUse = sensorOptions.filter(
        sensor => !thresholdBreachInsights.find(insight => insight.sensor === sensor)
    );

    const onSubmit = (sensor: SensorTypes): void => {
        setSelectedSensor(sensor);
        onSave(sensor as SensorTypes);
    };

    if (sensorOptionsNotAlreadyInUse.length === 0) return <div />;

    return (
        <div className="info-list-tile info-list-tile--with-margin">
            {txt('BuildingInsight.SelectChartSensor')}
            {sensorOptionsNotAlreadyInUse.map(sensor => (
                <div className="small-padding-top small-padding-bottom" key={`ThresholdBreachAdd${sensor}`}>
                    <PrimaryButton
                        color="secondary"
                        onClick={(): void => onSubmit(sensor)}
                        icon={<SensorIcon sensor={sensor} />}
                        title={txt(sensor === SensorTypes.temp ? 'Temperature' : sensor)}
                        disabled={loading}
                        loading={selectedSensor === sensor && loading}
                    />
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        thresholdBreachInsight: { thresholdOptions, thresholdBreachInsights },
        requests: {
            [RequestType.CreateThresholdBreachInsight]: { loading },
        },
    } = store;
    return { thresholdOptions, thresholdBreachInsights, loading };
};

export default connect(mapStateToProps)(AddInsightSensor);
