import React, { RefObject, SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import InputLabel from 'commons/src/components/input/InputLabel';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { LocationType } from 'commons/src/models/commonTypeScript';
import {
    deleteMqttClient,
    fetchMqttClientCertificate,
    updateMqttClient,
} from '../../../actions/mqttIntegrationActions';
import { mqttClientNameValidation } from '../../../constants';
import { MqttClient, MqttClientCertificate } from '../../../models/common';
import { IotCertificateStatus } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import LocationsSelector from '../webhooks/LocationsSelector';
import AddEditFormComponent from './AddEditFormComponent';
import styles from './MqttEditForm.module.scss';
import OptionsModal from './RenewOptionsModal';
import UploadCertificateModal from './UploadCertificateModal';

type Props = {
    clients: MqttClient[];
    certificate: MqttClientCertificate | undefined;
    locations: LocationType[];
    dateFormat: string;
    deleteLoading: boolean;
    fetchLoading: boolean;
    uploadLoading: boolean;
    saveLoading: boolean;
};

export const MqttEditFormComponent = ({
    clients,
    locations,
    certificate,
    dateFormat,
    deleteLoading,
    fetchLoading,
    uploadLoading,
    saveLoading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const { clientId } = useParams<'clientId'>() as { clientId: string };
    const downloadLinkRef: RefObject<HTMLAnchorElement> = React.createRef();
    const maxSelectableLocations = 10;

    const client = clients.find(c => c.id === clientId);
    const certAvailable = !!(client && client.certificateInfo);
    const clientHasUserGroupSubscription =
        client &&
        client.subscriptions &&
        client.subscriptions.some(
            subscription => !!subscription.userGroupId && !subscription.locationId && !subscription.serialNumber
        );
    const selectableLocations = locations.map(location => location.id);
    const subscriptionLocationIds =
        (client && client.subscriptions && client.subscriptions.map(subscription => subscription.locationId)) || [];

    const [clientName, setClientName] = useState(client ? client.clientName : '');
    const [description, setDescription] = useState(client ? client.description : '');
    const [validClient, setValidClient] = useState(true);
    const [selectedLocations, setSelectedLocations] = useState<string[]>(
        clientHasUserGroupSubscription
            ? selectableLocations
            : selectableLocations.filter(location => subscriptionLocationIds.includes(location))
    );

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [displayUploadModal, setDisplayUploadModal] = useState(false);
    const [displayRenewModal, setDisplayRenewModal] = useState(false);
    const [displayRenewOptionsModal, setDisplayRenewOptionsModal] = useState(false);
    const [active, setActive] = useState(
        client && client.certificateInfo ? client.certificateInfo.status === IotCertificateStatus.Active : false
    );
    const [isRenewing, setIsRenewing] = useState(false);

    const subscribedToUserGroup = selectedLocations.length === locations.length;
    const validUntil =
        client && client.certificateInfo
            ? moment(client.certificateInfo.validUntil).format(
                  dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
              )
            : '-';

    useEffect(() => {
        if ((!fetchLoading || !uploadLoading) && certificate) {
            const downloadLink = downloadLinkRef.current;
            if (downloadLink) {
                downloadLink.click();
            }
        }
    }, [fetchLoading, certificate]);

    const validateClient = (): boolean => {
        const validationResult =
            clientName.length > 0 &&
            clientName.indexOf(' ') === -1 &&
            RegExp(mqttClientNameValidation).test(clientName);
        setValidClient(validationResult);
        return validationResult;
    };

    const updateInput = (newName: string): void => {
        setClientName(newName);
        if (!validClient) {
            validateClient();
        }
    };

    const updateActive = (): void => {
        setActive(!active);
    };

    const onDeleteClient = (): void => {
        if (client) dispatch(deleteMqttClient(client.id));
    };
    const onDeleteClick = (e: SyntheticEvent<HTMLButtonElement, Event>): void => {
        e.preventDefault();
        setDisplayDeleteModal(true);
    };
    const closeDeleteModal = (): void => setDisplayDeleteModal(false);

    const toggleUploadModal = (): void => setDisplayUploadModal(!displayUploadModal);
    const toggleRenewModal = (): void => setDisplayRenewModal(!displayRenewModal);
    const toggleRenewOptionsModal = (): void => setDisplayRenewOptionsModal(!displayRenewOptionsModal);

    const validAmountOfLocations =
        selectedLocations.length <= maxSelectableLocations || selectedLocations.length === selectableLocations.length;

    const onSaveClick = (): void => {
        if (validAmountOfLocations && client && validateClient()) {
            const subscriptions = subscribedToUserGroup
                ? [{ userGroupId: client.userGroupId }]
                : selectedLocations.map(location => ({
                      userGroupId: client.userGroupId,
                      locationId: location,
                  }));
            dispatch(
                updateMqttClient({
                    id: client.id,
                    userGroupId: client.userGroupId,
                    clientName,
                    description,
                    subscriptions,
                    active,
                })
            );
        }
    };
    const onGetCertificateClick = (): void => {
        if (client) dispatch(fetchMqttClientCertificate(client.id));
    };

    const onRenewModalSubmitClicked = (): void => {
        toggleRenewModal();
        toggleRenewOptionsModal();
    };

    const onRenewUploadClicked = (): void => {
        setIsRenewing(true);
        toggleRenewOptionsModal();
        toggleUploadModal();
    };

    const onDownloadRenewedCertificate = (): void => {
        toggleRenewOptionsModal();
    };

    return (
        <div>
            <div className="page-wrapper__medium page-wrapper__medium--white">
                <div className="settings-details-container settings-details-container--margin">
                    <div className="certificate__info">
                        {client && client.certificateInfo && (
                            <MaterialIcon extraClass="certificate__info--icon" name="check_circle" fill />
                        )}
                        <div className="certificate__info--status">
                            <h3 className="certificate__info--status-info">
                                {txt('Mqtt.CertificateInfo', { date: validUntil })}
                            </h3>
                            <h4 className="certificate__info--status-info">
                                {`${txt('Mqtt.Issuer')}: ${
                                    client && client.certificateInfo ? client.certificateInfo.distinguishedName : '-'
                                }`}
                            </h4>
                        </div>
                    </div>

                    <div className="form__row form__button-container form__button-container--left form__button-container--no-padding">
                        {certAvailable ? (
                            <div className="form__attr--element">
                                <PrimaryButton
                                    id="renew-cert-button"
                                    icon={<MaterialIcon name="refresh" />}
                                    onClick={toggleRenewModal}
                                    type="button"
                                    title="Mqtt.RenewCert"
                                    color="primary"
                                    loading={uploadLoading}
                                    testAttr="renew-cert"
                                />
                            </div>
                        ) : (
                            <>
                                <div className="form__attr--element">
                                    <PrimaryButton
                                        id="get-aws-cert-button"
                                        icon={<MaterialIcon name="download" />}
                                        onClick={onGetCertificateClick}
                                        type="button"
                                        title="Mqtt.DownloadCert"
                                        color="primary"
                                        testAttr="get-mqtt-aws-cert"
                                        loading={fetchLoading}
                                    />
                                    <a
                                        href={certificate && certificate.downloadUrl}
                                        download={certificate && certificate.fileName}
                                        ref={downloadLinkRef}
                                        hidden
                                    >
                                        {txt('Mqtt.Certificate')}
                                    </a>
                                </div>
                                <div className="form__attr--element">
                                    <PrimaryButton
                                        color="secondary"
                                        id="upload-cert-button"
                                        icon={<MaterialIcon name="upload" />}
                                        onClick={toggleUploadModal}
                                        type="button"
                                        title="Mqtt.UploadCert"
                                        loading={uploadLoading}
                                        testAttr="upload-cert"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="page-wrapper__medium page-wrapper__medium--white">
                {displayDeleteModal && (
                    <DeleteConfirmModal
                        title="Mqtt.DeleteClient"
                        description={txt('Mqtt.DeleteDescription', { name: client ? client.clientName : '' })}
                        onSubmit={onDeleteClient}
                        onCancel={closeDeleteModal}
                        onSubmitText="Delete"
                        onCancelText="Cancel"
                        loading={deleteLoading}
                    />
                )}
                {displayRenewModal && (
                    <DeleteConfirmModal
                        title="Mqtt.RenewCert"
                        description={txt('Mqtt.RenewDescription')}
                        onSubmit={onRenewModalSubmitClicked}
                        onCancel={toggleRenewModal}
                        onSubmitText="Mqtt.RenewCert"
                        onCancelText="No"
                        loading={deleteLoading}
                        regularSubmitButton
                    />
                )}
                <UploadCertificateModal
                    onClose={toggleUploadModal}
                    isOpen={displayUploadModal}
                    client={client}
                    isRenewing={isRenewing}
                />
                <OptionsModal
                    onClose={toggleRenewOptionsModal}
                    isOpen={displayRenewOptionsModal}
                    client={client}
                    onUploadClick={onRenewUploadClicked}
                    onDownloadCertificate={onDownloadRenewedCertificate}
                />
                {certAvailable && (
                    <div className="settings-details-container mqtt-edit-form">
                        <form>
                            <AddEditFormComponent
                                onNameChanged={updateInput}
                                onDescriptionChanged={setDescription}
                                client={client}
                                inputValid={validClient}
                            />
                            <div className={styles.locationSelector}>
                                <LocationsSelector
                                    setSelectedLocations={setSelectedLocations}
                                    selectedLocations={selectedLocations}
                                />
                            </div>
                            {!validAmountOfLocations && (
                                <div className={styles.responseBox}>
                                    <ResponseBox
                                        text={txt('Mqtt.MaxSelectableLimit', { limit: maxSelectableLocations })}
                                    />
                                </div>
                            )}
                            <div className="inline-header-lined inline-header-lined--extra-margin" />
                            <div className="form__row form__row--centered-padded">
                                <div>
                                    <InputLabel htmlFor="enable" label="Webhooks.Enable" />
                                    <FlipButton
                                        id="enable"
                                        onClick={updateActive}
                                        leftSelected={active}
                                        leftText="On"
                                        rightText="Off"
                                    />
                                </div>
                            </div>
                            <div className="form__row form__button-container">
                                <div className="form__attr--element centered">
                                    <PrimaryButton
                                        onClick={onDeleteClick}
                                        type="button"
                                        title="Delete"
                                        color="alert"
                                        loading={deleteLoading}
                                        testAttr="delete-mqtt-client"
                                    />
                                </div>
                                <div className="form__attr--element centered">
                                    <PrimaryButton
                                        onClick={onSaveClick}
                                        type="button"
                                        title="Save"
                                        color="primary"
                                        loading={saveLoading}
                                        testAttr="save-mqtt-client"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        mqtt: { clients, certificate },
        locations: { locations },
        userSettings: { dateFormat },
        requests: {
            [BusinessRequestType.DeleteMqttClient]: { loading: deleteLoading },
            [BusinessRequestType.FetchMqttClientCertificate]: { loading: fetchLoading },
            [BusinessRequestType.UploadMqttClientCertificate]: { loading: uploadLoading },
            [BusinessRequestType.UpdateMqttClient]: { loading: saveLoading },
        },
    } = state;
    return {
        clients,
        locations,
        certificate,
        dateFormat,
        deleteLoading,
        saveLoading,
        fetchLoading,
        uploadLoading,
    };
};
export default connect(mapStateToProps)(MqttEditFormComponent);
