import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { VirtualDeviceType } from 'commons/src/models/commonEnums';
import {
    BuildingType,
    DeviceMetaDataProperties,
    DeviceType,
    DeviceWithKeyInfo,
    InletAirMetaDataProperties,
} from 'commons/src/models/commonTypeScript';
import { fetchBuilding } from '../../../actions/locationActions';
import { AddZoneGroupPayload } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import GroupBuildingInfo from './GroupBuildingInfo';
import GroupDeviceInfo from './GroupDeviceInfo';
import GroupInfo from './GroupInfo';
import { defaultInletAirConfig, inletAirValidation } from './InletAirConfiguration';

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    building: BuildingType;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loadingBuilding: boolean;
};

export type ParentProps = {
    locationId: string;
    selectedDevice?: DeviceType;
    triggerSubmit: boolean;
    setTriggerSubmit: (triggerSubmit: boolean) => void;
    setPayload: (payload: AddZoneGroupPayload) => void;
    slimView?: boolean;
};

export type Props = StateProps & ParentProps;

export const validatePayload = (
    groupProps: {
        groupName: string;
        groupType?: { id: string; inputValue: string };
    } & DeviceMetaDataProperties,
    txt: (textKey: string, stringAttrs?: { [textKey: string]: string }) => string,
    selectedDevices: DeviceWithKeyInfo[],
    setInvalidElements: (elements: string[]) => void,
    setDisplayValidation: (displayValidation: boolean) => void
): AddZoneGroupPayload | undefined => {
    const missingElements: string[] = [];
    const validName = groupProps.groupName.length > 1;
    const groupSelected = !!groupProps.groupType;
    const devicesSelected = selectedDevices.length > 0;
    if (!validName) {
        missingElements.push(txt('Name'));
    }
    if (!groupSelected) {
        missingElements.push(txt('ZoneGrouping.GroupType'));
    }

    if (!devicesSelected) {
        missingElements.push(txt('Device'));
    }

    if (groupProps.groupType && groupProps.groupType.id === VirtualDeviceType.inletAirControl) {
        inletAirValidation(groupProps).map(k => missingElements.push(txt(k)));
    }

    setInvalidElements(missingElements);
    setDisplayValidation(missingElements.length > 0);

    if (missingElements.length === 0) {
        const { groupType, groupName: segmentName, ...deviceMetaData } = groupProps;
        const members = selectedDevices.map(device => device.serialNumber);
        return {
            type: groupType && (groupType.id as VirtualDeviceType),
            members,
            segmentName,
            deviceMetaData: (Object.keys(deviceMetaData).length && deviceMetaData) || undefined,
        };
    }
    return undefined;
};

export const AddGroupFormComponent = ({
    dateFormat,
    selectedDevice,
    triggerSubmit,
    setTriggerSubmit,
    setPayload,
    slimView,
    building,
    devicesWithKeyInfo,
    locationId,
    loadingBuilding,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [groupType, setGroupType] = useState<{ id: string; inputValue: string } | undefined>(
        selectedDevice && selectedDevice.type ? { id: selectedDevice.type, inputValue: selectedDevice.type } : undefined
    );

    const [selectedDevices, setSelectedDevices] = useState<DeviceWithKeyInfo[]>([]);
    const [groupName, setGroupName] = useState((selectedDevice && selectedDevice.segmentName) || '');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [invalidElements, setInvalidElements] = useState<string[]>([]);

    const [inletAirConfig, setInletAirConfig] = useState<InletAirMetaDataProperties>({
        ...defaultInletAirConfig,
        ...((selectedDevice && selectedDevice.deviceMetaData) || {}),
    });

    useEffect(() => {
        if (!building && !loadingBuilding) {
            dispatch(fetchBuilding(locationId));
        }
    }, []);

    useEffect(() => {
        const deviceToEditDevices =
            selectedDevice &&
            selectedDevice.deviceMetaData &&
            selectedDevice.deviceMetaData.members &&
            selectedDevice.deviceMetaData.members
                .map(resourceId => devicesWithKeyInfo[resourceId])
                .filter(device => device !== undefined);
        setSelectedDevices(deviceToEditDevices || []);
    }, [selectedDevice]);

    const saveGroupType = (): void => {
        const validPayload = validatePayload(
            {
                groupName,
                groupType,
                ...inletAirConfig,
            },
            txt,
            selectedDevices,
            setInvalidElements,
            setDisplayValidation
        );
        if (validPayload) {
            setPayload(validPayload);
        } else {
            setTriggerSubmit(false);
        }
    };

    useEffect((): void => {
        if (triggerSubmit) {
            saveGroupType();
        }
    }, [triggerSubmit]);

    useEffect((): void => {
        if (displayValidation) {
            validatePayload(
                {
                    groupName,
                    groupType,
                    ...inletAirConfig,
                },
                txt,
                selectedDevices,
                setInvalidElements,
                setDisplayValidation
            );
        }
    }, [groupType, selectedDevices, groupName, displayValidation, inletAirConfig]);

    let errorText;
    if (invalidElements.length > 0) {
        errorText = `${txt('ZoneGrouping.FillOutMissingInfo', {
            invalidElements: invalidElements.join(', '),
        })}`;
    }

    return (
        <>
            <GroupInfo
                displayValidation={displayValidation}
                setGroupName={setGroupName}
                setGroupType={setGroupType}
                groupName={groupName}
                groupType={groupType}
                deviceType={selectedDevice && (selectedDevice.type as VirtualDeviceType)}
                building={building}
                inletAirConfig={inletAirConfig}
                setInletAirConfig={setInletAirConfig}
            />
            {building && !selectedDevice && (
                <GroupBuildingInfo building={building} validate={displayValidation} dateFormat={dateFormat} />
            )}
            <GroupDeviceInfo
                building={building}
                setSelectedDevices={setSelectedDevices}
                selectedDevices={selectedDevices}
                editingDevice={!!(selectedDevice && selectedDevice.type)}
                groupType={groupType && (groupType.id as VirtualDeviceType)}
                slimView={slimView}
            />
            {errorText && <ResponseBox text="RequiredDataMissing" subtext={errorText} />}
        </>
    );
};

const mapStateToProps = (state: Store, props: ParentProps): StateProps => {
    const {
        userSettings: { dateFormat },
        buildings: { buildings },
        devices: { devicesWithKeyInfo, loading },
        requests: {
            [BusinessRequestType.FetchBuilding]: { loading: loadingBuilding },
        },
    } = state;

    const building = buildings[props.locationId];
    return {
        dateFormat,
        building,
        loadingBuilding: loadingBuilding || loading,
        devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(AddGroupFormComponent);
