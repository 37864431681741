import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { renameSegment as renameSegmentAction } from 'commons/src/actions/DeviceActions';
import { deleteHub } from 'commons/src/actions/HubActions';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { IntercomAPI } from 'commons/src/components/Intercom';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { headerPlaceholder } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import DeviceEditNameHeader from 'commons/src/features/devicePage/DeviceEditNameHeader';
import { HubData } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { Store } from 'commons/src/reducers';
import { RequestState } from 'commons/src/reducers/requestReducer';

export type Props = {
    displayedHub: HubData | undefined;
    invalidHub: boolean;
    onGoBack: () => void;
    onGoBackName: string;
};

export const HubPageHeader = ({ displayedHub, invalidHub, onGoBack, onGoBackName }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [deletingHub, setDeletingHub] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { loading: deleteHubLoading, error: deleteHubError }: RequestState = useSelector(
        (state: Store) => state.commonRequests.DELETE_HUB
    );

    useEffect(() => {
        if (deletingHub && !deleteHubLoading) {
            if (!deleteHubError) {
                onGoBack();
            }
            setDeletingHub(false);
        }
    }, [deleteHubError, deleteHubLoading, deletingHub]);

    if (!deletingHub && invalidHub) {
        navigate(pageNotFoundUrl('invalidHub'));
        return <div />;
    }

    const onDeleteHub = (): void => {
        if (displayedHub) {
            IntercomAPI('trackEvent', 'delete-hub', {
                hubSerialNumber: displayedHub.serialNumber,
                hubName: displayedHub.name,
            });
            dispatch(deleteHub(displayedHub.serialNumber, displayedHub.locationId));
            setDeletingHub(true);
        }
    };

    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => setDeleteModalOpen(true),
            id: 'unpairHub',
            title: 'HubStrings.UnpairHub',
            testAttr: 'unpair-hub',
            color: 'alert',
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            requiredGroupTypes: [],
        },
    ];
    const deleteHubDescription = (
        <p className="modal__content__description">
            {txt('HubStrings.DeleteHubDescription', { name: displayedHub ? displayedHub.name : '' })}
        </p>
    );

    const renameSegmentProxy = (newName: string): void => {
        if (!displayedHub) {
            return;
        }
        dispatch(renameSegmentAction(displayedHub.serialNumber, 'latest', newName, displayedHub.locationId));
    };

    return (
        <ReactPlaceholder ready={!!displayedHub} customPlaceholder={headerPlaceholder}>
            <PageHeader
                title={(displayedHub && displayedHub.name) || ''}
                subHeader={onGoBackName}
                subHeaderClick={onGoBack}
                customHeader={
                    <DeviceEditNameHeader
                        name={(displayedHub && displayedHub.name) || ''}
                        renameSegment={renameSegmentProxy}
                    />
                }
            />
            <div className="container">
                <SubHeader actionButtons={actionButtons} />
                {deleteModalOpen && (
                    <DeleteConfirmModal
                        title="HubStrings.UnpairHub"
                        loading={deleteHubLoading}
                        description={deleteHubDescription}
                        onSubmit={onDeleteHub}
                        onCancel={(): void => setDeleteModalOpen(false)}
                        onSubmitText="Unpair"
                        onCancelText="Cancel"
                        error={!!deleteHubError}
                        errorText="SomethingWentWrong"
                    />
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default HubPageHeader;
