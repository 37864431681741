import React, { useEffect, useState, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ALERT_DELETED_ALERT } from 'commons/src/analytics/AnalyticsEvents';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import InputLabel from 'commons/src/components/input/InputLabel';
import RadioButtons from 'commons/src/components/input/Radio';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ThresholdsModal from 'commons/src/components/modals/ThresholdsModal';
import { alertInsightSensors, alertRulesSingleThresholdSensors, alertSensors } from 'commons/src/DeviceAndSensorLists';
import IconOfflineDevice from 'commons/src/img/icon-offline-device';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SensorThresholds } from 'commons/src/models/commonTypeScript';
import { deleteNotificationAlert } from '../../../actions/thirdPartyIntegrationActions';
import { alertRuleThresholdCategories, alertsTimeConditions, paths } from '../../../constants';
import { ThirdPartyIntegrationAlertRule, AlertRule, ThirdPartyIntegrationAlert } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import SensorRule from './SensorRule';
import SystemNotificationRule from './SystemNotificationRule';

type ParentProps = {
    selectedThresholdValues: ThirdPartyIntegrationAlertRule[];
    submissionLoading: boolean;
    onSubmitAlert: (
        sensorAlertRules: ThirdPartyIntegrationAlertRule[],
        notifyOfflineDevice: boolean,
        notifyLowBattery: boolean,
        timeCondition: string
    ) => void;
    alertToEdit: ThirdPartyIntegrationAlert | undefined;
    isActive: boolean;
    setActiveState: (active: boolean) => void;
    disableSubmit: boolean;
};

type StateProps = {
    loadingDelete: boolean;
    thresholds: { [sensor: string]: SensorThresholds };
};

export type Props = StateProps & ParentProps;

export const AlertRuleFormComponent = (props: Props): React.ReactElement => {
    const {
        selectedThresholdValues,
        onSubmitAlert,
        submissionLoading,
        alertToEdit,
        thresholds,
        loadingDelete,
        isActive,
        setActiveState,
        disableSubmit,
    } = props;
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [alertRules, setAlertRules] = useState<{ [sensor: string]: AlertRule }>({});
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [displayThresholdsModal, setDisplayThresholdsModal] = useState(false);
    const [notifyOfflineDevice, setNotifyOfflineDevice] = useState<boolean>(
        (!!alertToEdit && alertToEdit?.notifyOfflineDevice) || false
    );
    const [notifyLowBattery, setNotifyLowBattery] = useState(false);
    const [selectedTimeCondition, setSelectedTimeCondition] = useState<string>(
        (alertToEdit && alertToEdit?.timeCondition) || alertsTimeConditions.ALWAYS
    );

    const getThresholdByCategory = (sensor: string, thresholdCategory: string): number => {
        const thresholdValues = selectedThresholdValues
            .filter(sensorRule => sensorRule.sensorType === sensor)
            .find(thresholdValue => thresholdValue.thresholdType === thresholdCategory);
        if (thresholdValues) {
            return thresholdValues.thresholdValue;
        }
        if (thresholdCategory === alertRuleThresholdCategories.allBelow) {
            return thresholds[sensor]?.customOptions?.defaultLow || 0;
        }
        return thresholds[sensor]?.customOptions?.defaultHigh || 1;
    };

    useEffect(() => {
        selectedThresholdValues.forEach(rule => {
            if (alertRulesSingleThresholdSensors.includes(rule.sensorType as SensorTypes)) {
                setAlertRules(prevRules => ({
                    ...prevRules,
                    [rule.sensorType]: {
                        sensorType: rule.sensorType,
                        thresholdValues: [rule.thresholdValue],
                        duration: rule.duration,
                        enabled: true,
                    },
                }));
            } else {
                setAlertRules(prevRules => ({
                    ...prevRules,
                    [rule.sensorType]: {
                        sensorType: rule.sensorType,
                        thresholdValues: [
                            getThresholdByCategory(rule.sensorType, alertRuleThresholdCategories.allBelow),
                            getThresholdByCategory(rule.sensorType, alertRuleThresholdCategories.allAbove),
                        ],
                        duration: rule.duration,
                        enabled: true,
                    },
                }));
            }
        });
    }, [selectedThresholdValues]);

    const onDelete = (): void => {
        if (alertToEdit) {
            dispatch(deleteNotificationAlert(alertToEdit.integrationId, alertToEdit.id, alertToEdit.integrationType));
            analyticsLogger(ALERT_DELETED_ALERT, { pageType: PageType.Alert });
        }
    };

    const updateAlertValue = (
        sensorType: string,
        thresholdValues: number[],
        duration: number,
        enabled: boolean
    ): void => {
        setAlertRules(prevRules => ({
            ...prevRules,
            [sensorType]: {
                ...prevRules[sensorType],
                thresholdValues,
                duration,
                enabled,
            },
        }));
    };

    const anyEnabledAlert =
        Object.keys(alertRules)
            .map(sensor => alertRules[sensor].enabled)
            .includes(true) ||
        notifyLowBattery ||
        notifyOfflineDevice;

    const validAlerts = (): ThirdPartyIntegrationAlertRule[] => {
        const validRules = Object.keys(alertRules)
            .filter(sensor => alertRules[sensor].enabled)
            .map(sensor => {
                const ruleElements = {
                    duration: alertRules[sensor].duration,
                    sensorType: sensor,
                };
                if (alertRulesSingleThresholdSensors.includes(sensor as SensorTypes)) {
                    return {
                        ...ruleElements,
                        thresholdType: alertRuleThresholdCategories.allAbove,
                        thresholdValue: alertRules[sensor].thresholdValues[0],
                    };
                }
                return [
                    {
                        ...ruleElements,
                        thresholdType: alertRuleThresholdCategories.allBelow,
                        thresholdValue: alertRules[sensor].thresholdValues[0],
                    },
                    {
                        ...ruleElements,
                        thresholdType: alertRuleThresholdCategories.allAbove,
                        thresholdValue: alertRules[sensor].thresholdValues[1],
                    },
                ];
            });
        return validRules.flat();
    };

    const submitRules = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const validSensorRules = validAlerts();
        onSubmitAlert(validSensorRules, notifyOfflineDevice, notifyLowBattery, selectedTimeCondition);
    };

    const filterRulesBySensor = (sensor: string): ThirdPartyIntegrationAlertRule[] =>
        selectedThresholdValues.filter(rule => rule.sensorType === sensor);

    const renderSensorRules = (sensors: string[]): (React.ReactElement | null)[] =>
        sensors.map(sensor => {
            return (
                <SensorRule
                    sensor={sensor as SensorTypes}
                    updateAlertValue={updateAlertValue}
                    selectedThresholdValues={filterRulesBySensor(sensor)}
                    key={sensor}
                    thresholds={thresholds}
                />
            );
        });

    const toggleActive = (): void => {
        setActiveState(!isActive);
    };
    const onClickDelete = (): void => {
        setDisplayDeleteModal(!displayDeleteModal);
    };

    const toggleSystemNotificationRule = (id: string): void => {
        if (id === 'offline-device') setNotifyOfflineDevice(!notifyOfflineDevice);
        else setNotifyLowBattery(!notifyLowBattery);
    };

    const openCloseThresholdsModal = (): void => {
        setDisplayThresholdsModal(!displayThresholdsModal);
    };

    const selectTimeCondition = (e: SyntheticEvent<HTMLInputElement>): void => {
        const timeCondition = e.currentTarget.value;
        setSelectedTimeCondition(timeCondition);
    };

    const renderTimeConditionCheckbox = (): React.ReactElement => {
        const radioOptions = Object.keys(alertsTimeConditions).map(condition => ({
            value: condition,
            label: `NotificationAlerts.${condition}`,
            testId: `${condition}-time-condition`,
        }));
        return (
            <div>
                <RadioButtons
                    buttons={radioOptions}
                    selectorName="timeCondition"
                    row
                    onChange={selectTimeCondition}
                    value={selectedTimeCondition}
                    labelId="time-condition-selector"
                />
            </div>
        );
    };

    return (
        <div className="settings-details-container">
            {displayDeleteModal && (
                <DeleteConfirmModal
                    title="NotificationAlerts.DeleteAlert"
                    description={txt('NotificationAlerts.DeleteDescription')}
                    onSubmit={onDelete}
                    onCancel={onClickDelete}
                    onSubmitText="Delete"
                    onCancelText="Cancel"
                    loading={loadingDelete}
                />
            )}
            {displayThresholdsModal && <ThresholdsModal onClose={openCloseThresholdsModal} />}
            <form onSubmit={submitRules}>
                <div className="form notification-alert__form">
                    <p className="text-large text-paragraph">{txt('NotificationAlerts.SetRulesDescription')}</p>
                    <p className="text-small text-paragraph">{txt('NotificationAlerts.ViewThresholdValues')}</p>
                    <p className="form__row--padded-medium">
                        <PrimaryButton
                            color="secondary"
                            onClick={openCloseThresholdsModal}
                            type="button"
                            title="NotificationAlerts.ViewThresholds"
                            testAttr="add-alert"
                            icon={<MaterialIcon name="info" />}
                        />
                    </p>
                    <div className="notification-alert__form__section-container">{renderSensorRules(alertSensors)}</div>
                    <div>
                        <h3 className="text-paragraph">{txt('VirtualSensors')}</h3>
                        <div className="notification-alert__form__section-container">
                            {renderSensorRules(alertInsightSensors)}
                        </div>
                    </div>
                    <h3 className="text-paragraph">{txt('NotificationAlerts.SystemNotifications')}</h3>
                    <div className="form__row notification-alert__form__section-container">
                        <SystemNotificationRule
                            toggleSystemNotification={toggleSystemNotificationRule}
                            icon={<IconOfflineDevice />}
                            description={txt('NotificationAlerts.OfflineDevice')}
                            id="offline-device"
                            checked={notifyOfflineDevice}
                        />
                    </div>
                    <h3 className="text-paragraph">{txt('NotificationAlerts.Schedule')}</h3>
                    <div className="form__row form__row--padded-small">{renderTimeConditionCheckbox()}</div>
                    <div className="form__row notification-alert__info-text">
                        <MaterialIcon extraClass="notification-alert__info-text__icon" name="info" />
                        <span className="text-small text-paragraph">
                            <Trans i18nKey="NotificationAlerts.BuildingsUsageHoursDescription">
                                <Link to={`/${paths.buildings}`}>{txt('NotificationAlerts.BuildingsSmallCap')}</Link>
                            </Trans>
                        </span>
                    </div>
                    <div className="inline-header-lined inline-header-lined--extra-margin" />
                    {!!alertToEdit && (
                        <div className="form__row form__row--centered-padded">
                            <div>
                                <InputLabel htmlFor="enable" label="Webhooks.Enable" />
                                <FlipButton
                                    id="enable"
                                    onClick={toggleActive}
                                    leftSelected={isActive}
                                    leftText="On"
                                    rightText="Off"
                                />
                            </div>
                        </div>
                    )}
                    <div className="form__row form__button-container">
                        {!!alertToEdit && (
                            <div className="form__attr--element centered">
                                <PrimaryButton
                                    onClick={onClickDelete}
                                    type="button"
                                    title="Delete"
                                    color="alert"
                                    loading={loadingDelete}
                                    testAttr="delete-alert"
                                />
                            </div>
                        )}
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                id="submit"
                                type="submit"
                                color="primary"
                                loading={submissionLoading}
                                disabled={!anyEnabledAlert || disableSubmit}
                                title="Save"
                                onClick={submitRules}
                                testAttr="save-alert"
                                testId="save-alert"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
        requests: {
            [RequestType.DeleteNotificationAlert]: { loading: loadingDelete },
        },
    } = state;
    return {
        thresholds,
        loadingDelete,
    };
};

export default connect(mapStateToProps)(AlertRuleFormComponent);
