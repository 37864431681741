import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { updateDeviceConfig } from 'commons/src/actions/DeviceActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import RadioButtons from 'commons/src/components/input/Radio';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import usbcCable from 'commons/src/img/usbcCable.png';
import { ConsumerBatteryModes } from 'commons/src/models/commonEnums';
import { DeviceConfig, ErrorType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';

type StateProps = {
    deviceConfigs: {
        [serialNumber: string]: DeviceConfig;
    };
    loading: boolean;
    error?: ErrorType;
};
export type ParentProps = {
    serialNumber: string;
    segmentId?: string;
    onClose: () => void;
};

export type Props = StateProps & ParentProps;

export const BatterySettingsComponent = ({
    serialNumber,
    deviceConfigs,
    segmentId,
    loading,
    error,
    onClose,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const deviceConfig = deviceConfigs[serialNumber];
    const [batteryMode, setBatteryMode] = useState(deviceConfig ? deviceConfig.batteryMode : undefined);

    const changeSelection = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setBatteryMode(value as ConsumerBatteryModes);
    };

    const onSubmit = (): void => {
        if (deviceConfig && deviceConfig.batteryMode !== batteryMode) {
            dispatch(updateDeviceConfig({ batteryMode }, serialNumber, segmentId || 'latest'));
        }
    };

    const batteryOptions = Object.keys(ConsumerBatteryModes).map(mode => ({
        value: mode,
        label: (
            <div className="input__radio-selector__icon">
                <div className="device-svg-icons device-svg-icons--circled device-svg-icons--margin-right">
                    <MaterialIcon
                        name={mode === ConsumerBatteryModes.improvedBatteryLife ? 'battery_full' : 'data_usage'}
                    />
                </div>
                <div>
                    <div className="text-medium text-bold">{txt(`DeviceSettingsConsumer.${mode}`)}</div>
                    <div className="text-small">{txt(`DeviceSettingsConsumer.${mode}Description`)}</div>
                </div>
            </div>
        ),
        labelIsElement: true,
        customStyle: 'input__radio-selector__radio',
    }));

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <RadioButtons
                row={false}
                buttons={batteryOptions}
                onChange={changeSelection}
                labelId="batterySelector"
                selectorName="batteryMode"
                value={batteryMode}
            />
            <div className="flex flex--align-center">
                <img src={usbcCable} alt={txt('DeviceSettings.USBCImage')} className="change-location__charger-image" />
                <div className="text-small">{txt('DeviceSettings.USBPoweredDescription')}</div>
            </div>
            {error && <ResponseBox text={`ErrorCodes.${error}`} />}
            <div className="change-location__form__buttons">
                <PrimaryButton
                    color="secondary"
                    id="cancel"
                    title="Cancel"
                    loading={false}
                    disabled={loading}
                    onClick={onClose}
                />
                <PrimaryButton id="submit" title="SaveChanges" loading={loading} color="primary" onClick={onSubmit} />
            </div>
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        deviceConfig: { deviceConfigs },
        commonRequests: {
            [CommonRequestType.UpdateDeviceConfig]: { loading, error },
        },
    } = store;
    return { deviceConfigs, loading, error };
};

export default connect(mapStateToProps)(BatterySettingsComponent);
