import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_CONFIGURED_GLOW_RING } from 'commons/src/analytics/AnalyticsEvents';
import GlowRingButton from 'commons/src/components/buttons/GlowRingButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import RadioButtons from 'commons/src/components/input/Radio';
import RadioSelector from 'commons/src/components/input/RadioSelector';
import { IntercomAPI } from 'commons/src/components/Intercom';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { LedBehavioursWavePlus, LedBrightness } from 'commons/src/models/commonEnums';
import {
    BuildingConfiguration,
    ErrorType,
    LocationDevicesConfigurationRequest,
} from 'commons/src/models/commonTypeScript';
import { updateLocationConfig } from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import styles from './ConfigureGlowRing.module.scss';
import SensorAlertConfig from './SensorAlertConfig';

type ParentProps = {
    locationId: string;
};

type StateProps = {
    configurations: { [locationId: string]: BuildingConfiguration };
    loading: boolean;
    error?: ErrorType;
};

export type Props = StateProps & ParentProps;

export const ConfigureGlowRingComponent = ({
    locationId,
    configurations,
    loading,
    error,
}: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [validateSelectors, setValidateSelectors] = useState(false);
    const buildingConfig = configurations[locationId];

    const [thresholdValues, setThresholdValues] = useState(buildingConfig?.configuration?.thresholds);
    const [ledBehaviour, setLedBehaviour] = useState<LedBrightness | string>(
        buildingConfig?.configuration?.ledBehaviour || LedBehavioursWavePlus.connectedStatus
    );
    const [alarmFrequency, setAlarmFrequency] = useState(buildingConfig?.configuration?.staleAir?.alertPeriod || 60);
    const [snoozeTime, setSnoozeTime] = useState(buildingConfig?.configuration?.staleAir?.snoozePeriod || 1800);

    const updateThresholdValues = (value: number, sensor: string, color: string): void => {
        const updatedThresholds = { ...thresholdValues, [sensor]: { ...thresholdValues[sensor], [color]: value } };
        setThresholdValues(updatedThresholds);
    };

    const setRingBehaviour = (e: SyntheticEvent<HTMLInputElement>): void => {
        const selectionType = e.currentTarget.value;
        setLedBehaviour(selectionType);
    };

    const updateAlarm = (id: string, value: string): void => {
        if (id === 'alarmFrequency') {
            setAlarmFrequency(parseInt(value, 10));
        } else if (id === 'snoozeTime') {
            setSnoozeTime(parseInt(value, 10));
        }
    };

    const snoozeTimeOptions = [600, 1800];
    const alarmFrequencyOptions = [30, 60];

    const onSubmit = (): void => {
        const ledBehaviourStaleAir = ledBehaviour === LedBehavioursWavePlus.alarmStaleAir;
        if (!buildingConfig || ledBehaviour !== buildingConfig.configuration.ledBehaviour) {
            IntercomAPI('trackEvent', 'building-led-behaviour', {
                ledBehaviour,
            });
        }
        const validAlarmFrequency = alarmFrequencyOptions.includes(alarmFrequency);
        const validSnoozePeriod = snoozeTimeOptions.includes(snoozeTime);
        if (ledBehaviourStaleAir && (!validAlarmFrequency || !validSnoozePeriod)) {
            setValidateSelectors(true);
        } else {
            const devicesConfig: LocationDevicesConfigurationRequest = {
                ledBehaviour: ledBehaviour as LedBehavioursWavePlus,
                ledBrightness: buildingConfig && buildingConfig.configuration.ledBrightness,
                thresholds: ledBehaviourStaleAir ? thresholdValues : undefined,
                staleAir: ledBehaviourStaleAir ? { alertPeriod: alarmFrequency, snoozePeriod: snoozeTime } : undefined,
            };
            dispatch(
                updateLocationConfig(
                    {
                        configuration: devicesConfig,
                        resetEnabled: buildingConfig && buildingConfig.resetEnabled,
                    },
                    locationId
                )
            );

            analyticsLogger(BUILDING_CONFIGURED_GLOW_RING, {
                yellowLimit: devicesConfig.thresholds && devicesConfig.thresholds.co2.yellow,
                redLimit: devicesConfig.thresholds && devicesConfig.thresholds.co2.red,
                frequency: devicesConfig.staleAir && devicesConfig.staleAir.alertPeriod,
                snooze: devicesConfig.staleAir && devicesConfig.staleAir.snoozePeriod,
            });
        }
    };

    const glowRingOptions = [
        {
            customStyle: 'modal__content__radio building-config__radio',
            value: LedBehavioursWavePlus.connectedStatus,
            label: <div className={styles.glowRingLabel}>{txt('LedBehaviour.ConnectionStatus')}</div>,
            buttonChildren: (
                <div>
                    <GlowRingButton
                        glowColor="white"
                        selected={ledBehaviour === LedBehavioursWavePlus.connectedStatus}
                        glowRingDescription="LedBehaviour.ConnectionStatusDescription"
                    />
                    {ledBehaviour === LedBehavioursWavePlus.connectedStatus && (
                        <p className="building-config__text text-medium">
                            {txt('LedBehaviour.ConnectionStatusDetails')}
                        </p>
                    )}
                </div>
            ),
            labelIsElement: true,
        },
        {
            customStyle: 'modal__content__radio building-config__radio',
            value: LedBehavioursWavePlus.alarmStaleAir,
            label: <div className={styles.glowRingLabel}>{txt('LedBehaviour.AlarmStaleAir')}</div>,
            buttonChildren: (
                <GlowRingButton
                    glowColor="red"
                    selected={ledBehaviour === LedBehavioursWavePlus.alarmStaleAir}
                    glowRingDescription="LedBehaviour.AlarmStaleAirDescription"
                />
            ),
            labelIsElement: true,
        },
    ];

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="edit-building__form">
                <div className="building-config building-config__wrapper">
                    <RadioButtons
                        row={false}
                        buttons={glowRingOptions}
                        onChange={setRingBehaviour}
                        labelId="wavePlusLeadBehaviour"
                        selectorName="wavePlusLeadBehaviour"
                        value={ledBehaviour}
                    />
                    {ledBehaviour === LedBehavioursWavePlus.alarmStaleAir &&
                        Object.keys(thresholdValues).map(sensor => (
                            <div key={`alert-config-${sensor}`}>
                                <SensorAlertConfig
                                    sensor={sensor}
                                    thresholds={thresholdValues[sensor]}
                                    ranges={buildingConfig && buildingConfig.configuration.ranges[sensor]}
                                    unit={buildingConfig && buildingConfig.configuration.units[sensor]}
                                    setThresholdValue={updateThresholdValues}
                                />
                                <RadioSelector
                                    headerKey="LedBehaviour.AlarmFrequency"
                                    icon={<MaterialIcon name="av_timer" />}
                                    options={alarmFrequencyOptions.map(time => ({
                                        displayedValue: `${time} ${txt('TimeUnit.Seconds')}`,
                                        id: time.toString(),
                                    }))}
                                    onSelect={updateAlarm}
                                    selectedId={alarmFrequency.toString()}
                                    selectionName="alarmFrequency"
                                />
                                <RadioSelector
                                    headerKey="LedBehaviour.SnoozeTime"
                                    icon={<MaterialIcon name="snooze" />}
                                    options={snoozeTimeOptions.map(time => ({
                                        displayedValue: `${time / 60} ${txt('TimeUnit.Minutes')}`,
                                        id: time.toString(),
                                    }))}
                                    onSelect={updateAlarm}
                                    selectedId={snoozeTime.toString()}
                                    selectionName="snoozeTime"
                                />
                                <p className="building-config__text text-medium">
                                    {txt('LedBehaviour.AlarmTriggerDetails')}
                                </p>
                            </div>
                        ))}
                </div>
                {error && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />}
                {ledBehaviour === LedBehavioursWavePlus.alarmStaleAir && validateSelectors && (
                    <ResponseBox text="" subtext={txt('LedBehaviour.ValidateAlertFrequencyAndSnoozeTime')} />
                )}
                <div className="form__button-container">
                    <PrimaryButton
                        type="button"
                        id="saveBuildingFeaturesButton"
                        title="Save"
                        loading={loading}
                        onClick={onSubmit}
                        color="primary"
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [RequestType.GetLocationConfiguration]: { error },
            [RequestType.UpdateLocationConfiguration]: { loading, error: configError },
        },
        buildings: { configurations },
    } = state;

    return {
        configurations,
        loading,
        error: configError || error,
    };
};

export default connect(mapStateToProps)(ConfigureGlowRingComponent);
