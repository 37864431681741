import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SearchDropdown from 'commons/src/components/dropdown/SearchDropdown';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { publicDeviceShortUrl } from 'commons/src/constants';
import DeviceIcon from 'commons/src/img/deviceIcons/icon_device';
import { PlacementType } from 'commons/src/models/commonEnums';
import { BuildingType, Group, Units } from 'commons/src/models/commonTypeScript';
import { getOrganizationLogo } from '../../../actions/organizationPropertiesActions';
import { setDevicePubliclyAvailable } from '../../../actions/segmentPropertiesActions';
import { IndoorSpace, SpaceDevice } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import PrintAllQrLabels, { QrLabelProps } from '../../device/dropdownOptions/PDF/PrintAllQrLabels';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import LocationList from './LocationList';

type StateProps = {
    logoImage?: string;
    selectedGroup?: Group;
    language: string;
    units: Units;
};
type ParentProps = {
    building: BuildingType;
};
type Props = StateProps & ParentProps;

export const PublicDeviceOverviewComponent = ({
    building,
    language,
    units,
    logoImage,
    selectedGroup,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, building.id));

    const devices: { [serialNumber: string]: SpaceDevice } = (
        spaces.filter(space => space.placement === PlacementType.ROOM) as IndoorSpace[]
    )
        .flatMap(it => it.devices)
        .reduce((acc, device) => {
            acc[device.serialNumber] = device;
            return acc;
        }, {} as { [serialNumber: string]: SpaceDevice });

    const [checkedDevices, setCheckedDevices] = useState<string[]>([]);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);

    const setPublicDevice = (publiclyAvailable: boolean, serialNumber: string, hasPublicUrl: boolean): void => {
        dispatch(setDevicePubliclyAvailable(publiclyAvailable, serialNumber, hasPublicUrl));
    };

    const updateCheckedDevices = (serialNumbers: string[]): void => {
        setCheckedDevices(serialNumbers);
    };

    useEffect((): void => {
        if (!logoImage) {
            dispatch(getOrganizationLogo());
        }
    }, []);

    const makePublicDevice = ({ id }: { name: string; id: string }): void => {
        const hasPublicUrl = !!(devices[id] && devices[id].publicUrlPath);
        setPublicDevice(true, id, hasPublicUrl);
    };

    const removePublicDevices = (): void => {
        checkedDevices.map(serialNumber => setPublicDevice(false, serialNumber, true));
        setCheckedDevices([]);
    };

    const pdfProps = (): QrLabelProps[] => {
        const qrProps = {
            locationName: building.name,
            devices: checkedDevices.map(serialNumber => {
                const publicUrl = devices[serialNumber].publicUrlPath;
                const shortUrl = publicUrl && publicDeviceShortUrl.replace('{{publicUrlPath}}', publicUrl);
                const qrElement = document.getElementById(`qrCode${serialNumber}`) as HTMLCanvasElement;
                const qrImage = qrElement.toDataURL('img/png');
                return {
                    serialNumber,
                    organizationName: (selectedGroup && selectedGroup.organizationName) || '',
                    qrImage: qrImage || '',
                    logoImage,
                    url: shortUrl || '',
                    deviceName: devices[serialNumber].segmentName,
                };
            }),
        };
        return [qrProps];
    };

    const numberOfSelectedDevices = checkedDevices.length;
    const printQrCodes = (): void => setDownloadModalOpen(true);

    const nonPublicDevices = Object.values(devices)
        .filter(device => !device.publiclyAvailable)
        .map(device => ({ name: device.segmentName, id: device.serialNumber }));
    return (
        <div className="settings-details-container__content">
            {downloadModalOpen && (
                <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                    <PrintAllQrLabels qrLabels={pdfProps()} />
                </ReactPdfDownloadModal>
            )}
            <p className="text-bold text-large">{txt('PublicDevices.MakeDevicePublic')}</p>
            <div className="form__row form__row--padded-medium">
                <SearchDropdown listOfElements={nonPublicDevices} onSelect={makePublicDevice} icon={<DeviceIcon />} />
            </div>
            <LocationList
                devicesInBuilding={devices}
                updateCheckedDevices={updateCheckedDevices}
                checkedDevices={checkedDevices}
                language={language}
                units={units}
                setPublicDevice={setPublicDevice}
            />
            <div className="form__row form__button-container form__button-container--margin-bottom">
                <PrimaryButton
                    color="secondary"
                    type="button"
                    title={txt('PublicDevices.RemoveSelection', {
                        numberOfDevices: numberOfSelectedDevices.toString(),
                    })}
                    translate={false}
                    onClick={removePublicDevices}
                    loading={false}
                    disabled={numberOfSelectedDevices === 0}
                    testAttr="remove-selection"
                    testId="remove-selection"
                />
                <PrimaryButton
                    type="button"
                    title={txt('PublicDevices.PrintSelection', {
                        numberOfDevices: numberOfSelectedDevices.toString(),
                    })}
                    translate={false}
                    onClick={printQrCodes}
                    loading={false}
                    color="primary"
                    disabled={numberOfSelectedDevices === 0}
                    testAttr="print-public-qr-codes"
                />
            </div>
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        organizationProperties: { logoImage },
        userSettings: { selectedGroup, language, units },
    } = store;

    return {
        logoImage,
        selectedGroup,
        language,
        units,
    };
};

export default connect(mapStateToProps)(PublicDeviceOverviewComponent);
