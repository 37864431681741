import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import BatteryComponent from 'commons/src/components/sensors/BatteryComponent';
import RssiComponent from 'commons/src/components/sensors/RssiComponent';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import SpaceSensorValue from 'commons/src/components/sensors/SpaceSensorValue';
import SmartLinkIcon from 'commons/src/img/icon-smartlink';
import { EntityType, DeviceTypeNames } from 'commons/src/models/commonEnums';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { HubsAndDevices } from '../../../models/spaceModels';
import styles from './HubsAndDevicesDetails.module.scss';

export const DeviceDetails = ({ item }: { item: HubsAndDevices & { entityType: EntityType.DEVICE } }): ReactElement => {
    return (
        <>
            <div className={styles.deviceInfo}>
                <div className={styles.serialNumber}>{item.serialNumber}</div>
                <div className={styles.metaData}>
                    {item.rssi !== undefined && <RssiComponent rssi={item.rssi} healthStatus={item.healthStatus} />}
                    {item.batteryPercentage !== undefined && (
                        <BatteryComponent
                            batteryPercentage={item.batteryPercentage}
                            healthStatus={item.healthStatus}
                            noBatteryText
                        />
                    )}
                </div>
            </div>
            <div className={styles.sensors}>
                {item?.currentSensorValues?.slice(0, 5)?.map(sensor => (
                    <div className={styles.sensor} key={sensor.type}>
                        <div className={styles.sensorIcon}>
                            <SensorIcon sensor={sensor.type} />
                        </div>
                        <SpaceSensorValue
                            value={sensor.value}
                            unit={sensor.unit}
                            type={sensor.type}
                            thresholds={sensor.thresholds}
                            showSensorType={false}
                            healthStatus={item.healthStatus}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export const HubDetails = ({ item }: { item: HubsAndDevices & { entityType: EntityType.HUB } }): ReactElement => {
    const { t: txt } = useTranslation();
    const hubDevices = item.metaData?.devices || {};
    const numberOfConnectedDevices = Object.keys(hubDevices).length;
    return (
        <>
            <div className={styles.deviceInfo}>
                <div className={styles.hub}>
                    <SmartLinkIcon />
                    <div className={styles.deviceTitle}>{txt('Hub')}</div>
                </div>
                <div className={styles.serialNumber}>({item.serialNumber})</div>
            </div>
            {item.healthStatus !== DeviceHealthStatus.offline && item.healthStatus !== DeviceHealthStatus.notSynced && (
                <div className={styles.deviceInfo}>
                    <div className={classNames(styles.deviceTitle, styles.hub)}>
                        <div>{numberOfConnectedDevices}</div>
                        <div className={styles.toSpaceDevice}>{`${txt('HubStrings.ConnectedDevices')}`}</div>
                    </div>
                </div>
            )}
            <div className={styles.deviceInfo}>
                <div className={styles.hub}>
                    {item.healthStatus === DeviceHealthStatus.online ? (
                        <>
                            <MaterialIcon name="cloud" />
                            <div className={styles.deviceTitle}>{txt('Online')}</div>
                        </>
                    ) : (
                        <DeviceStatusPill status={item.healthStatus} deviceType={DeviceTypeNames.hub} />
                    )}
                </div>
            </div>
        </>
    );
};
