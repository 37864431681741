import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel, { ButtonGroupProps } from 'react-multi-carousel';
import Accordion from 'commons/src/accordion/Accordion';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import MultipleAttrDropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { sensorTypesWithCustomThresholds } from 'commons/src/DeviceAndSensorLists';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import InsightInfoButtons from '../../../device/InsightInfoButtons';
import DigestsComponent from '../eventAggregation/Digests';
import SpaceGraphActions from './GraphActions';
import { chartOnMouseOver } from './spaceGraphConfig';
import styles from './SpaceGraphSection.module.scss';
import SpaceMainGraph from './SpaceMainGraph';
import SpaceSparkLine from './SpaceSparkLine';
import 'react-multi-carousel/lib/styles.css';
import SpaceThresholdsComponent from './SpaceThresholdsComponent';
import { useSpaceGraphSection } from './useSpaceGraphSection';

type Props = {
    spaceId: string;
    locationId: string;
    fetchDataForPeriod: (period: SelectedPeriod) => void;
    fetchDigestForPeriod: (period: SelectedPeriod) => void;
};

const CarouselButtons = ({ next, previous, carouselState }: ButtonGroupProps): ReactElement => {
    const currentSlide = carouselState?.currentSlide || 0;
    const totalItems = carouselState?.totalItems || 0;
    const slidesToShow = carouselState?.slidesToShow || 1;
    const clickPrev = (): void => {
        const isNotOnFirstSlide = currentSlide !== 0;
        if (previous && isNotOnFirstSlide) previous();
    };

    const isOnLastSlide = currentSlide + slidesToShow === totalItems;
    const clickNext = (): void => {
        if (next && !isOnLastSlide) next();
    };

    return (
        <div className={styles.buttons}>
            <CircleButton
                onClick={clickPrev}
                size="small"
                color="secondary"
                iconName="keyboard_arrow_left"
                disabled={currentSlide === 0}
            />
            <CircleButton
                onClick={clickNext}
                size="small"
                color="secondary"
                iconName="keyboard_arrow_right"
                disabled={isOnLastSlide}
            />
        </div>
    );
};

const SpaceGraphSection = ({ spaceId, locationId, fetchDataForPeriod, fetchDigestForPeriod }: Props): ReactElement => {
    const {
        selectedPeriod,
        setSelectedPeriod,
        selectedSensor,
        updateSelectedSensor,
        compareSensor,
        onSelectCompareSensor,
        digests,
        enableEventAggregationToggleOn,
        isFocusView,
        onChangeView,
        sortedSensors,
        sparkLineSensorData,
        responsive,
        graphStartAndEnd,
        sensorCompareOptions,
        handleStatusPillClick,
        thresholds,
        showCustomThresholdsToggleOn,
    } = useSpaceGraphSection({ spaceId });
    const { t: txt } = useTranslation();

    return (
        <>
            <SpaceGraphActions
                spaceId={spaceId}
                locationId={locationId}
                selectedPeriod={selectedPeriod}
                fetchDataForPeriod={fetchDataForPeriod}
                fetchDigestForPeriod={fetchDigestForPeriod}
                setSelectedPeriod={setSelectedPeriod}
                isFocusView={isFocusView}
                onChangeView={onChangeView}
            />
            {isFocusView ? (
                <div className={styles.wrapper} data-space-graph>
                    {enableEventAggregationToggleOn && (
                        <div>
                            <div className={styles.topBar}>
                                <DigestsComponent
                                    spaceId={spaceId}
                                    digests={digests}
                                    selectedPeriod={selectedPeriod}
                                    onStatusPillClick={handleStatusPillClick}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.carouselWrapper}>
                        <Carousel
                            responsive={responsive}
                            ssr={false} // means to render carousel on server-side.
                            renderButtonGroupOutside
                            arrows={false}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            customButtonGroup={<CarouselButtons />}
                            containerClass={styles.carouselContainer}
                            removeArrowOnDeviceType={['tablet', 'mobile']}
                        >
                            {sparkLineSensorData.length > 0 ? (
                                sparkLineSensorData?.map(sensor => (
                                    <SpaceSparkLine
                                        key={sensor.sensor}
                                        data={sensor.sensorData}
                                        sensor={sensor.sensor}
                                        selected={selectedSensor}
                                        onSelect={updateSelectedSensor}
                                        graphStartAndEnd={graphStartAndEnd}
                                        spaceId={spaceId}
                                        locationId={locationId}
                                    />
                                ))
                            ) : (
                                <div className={styles.sparkLinePlaceholder} />
                            )}
                        </Carousel>
                    </div>
                    <div className={styles.advancedFeatures}>
                        {showCustomThresholdsToggleOn &&
                            sensorTypesWithCustomThresholds.includes(selectedSensor) &&
                            thresholds[selectedSensor] && (
                                <SpaceThresholdsComponent
                                    sensorType={selectedSensor}
                                    thresholds={thresholds[selectedSensor]}
                                    locationId={locationId}
                                    spaceId={spaceId}
                                    selectedPeriod={selectedPeriod}
                                />
                            )}
                        <div className={styles.compare}>
                            <MultipleAttrDropdown
                                title=""
                                defaultOption="Compare"
                                options={sensorCompareOptions}
                                value={compareSensor ? txt(compareSensor) : undefined}
                                onSelect={({ id }): void => onSelectCompareSensor(id as SensorTypes)}
                                testAttr="compare-sensor-dropdown"
                                testId="compare-sensor-dropdown"
                                id="compareSensorDropdown"
                                optionsAlreadySorted
                                slim
                                noBottomMargin
                            />
                        </div>
                    </div>
                    <SpaceMainGraph
                        sensor={selectedSensor}
                        thresholds={thresholds}
                        spaceId={spaceId}
                        selectedInterval={selectedPeriod}
                        locationId={locationId}
                        graphStartAndEnd={graphStartAndEnd}
                        compareSensor={compareSensor}
                        isFocusView={isFocusView}
                    />
                </div>
            ) : (
                <div>
                    {sortedSensors?.map(sensor => (
                        <Accordion key={sensor?.type} isOpenByDefault header={txt(sensor?.type)}>
                            <div
                                onMouseMove={(e): void => chartOnMouseOver(e.nativeEvent, false)}
                                onMouseLeave={(e): void => chartOnMouseOver(e.nativeEvent, true)}
                            >
                                <SpaceMainGraph
                                    sensor={sensor.type}
                                    thresholds={thresholds}
                                    spaceId={spaceId}
                                    selectedInterval={selectedPeriod}
                                    locationId={locationId}
                                    graphStartAndEnd={graphStartAndEnd}
                                    compareSensor={compareSensor}
                                    isFocusView={isFocusView}
                                />
                            </div>
                        </Accordion>
                    ))}
                </div>
            )}
            <InsightInfoButtons sensor={selectedSensor} />
        </>
    );
};

export default SpaceGraphSection;
