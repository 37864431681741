import React, { KeyboardEvent, SyntheticEvent, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { fetchSegmentProperties } from '../../../actions/segmentPropertiesActions';
import DeviceSelectorWithDetails from '../../../components/DeviceSelectorWithDetails';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

type StateProps = {
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    loading: boolean;
};

type ParentProps = {
    device: DeviceWithKeyInfo;
    removeDeviceFromList: (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>) => void;
    slimView?: boolean;
};

type Props = StateProps & ParentProps;

export const GroupDeviceDetailsComponent = ({
    removeDeviceFromList,
    device,
    segmentProperties,
    loading,
    slimView,
}: Props): React.ReactElement => {
    const deviceProps = segmentProperties[device.serialNumber];
    const dispatch = useDispatch();

    useEffect((): void => {
        if (!deviceProps) {
            dispatch(fetchSegmentProperties(device.serialNumber));
        }
    }, []);

    const segmentPropsForDevice = segmentProperties[device.serialNumber];

    return (
        <DeviceSelectorWithDetails
            device={device}
            segmentProperties={segmentPropsForDevice}
            removeDeviceFromList={removeDeviceFromList}
            loading={!segmentPropsForDevice && loading}
            slimView={slimView}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        segmentPropertiesStore: { segmentProperties },
        requests: {
            [BusinessRequestType.FetchSegmentProperties]: { loading },
        },
        devices: { loading: deviceLoading },
    } = state;
    return {
        segmentProperties,
        loading: loading || deviceLoading,
    };
};

export default connect(mapStateToProps)(GroupDeviceDetailsComponent);
