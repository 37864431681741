import React from 'react';
import ReactPDF, { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { colors } from '../../constants';
import Co2PngWhite from '../../img/sensorIcons/sensorPng/sensor-co2-white.png';
import Co2Png from '../../img/sensorIcons/sensorPng/sensor-co2.png';
import HumidityPngWhite from '../../img/sensorIcons/sensorPng/sensor-humidity-white.png';
import HumidityPng from '../../img/sensorIcons/sensorPng/sensor-humidity.png';
import LightPngWhite from '../../img/sensorIcons/sensorPng/sensor-light-white.png';
import LightPng from '../../img/sensorIcons/sensorPng/sensor-light.png';
import MoldPng from '../../img/sensorIcons/sensorPng/sensor-mold.png';
import NoisePngWhite from '../../img/sensorIcons/sensorPng/sensor-noise-white.png';
import NoisePng from '../../img/sensorIcons/sensorPng/sensor-noise.png';
import OccupancyPng from '../../img/sensorIcons/sensorPng/sensor-occupancy.png';
import PMPngWhite from '../../img/sensorIcons/sensorPng/sensor-pm-white.png';
import PMPng from '../../img/sensorIcons/sensorPng/sensor-pm.png';
import RadonPngWhite from '../../img/sensorIcons/sensorPng/sensor-radon-white.png';
import RadonPng from '../../img/sensorIcons/sensorPng/sensor-radon.png';
import TempPngWhite from '../../img/sensorIcons/sensorPng/sensor-temp-white.png';
import TempPng from '../../img/sensorIcons/sensorPng/sensor-temp.png';
import VentilationPng from '../../img/sensorIcons/sensorPng/sensor-ventilation.png';
import VirusRiskPng from '../../img/sensorIcons/sensorPng/sensor-virus.png';
import VocPngWhite from '../../img/sensorIcons/sensorPng/sensor-voc-white.png';
import VocPng from '../../img/sensorIcons/sensorPng/sensor-voc.png';

Font.registerHyphenationCallback(word => [word]);

export const coloredCircleStyles = StyleSheet.create({
    red: { borderColor: colors.redPersian },
    green: { borderColor: colors.greenBiosphere },
    yellow: { borderColor: colors.yellowSunlight },
    grey: { borderColor: colors.greyMist },
});

export const colorStyles = StyleSheet.create({
    red: { backgroundColor: colors.redPersian },
    green: { backgroundColor: colors.greenBiosphere },
    yellow: { backgroundColor: colors.yellowSunlight },
    smallText: {
        fontSize: 10,
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        maxWidth: '80%',
        textAlign: 'left',
    },
});

export const componentStyling = StyleSheet.create({
    page: {
        paddingTop: '6vw',
        paddingRight: '5vw',
        paddingBottom: '75',
        paddingLeft: '5vw',
        fontFamily: 'Regular',
    },
    paragraph: {
        fontSize: '10',
        paddingTop: '2vw',
        paddingBottom: '2vw',
    },
});

export const Body = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Page size="A4" style={componentStyling.page}>
        {children}
    </Page>
);

export const BoldText = ({
    children,
    style,
}: {
    children: string | undefined;
    style?: ReactPDF.Style;
}): React.ReactElement => {
    const componentStyle = { fontFamily: 'DemiBold' };
    return <Text style={style ? [style, componentStyle] : componentStyle}>{children}</Text>;
};

export const Paragraph = ({
    children,
    style,
}: {
    children: string | undefined;
    style?: ReactPDF.Style;
}): React.ReactElement => {
    const componentStyle = style ? [componentStyling.paragraph, style] : componentStyling.paragraph;
    return <Text style={componentStyle}>{children}</Text>;
};

export const ParagraphSmall = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '8px', padding: '2vw 0' }}>{children}</Text>
);

export const ParagraphNoPaddingTop = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '10', padding: '0 0 2vw 0' }}>{children}</Text>
);

export const HeaderBlock = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => (
    <View
        style={{
            marginBottom: '4vw',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        {children}
    </View>
);

export const SubHeader = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '13', marginBottom: '2vw' }}>{children}</Text>
);

export const SubHeaderNoMargin = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '13' }}>{children}</Text>
);

export const LogoContainer = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => (
    <View
        style={{
            height: '12vw',
            display: 'flex',
            marginTop: '-5px',
            marginRight: '4vw',
            width: '30vw',
            justifyContent: 'flex-start', // TODO: right is not valid, is this the right replacement?
        }}
    >
        {children}
    </View>
);

export const Logo = ({ src }: { src: string }): React.ReactElement => (
    <Image style={{ marginLeft: 'auto' }} src={src} />
);

export const SmallLogo = ({ src }: { src: string }): React.ReactElement => (
    <Image style={{ width: '10vw', height: '8vw' }} src={src} />
);

export const PageLine = (): React.ReactElement => (
    <View
        style={{
            width: '90vw',
            height: '10',
            border: `0 solid ${colors.greyNepal}`,
            borderTopWidth: '1',
            textAlign: 'center',
            margin: '25 0 20 0',
        }}
    />
);

export const PageLinePaddedTop = (): React.ReactElement => (
    <View
        style={{
            width: '90vw',
            height: '10',
            border: `0 solid ${colors.greyNepal}`,
            borderTopWidth: '1',
            textAlign: 'center',
            margin: '25 0 5 0',
        }}
    />
);

export const PageLineSlim = (): React.ReactElement => (
    <View
        style={{
            width: '90vw',
            height: '10',
            border: `0 solid ${colors.greyNepal}`,
            borderTopWidth: '1',
            textAlign: 'center',
            margin: '5 0 5 0',
        }}
    />
);

export const Graph = ({ src }: { src: string }): React.ReactElement => (
    <Image style={{ width: '90vw', marginTop: '3vw', marginBottom: '3vw' }} src={src} />
);

export const FlexWrapper = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => <View style={{ display: 'flex', flexDirection: 'row' }}>{children}</View>;

export const FlexWrapperWrapped = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => (
    <View
        style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        }}
    >
        {children}
    </View>
);

export const PaddedAverage = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => <View style={{ margin: '5 0' }}>{children}</View>;

export const FlexWrapperSpaced = ({
    children,
    style,
}: {
    children: React.ReactElement | React.ReactElement[];
    style?: ReactPDF.Style;
}): React.ReactElement => {
    const componentStyle: ReactPDF.Style = {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '10',
        justifyContent: 'space-between',
    };
    return <View style={style ? [componentStyle, style] : componentStyle}>{children}</View>;
};

export const TileContainer = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => <View style={{ width: '50%' }}>{children}</View>;

export const InsightWrapper = ({
    children,
    style,
}: {
    children: React.ReactElement | (React.ReactElement | undefined)[];
    style?: ReactPDF.Style;
}): React.ReactElement => {
    const componentStyle: ReactPDF.Style = { display: 'flex', flexDirection: 'row', alignItems: 'center' };
    return <View style={style ? [componentStyle, style] : componentStyle}>{children}</View>;
};

export const ValueWrapper = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => <View style={{ padding: '0 2vw' }}>{children}</View>;

export const SensorValue = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '30' }}>{children}</Text>
);

export const SensorUnitText = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '10', paddingBottom: '3' }}>{children}</Text>
);

export const SensorValueMedium = ({ children }: { children: string | undefined }): React.ReactElement => (
    <Text style={{ fontSize: '24' }}>{children}</Text>
);

export const DeviceIcon = ({ src }: { src: string }): React.ReactElement => (
    <Image
        style={{
            width: '34',
            height: '34',
            padding: '5',
            marginTop: '3',
            marginLeft: '3',
        }}
        src={src}
    />
);

export const ColoredCircle = ({
    children,
    style,
}: {
    children?: React.ReactElement | React.ReactElement[];
    style?: ReactPDF.Style[];
}): React.ReactElement => {
    const componentStyle: ReactPDF.Style = {
        minWidth: '10',
        height: '50',
        width: '50',
        marginTop: '2',
        border: '5 solid gray',
        borderRadius: '25',
        position: 'relative',
    };
    return <View style={style ? [componentStyle, ...style] : componentStyle}>{children}</View>;
};

export const ColoredDot = ({
    children,
    style,
}: {
    children?: string | undefined;
    style?: ReactPDF.Style;
}): React.ReactElement => {
    const componentStyle: ReactPDF.Style = {
        minWidth: '10',
        height: '10',
        width: '10',
        marginTop: '2',
        marginLeft: '8',
        marginRight: '9',
        borderRadius: '9',
    };
    return <Text style={style ? [componentStyle, style] : componentStyle}>{children}</Text>;
};

export const Footer = ({
    children,
    fixed,
}: {
    children: React.ReactElement | React.ReactElement[];
    fixed?: boolean;
}): React.ReactElement => (
    <View
        fixed={fixed}
        style={{
            width: '100%',
            position: 'absolute',
            bottom: '30',
            left: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 30',
            alignItems: 'baseline',
        }}
    >
        {children}
    </View>
);

export type SensorColumns = {
    average?: number;
    highestMeasurement?: number;
    lowestMeasurement?: number;
    name: string;
    roomType: string;
    serialNumber: string;
}[];

export const sensorIconSource = (sensor: string, white?: boolean): string | null => {
    const src: { [sensor: string]: string } = {
        humidity: HumidityPng,
        voc: VocPng,
        co2: Co2Png,
        temp: TempPng,
        virusRisk: VirusRiskPng,
        warmerTemperature: TempPng,
        decreaseOccupancy: OccupancyPng,
        ventilationRate: VentilationPng,
        retainHumidity: HumidityPng,
        radonShortTermAvg: RadonPng,
        pm25: PMPng,
        noise: NoisePng,
        sla: NoisePng,
        pm1: PMPng,
        pm10: PMPng,
        light: LightPng,
        mold: MoldPng,
    };
    const whiteSensorSrc: { [sensor: string]: string } = {
        voc: VocPngWhite,
        co2: Co2PngWhite,
        humidity: HumidityPngWhite,
        temp: TempPngWhite,
        radonShortTermAvg: RadonPngWhite,
        pm25: PMPngWhite,
        pm1: PMPngWhite,
        pm10: PMPngWhite,
        noise: NoisePngWhite,
        light: LightPngWhite,
        sla: NoisePngWhite,
    };
    const whiteSensorIcon = whiteSensorSrc[sensor];
    const sensorUrl: string | undefined = white && whiteSensorIcon ? whiteSensorIcon : src[sensor];
    return sensorUrl || null;
};
