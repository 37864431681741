import React from 'react';
import classNames from 'classnames';
import { EnumStatusColors, SensorTypes } from '../../models/commonEnums';
import SensorIcon from './SensorIcon';
import styles from './SensorStatusDot.module.scss';

type Props = { color: EnumStatusColors; hideOkColorCoding?: boolean };
const SensorStatusDot = ({ color, hideOkColorCoding }: Props): React.ReactElement => {
    return (
        <div
            className={classNames(styles.dot, {
                [styles.ok]: color === EnumStatusColors.green && !hideOkColorCoding,
                [styles.alert]: color === EnumStatusColors.red,
                [styles.warning]: color === EnumStatusColors.yellow,
                [styles.disabled]: color === EnumStatusColors.grey,
            })}
        />
    );
};

export default SensorStatusDot;

export const SensorStatusCircle = ({ color }: { color: EnumStatusColors }): React.ReactElement => {
    return (
        <div
            className={classNames(styles.circle, {
                [styles.ok]: color === EnumStatusColors.green,
                [styles.alert]: color === EnumStatusColors.red,
                [styles.warning]: color === EnumStatusColors.yellow,
                [styles.disabled]: color === EnumStatusColors.grey,
            })}
        >
            <div className={styles.circleCenter} />
        </div>
    );
};

export const SensorStatusCircleWithIcon = ({
    color,
    sensor,
}: {
    color: EnumStatusColors;
    sensor: SensorTypes;
}): React.ReactElement => {
    return (
        <div
            className={classNames(styles.largeCircle, {
                [styles.ok]: color === EnumStatusColors.green,
                [styles.alert]: color === EnumStatusColors.red,
                [styles.warning]: color === EnumStatusColors.yellow,
                [styles.disabled]: color === EnumStatusColors.grey,
            })}
        >
            <div className={styles.largeCircleCenter}>
                <SensorIcon sensor={sensor} />
            </div>
        </div>
    );
};
