import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDevice, fetchDeviceConfig } from 'commons/src/actions/DeviceActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { paths } from 'commons/src/constants';
import DeleteModal from 'commons/src/features/devicePage/dropdownOptions/DeleteModal';
import { DeviceConfig, DeviceType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { CommonRequestType as RequestType } from 'commons/src/reducers/requestReducer';
import BatterySettings from './BatterySettings';
import ChangeLocation from './ChangeLocation';

export type Props = {
    loading: boolean;
    devices: { [serialNumber: string]: DeviceType };
    locationsLoading: boolean;
    demoMode: boolean;
    deviceConfigs: {
        [serialNumber: string]: DeviceConfig;
    };
};

export const DeviceSettingsComponent = ({
    loading,
    locationsLoading,
    devices,
    demoMode,
    deviceConfigs,
}: Props): React.ReactElement | null => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hasBatterySettings = deviceConfigs[serialNumber] && deviceConfigs[serialNumber].batteryMode;
    const selectedDevice = devices[serialNumber];
    const [deviceDetailsOpen, setDeviceDetailsOpen] = useState(false);
    const [batterySettingsOpen, setBatterySettingsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect((): void => {
        if (!locationsLoading) {
            dispatch(fetchDeviceConfig(serialNumber, (selectedDevice && selectedDevice.segmentId) || 'latest'));
        }
    }, [locationsLoading, serialNumber, selectedDevice]);

    useEffect(() => {
        if (!loading && !selectedDevice) {
            navigate(`/${paths.devices}`);
        }
    }, [loading, selectedDevice]);

    const onDeleteModalOpenClose = (): void => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const clickDeleteDevice = (): void => {
        if (demoMode) return;
        dispatch(deleteDevice(serialNumber, selectedDevice.locationId));
    };

    return (
        <div className="flex-column">
            <div className="page-wrapper__medium">
                <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                    {isDeleteModalOpen && (
                        <DeleteModal
                            isB2B={false}
                            isEndedSegment={false}
                            onDeleteDevice={clickDeleteDevice}
                            isModalOpen={isDeleteModalOpen}
                            onModalClose={onDeleteModalOpenClose}
                            segmentName={selectedDevice.segmentName}
                        />
                    )}
                    <div className="settings-details-container settings-details-container--center settings-details-container--margin">
                        <DeviceInfo
                            serialNumber={serialNumber}
                            deviceType={selectedDevice && selectedDevice.type}
                            segmentStart={selectedDevice && selectedDevice.segmentStart}
                            noMargin
                        />
                    </div>
                    <div className="settings-details-container settings-details-container--margin">
                        <CollapsableSectionHeader
                            openSection={setDeviceDetailsOpen}
                            headerIsOpen={deviceDetailsOpen}
                            headerText="DeviceSettings.DeviceSettings"
                            subtext="DeviceSettings.DeviceSettingsSubHeader"
                            loading={false}
                            testAttr="settings"
                        />
                        {deviceDetailsOpen && (
                            <ChangeLocation
                                goBack={(): void => setDeviceDetailsOpen(false)}
                                serialNumber={serialNumber}
                            />
                        )}
                    </div>
                    {hasBatterySettings && (
                        <div className="settings-details-container settings-details-container--margin">
                            <CollapsableSectionHeader
                                openSection={setBatterySettingsOpen}
                                headerIsOpen={batterySettingsOpen}
                                headerText="DeviceSettings.BatteryLife"
                                subtext="DeviceSettingsConsumer.BatteryLifeDescription"
                                loading={false}
                                testAttr="battery"
                            />
                            {batterySettingsOpen && (
                                <BatterySettings
                                    serialNumber={serialNumber}
                                    onClose={(): void => setBatterySettingsOpen(false)}
                                />
                            )}
                        </div>
                    )}
                    {/* {deviceIsViewPlus && (
                        <div className="settings-details-container settings-details-container--margin">
                            <CollapsableSectionHeader
                                openSection={setDisplaySettingsOpen}
                                headerIsOpen={displaySettingsOpen}
                                headerText="DeviceSettings.Display"
                                subtext={txt('DeviceSettings.DisplaySubHeader', {
                                    deviceType: txt(mapDeviceType(DeviceTypeNames.viewPlus)),
                                })}
                                loading={false}
                                testAttr="display-settings"
                                translateSubText={false}
                            />
                            {displaySettingsOpen && (
                                <ViewPlusSettings // this component is no longer in use. Make sure to delete this as well when removing this. If starting to use this again tests for this component have been commented out and needs to be updated.
                                    goBack={(): void => setDisplaySettingsOpen(false)}
                                    serialNumber={serialNumber}
                                    publicUrl={selectedDevice.publicUrlPath}
                                />
                            )}
                        </div>
                    )} */}
                    <div className="change-location__form__buttons">
                        <PrimaryButton onClick={onDeleteModalOpenClose} color="alert" title="DeleteDevice" />
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        devices: { devices },
        userSettings: { demoMode },
        deviceConfig: { deviceConfigs },
        locations: { loading: locationsLoading, locations },
        commonRequests: {
            [RequestType.FetchDeviceConfig]: { loading: configLoading },
        },
    } = store;

    return {
        devices,
        demoMode,
        deviceConfigs,
        loading: (locationsLoading && locations.length === 0) || configLoading,
        locationsLoading: locationsLoading && locations.length === 0,
    };
};

export default connect(mapStateToProps)(DeviceSettingsComponent);
