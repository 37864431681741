import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { EnumStatusColors, Rating } from '../../models/commonEnums';
import { ThresholdRange } from '../../models/commonTypeScript';
import SensorStatusDot from '../sensors/SensorStatusDot';

type Props = {
    sensorType: string;
    unit: string;
    ranges: ThresholdRange[];
};

const ThresholdLimits = ({ ranges, sensorType, unit }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sortFromHighToLow = [...ranges].sort((a, b) => (b.from || 0) - (a.from || 0));
    const sensorThresholds = (sortedRanges: ThresholdRange[]): ReactElement[] =>
        sortedRanges.map((threshold, index) => {
            const thresholdKey = `${sensorType}-threshold-${index}`;
            const color = (rating: Rating): EnumStatusColors => {
                if (rating === Rating.GOOD) {
                    return EnumStatusColors.green;
                }
                if (rating === Rating.POOR) {
                    return EnumStatusColors.red;
                }
                return EnumStatusColors.yellow;
            };
            return (
                <div key={thresholdKey} className="modal__thresholds__sensor-wrapper__limit-row">
                    <div>
                        <SensorStatusDot color={color(threshold.rating)} />
                        {threshold.from && ['≥ ', threshold.from]}
                        {threshold.from && threshold.to && [' ', txt('AndConnectionWord')]}
                        {threshold.to && [' < ', threshold.to]}
                        {unit}
                    </div>
                </div>
            );
        });
    return <div>{sensorThresholds(sortFromHighToLow)}</div>;
};

export default ThresholdLimits;
