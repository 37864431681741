import React, { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccordionButton from 'commons/src/components/buttons/AccordionButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import Input from 'commons/src/components/input/Input';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { A4BSupportMail, dateFormats, EMAIL_REGEX } from 'commons/src/constants';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchPartnerDetails } from '../../../actions/partnerActions';
import { createPartnerCustomer } from '../../../actions/partnerCustomerActions';
import { paths } from '../../../constants';
import { KeyValuePairType } from '../../../models/common';
import { NewPartnerSubAccountRequest, SubAccountDetails } from '../../../models/partner';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { removeEmptyKeyValuePair } from '../../../sagas/segmentPropertiesSaga';
import MapInput from '../../integrations/webhooks/MapInput';
import styles from './PartnerAddCustomer.module.scss';

export type Props = {
    dateFormat: keyof typeof dateFormats;
    loading: boolean;
    createCustomerLoading: boolean;
    error?: ErrorType;
    userName: string;
    customers: SubAccountDetails[];
};

export const PartnerAddCustomerComponent = ({
    dateFormat,
    loading,
    createCustomerLoading,
    error,
    userName,
    customers,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orgName, setOrgName] = useState('');
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [labels, setLabels] = useState<KeyValuePairType[]>([]);
    const [showLabels, setShowLabels] = useState(false);

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect((): void => {
        if (customers.length === 0) {
            dispatch(fetchPartnerDetails());
        }
    }, []);

    const actionButtons: ActionButton[] = [
        {
            testAttr: 'close-add-customer',
            title: 'Close',
            color: 'secondary',
            id: 'CloseAddCustomer',
            onClick: (): void => navigate({ pathname: `/${paths.partnerPage}` }),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const validInput = (): boolean => {
        const orgNameValid = orgName.trim().length > 1;
        const adminNameValid = adminName.trim().length > 1;
        const adminEmailValid = EMAIL_REGEX.test(adminEmail);

        const inputValid = orgNameValid && adminEmailValid && adminNameValid;
        setDisplayValidation(!inputValid);
        return inputValid;
    };

    const onSubmit = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        if (validInput()) {
            const customer: NewPartnerSubAccountRequest = {
                email: adminEmail,
                name: adminName,
                partnerName: userName,
            };
            dispatch(
                createPartnerCustomer({
                    ...customer,
                    organizationName: orgName,
                    labels: removeEmptyKeyValuePair(labels),
                })
            );
        }
    };

    const updateInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value, id } = e.currentTarget;
        if (id === 'orgName') setOrgName(value);
        else if (id === 'adminName') setAdminName(value);
        else if (id === 'adminEmail') setAdminEmail(value);
    };

    return (
        <div className="partner-portal">
            <PageHeader title={txt('Partner.AddCustomer')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                <div className="page-wrapper__medium page-wrapper__medium--padded page-wrapper__medium--white">
                    <form>
                        <div className="form__row">
                            <div className="form__field form__field--half">
                                <Input
                                    validate={displayValidation && orgName.trim().length < 2}
                                    type="text"
                                    id="orgName"
                                    label="OrganizationProfile.OrganizationName"
                                    currentValue={orgName}
                                    onChange={updateInput}
                                    isValid={orgName.trim().length > 1}
                                />
                            </div>
                            <div className="form__field form__field--half">
                                <LabeledText
                                    label="DateCreated"
                                    value={moment().format(dateFormats[dateFormat].calendarFormat)}
                                    id="orgDateCreated"
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__field form__field--half">
                                <Input
                                    validate={displayValidation && adminName.trim().length < 2}
                                    type="text"
                                    id="adminName"
                                    label="Partner.AdminName"
                                    currentValue={adminName}
                                    onChange={updateInput}
                                />
                            </div>
                            <div className="form__field form__field--half">
                                <Input
                                    validate={displayValidation && !EMAIL_REGEX.test(adminEmail)}
                                    type="text"
                                    id="adminEmail"
                                    label="Partner.AdminEmail"
                                    currentValue={adminEmail}
                                    infoText="Partner.InvitationEmail"
                                    onChange={updateInput}
                                    hint="EmailHint"
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className={styles.infoParagraph}>
                                <MaterialIcon name="info" />
                                <span>{txt('Partner.AddPartnerDescription')}</span>
                            </div>
                        </div>
                        {/* <div className="form__row">
                                    <div className={styles.infoParagraph}>
                                        <div className={styles.planBadge}>{txt('SubscriptionModel.Limited')}</div>
                                        <span>{txt('Partner.LimitedPlanByDefault')}</span>
                                    </div>
                                </div> */}
                        <div className={showLabels ? styles.labelsSectionOpen : styles.labelsSectionClosed}>
                            <div className="form__row">
                                <AccordionButton
                                    expanded={showLabels}
                                    onClick={(): void => setShowLabels(!showLabels)}
                                    data-testid="data-expand-add-label"
                                    id="expandAddLabel"
                                    title="CustomLabels.AddLabel"
                                />
                            </div>
                            {showLabels && (
                                <MapInput
                                    id="organization-labels-selector"
                                    updatePair={setLabels}
                                    pairs={labels}
                                    displayValidation={false}
                                    keyHeader="CustomLabels.LabelKey"
                                    keyHint="CustomLabels.LabelKeyRequiredHint"
                                    valueHeader="CustomLabels.LabelValue"
                                    valueHint=""
                                    addButtonText="CustomLabels.AddLabel"
                                    maxValueLength={255}
                                    maxKeyLength={255}
                                    maxRows={5}
                                />
                            )}
                        </div>
                        <div className="form__row form__row--centered">
                            <PrimaryButton
                                onClick={onSubmit}
                                type="submit"
                                icon={<MaterialIcon name="add" />}
                                title="Partner.AddCustomer"
                                color="primary"
                                loading={loading || createCustomerLoading}
                            />
                        </div>
                        {error && (
                            <div className={styles.errorWrapper}>
                                <ResponseBox
                                    text={`ErrorCodes.${error.error}`}
                                    subtext={
                                        <Trans i18nKey="Partner.AddCustomerPleaseContactSupport">
                                            <a href={`mailto: ${A4BSupportMail}`}>{A4BSupportMail}</a>
                                        </Trans>
                                    }
                                />
                            </div>
                        )}
                    </form>
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { dateFormat, userName, loading: userSettingsLoading },
        requests: {
            [RequestType.CreatePartnerCustomer]: { loading: createCustomerLoading, error: createCustomerError },
        },
        partner: { customers },
    } = store;

    return {
        customers: customers || [],
        dateFormat,
        loading: userSettingsLoading,
        createCustomerLoading,
        error: createCustomerError,
        userName,
    };
};

export default connect(mapStateToProps)(PartnerAddCustomerComponent);
