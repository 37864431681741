import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import {
    fetchThirdPartyIntegrationMapping,
    fetchThirdPartyIntegrations,
} from '../../../actions/thirdPartyIntegrationActions';
import { paths, thirdPartyChannels } from '../../../constants';
import { ThirdPartyAddedIntegration, ThirdPartyIntegration } from '../../../models/common';
import { Store } from '../../../reducers';
import ThirdPartyMappingForm from './ThirdPartyMappingForm';

type Props = {
    thirdPartyIntegrations: ThirdPartyIntegration[];
    loadingThirdPartyMapping: boolean;
    loadingThirdPartyIntegrations: boolean;
    loadingLocations: boolean;
};

export const EditThirdPartyMappingComponent = ({
    loadingLocations,
    loadingThirdPartyMapping,
    thirdPartyIntegrations,
    loadingThirdPartyIntegrations,
}: Props): React.ReactElement => {
    const { integrationId } = useParams<'integrationId'>();
    const navigate = useNavigate();
    const [foundIntegration, setFoundIntegration] = useState<ThirdPartyAddedIntegration | undefined>(undefined);
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (thirdPartyIntegrations.length === 0 && !loadingThirdPartyIntegrations) {
            dispatch(fetchThirdPartyIntegrations());
        }
    }, []);

    useEffect(() => {
        if (integrationId && !loadingThirdPartyIntegrations && !loadingLocations) {
            const integrationExists = thirdPartyIntegrations.find(int => int.id === integrationId);
            if (!integrationExists) navigate({ pathname: `/${paths.thirdParty}` });
            else {
                if (integrationExists.type !== thirdPartyChannels.SLACK) {
                    dispatch(fetchThirdPartyIntegrationMapping(integrationExists.id));
                }
                setFoundIntegration({
                    id: integrationExists.id,
                    type: integrationExists.type,
                    name: integrationExists.name,
                });
            }
        }
    }, [integrationId, loadingThirdPartyIntegrations, loadingLocations]);

    const close = (): void => {
        navigate({ pathname: `/${paths.thirdParty}` });
    };

    const actionButtons: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-edit-mapping',
            id: 'closeEditThirdParty',
            color: 'secondary',
            title: 'Close',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt('ThirdParty.EditIntegration')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder
                ready={!(loadingLocations || loadingThirdPartyMapping || loadingThirdPartyIntegrations)}
                customPlaceholder={mediumFormLoader}
            >
                <ThirdPartyMappingForm integration={foundIntegration} />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading: loadingThirdPartyIntegrations },
            FETCH_THIRD_PARTY_INTEGRATION_MAPPING: { loading: loadingThirdPartyMapping },
        },
        thirdPartyIntegrations: { thirdPartyIntegrations },
        locations: { loading: loadingLocations },
    } = state;

    return {
        loadingLocations,
        loadingThirdPartyMapping,
        loadingThirdPartyIntegrations,
        thirdPartyIntegrations,
    };
};

export default connect(mapStateToProps)(EditThirdPartyMappingComponent);
