import dotProp from 'dot-prop-immutable';
import {
    FetchBuildingSuccess,
    FetchVirtualDevicesSuccess,
    LocationActionType,
} from 'business-dashboard/src/actions/locationActions';
import {
    SegmentPropertiesActionType,
    SegmentPropertiesDevicesReducerActions,
} from 'business-dashboard/src/actions/segmentPropertiesActions';
import { DeviceActionType, DeviceReducerActions } from '../actions/DeviceActions';
import { HubActionType, HubReducerActions } from '../actions/HubActions';
import { LocationActions, LocationReducerActions } from '../actions/LocationActions';
import {
    DELETE_LOCATION_SUCCESS,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_ERROR,
    FETCH_LOCATIONS_SUCCESS,
} from '../actions/LocationActionTypes';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { SelectGroupActionType, SelectGroupReducerActions } from '../actions/selectGroupActions';
import { DeviceType, DeviceWithKeyInfo, EndedSegment, HubData } from '../models/commonTypeScript';

const initialState = {
    devices: {},
    devicesWithKeyInfo: {},
    hubsWithKeyInfo: {},
    endedMeasurements: [],
    loading: true,
    hubs: [],
};
export type DevicesStore = {
    devices: { [serialNumber: string]: DeviceType };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    hubs: HubData[];
    endedMeasurements: EndedSegment[];
};

const deleteHub = (serialNumber: string, hubs: HubData[]): HubData[] => {
    const indexOfHubToDelete = hubs.findIndex(hub => hub.serialNumber === serialNumber);
    if (indexOfHubToDelete > -1) {
        return dotProp.delete(hubs, indexOfHubToDelete);
    }
    return hubs;
};

const deleteEndedMeasurement = (segmentId: string, endedMeasurements: EndedSegment[]): EndedSegment[] => {
    const indexOfSegmentToDelete = endedMeasurements.findIndex(measurement => measurement.segmentId === segmentId);
    if (indexOfSegmentToDelete > -1) {
        return dotProp.delete(endedMeasurements, indexOfSegmentToDelete);
    }
    return endedMeasurements;
};

const updateDevicePublicStatus = (
    devices: { [serialNumber: string]: DeviceType },
    serialNumber: string,
    publiclyAvailable: boolean
): { [serialNumber: string]: DeviceType } => {
    let updatedDeviceObject = { ...devices };
    const currentDeviceData = devices[serialNumber];
    if (currentDeviceData) {
        const updatedDeviceProps = {
            ...currentDeviceData,
            publiclyAvailable,
        };
        updatedDeviceObject = {
            ...devices,
            [serialNumber]: updatedDeviceProps,
        };
    }
    return updatedDeviceObject;
};

const updateDevicePublicUrl = (
    devices: { [serialNumber: string]: DeviceType },
    serialNumber: string,
    publicUrlPath: string
): { [serialNumber: string]: DeviceType } => {
    let updatedDeviceObject = { ...devices };
    const currentDeviceData = devices[serialNumber];
    if (currentDeviceData) {
        const updatedDeviceProps = {
            ...currentDeviceData,
            publicUrlPath,
        };
        updatedDeviceObject = {
            ...devices,
            [serialNumber]: updatedDeviceProps,
        };
    }
    return updatedDeviceObject;
};

const getEndedMeasurements = (stateEndedSegments: EndedSegment[], newEndedSegments: EndedSegment[]): EndedSegment[] => {
    const endedSegmentsNotInNewFetch = stateEndedSegments.filter(
        segment => !newEndedSegments.find(newEndedSegment => newEndedSegment.segmentId === segment.segmentId)
    );
    return [...newEndedSegments, ...endedSegmentsNotInNewFetch];
};

type DeviceActions =
    | LocationReducerActions
    | DeviceReducerActions
    | HubReducerActions
    | LogOutSuccess
    | SegmentPropertiesDevicesReducerActions
    | FetchVirtualDevicesSuccess
    | FetchBuildingSuccess
    | SelectGroupReducerActions;

export default (state: DevicesStore = initialState, action: DeviceActions): DevicesStore => {
    switch (action.type) {
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                devices: { ...state.devices, ...action.payload.devices },
                devicesWithKeyInfo: { ...state.devicesWithKeyInfo, ...action.payload.devicesWithKeyInfo },
                hubsWithKeyInfo: { ...state.hubsWithKeyInfo, ...action.payload.hubsWithKeyInfo },
                hubs: action.payload.hubs === undefined ? state.hubs : action.payload.hubs,
                loading: false,
            };
        case FETCH_LOCATIONS_ERROR:
            return {
                ...state,
                loading: false,
            };
        case FETCH_LOCATIONS:
            return {
                ...state,
                loading: true,
            };
        case LocationActions.FetchLocationsHistorySuccess:
            return {
                ...state,
                endedMeasurements: getEndedMeasurements(state.endedMeasurements, action.payload),
            };
        case DeviceActionType.DeleteDeviceSuccess: {
            const updatedDevicesWithKeyInfo = { ...state.devicesWithKeyInfo };
            delete updatedDevicesWithKeyInfo[action.serialNumber];
            return {
                ...state,
                devicesWithKeyInfo: updatedDevicesWithKeyInfo,
            };
        }
        case SegmentPropertiesActionType.SetDevicePubliclyAvailableSuccess:
            return {
                ...state,
                devices: updateDevicePublicStatus(state.devices, action.serialNumber, action.publiclyAvailable),
            };
        case SegmentPropertiesActionType.FetchGeneratedSegmentPublicUrlSuccess:
            return {
                ...state,
                devices: updateDevicePublicUrl(
                    state.devices,
                    action.payload.serialNumber,
                    action.payload.publicUrlPath
                ),
            };
        case HubActionType.DeleteHubSuccess: {
            const updatedHubsWithKeyInfo = { ...state.hubsWithKeyInfo };
            delete updatedHubsWithKeyInfo[action.serialNumber];
            return {
                ...state,
                hubsWithKeyInfo: updatedHubsWithKeyInfo,
                hubs: deleteHub(action.serialNumber, state.hubs),
            };
        }
        case DeviceActionType.DeleteEndedSegmentSuccess:
            return {
                ...state,
                endedMeasurements: deleteEndedMeasurement(action.segmentId, state.endedMeasurements),
            };
        case DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                endedMeasurements: state.endedMeasurements.map(measurement => ({
                    ...measurement,
                    locationName: measurement.locationId === action.locationId ? 'Unknown' : measurement.locationName,
                })),
            };
        case LocationActionType.FetchVirtualDevicesSuccess: {
            return {
                ...state,
                devices: { ...state.devices, ...action.devices },
            };
        }
        case LocationActionType.FetchBuildingSuccess: {
            return {
                ...state,
                devicesWithKeyInfo: { ...state.devicesWithKeyInfo, ...action.devicesWithKeyInfo },
                hubsWithKeyInfo: { ...state.hubsWithKeyInfo, ...action.hubsWithKeyInfo },
            };
        }
        case DeviceActionType.FetchDeviceSuccess: {
            return {
                ...state,
                devices: { ...state.devices, [action.serialNumber]: action.device },
            };
        }
        case DeviceActionType.RenameSegmentSuccess: {
            const { serialNumber, name, segmentId } = action;

            const currentDeviceProps = state.devicesWithKeyInfo[serialNumber];
            const currentHubWithProps = state.hubsWithKeyInfo[serialNumber];
            const updatedDevicesWithKeyInfo = currentDeviceProps
                ? {
                      ...state.devicesWithKeyInfo,
                      [serialNumber]: { ...state.devicesWithKeyInfo[serialNumber], name },
                  }
                : state.devicesWithKeyInfo;
            const updatedHubsWithKeyInfo = currentHubWithProps
                ? {
                      ...state.hubsWithKeyInfo,
                      [serialNumber]: { ...state.hubsWithKeyInfo[serialNumber], name },
                  }
                : state.hubsWithKeyInfo;
            return {
                ...state,
                devicesWithKeyInfo: updatedDevicesWithKeyInfo,
                hubsWithKeyInfo: updatedHubsWithKeyInfo,
                devices:
                    segmentId === 'latest' && state.devices[serialNumber]
                        ? dotProp.merge(state.devices, serialNumber, { segmentName: name })
                        : state.devices,
            };
        }
        case HubActionType.FetchHubSuccess:
            return {
                ...state,
                hubs: [...state.hubs, action.hub],
            };
        case LOGOUT_SUCCESS:
        case SelectGroupActionType.SelectGroupInit:
            return initialState;
        default:
            return state;
    }
};
