import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import {
    BUILDING_THRESHOLD_BREACH_INSIGHT_ADDED,
    BUILDING_THRESHOLD_BREACH_INSIGHT_FETCHED,
    BUILDING_THRESHOLD_BREACH_TOGGLE_TREND,
} from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import InputInfo from 'commons/src/components/input/InputInfo';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { thresholdBreachElement } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { AnyDeviceType, BuildingType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import {
    createThresholdBreachInsight,
    fetchThresholdsBreachAllInsights,
    fetchThresholdsBreachSingleInsight,
} from '../../../actions/thresholdBreachInsightActions';
import { BreachType, BuildingInsight } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import AddInsightElement from './AddInsightElement';
import BuildingInsightTimeSelector from './BuildingInsightTimeSelector';
import BuildingSensorInsight from './BuildingSensorInsight';
import ThresholdBreachCsvDownload from './ThresholdBreachCsvDownload';

type ParentProps = {
    locationId: string;
    building: BuildingType;
};

type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    thresholdBreachInsights: BuildingInsight[];
    locationIdForData?: string;
    loading: boolean;
    error?: ErrorType;
    dateFormat: keyof typeof dateFormats;
};

type Props = ParentProps & StateProps;
type SelectedPeriod = {
    toDate: string;
    fromDate: string;
    resolution: Resolution;
    name: TimePeriod;
};
const BuildingInsightComponent = ({
    locationId,
    locationIdForData,
    thresholdBreachInsights,
    error,
    loading,
    building,
    devicesWithKeyInfo,
    dateFormat,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const defaultTimeSpanSelected: TimePeriod = TimePeriod.week;
    const defaultResolution: Resolution = Resolution.day;
    const buildingHasUsageHours = !!building && Object.keys(building.usageHours).length > 0;
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>({
        toDate: moment().format('YYYY-MM-DD'),
        fromDate: moment().subtract(1, defaultTimeSpanSelected).format('YYYY-MM-DD'),
        resolution: defaultResolution,
        name: defaultTimeSpanSelected,
    });
    const [displayTrendOverTime, setDisplayTrendOverTime] = useState(true);
    const [withOpeningHours, setWithOpeningHours] = useState(buildingHasUsageHours);
    const buildingThresholdBreaches = locationIdForData === locationId ? thresholdBreachInsights : [];
    const numberOfPhysicalDevicesInBuilding = (
        (building &&
            building.devices.filter(snr => {
                const deviceType = devicesWithKeyInfo[snr] && devicesWithKeyInfo[snr].type;
                return deviceType && !isVirtualDevice(deviceType as AnyDeviceType);
            })) ||
        []
    ).length;

    const onDownloadCsv = (): void => {
        setDownloadModalOpen(true);
    };

    const fetchComponentData = (from: string, to: string, res: Resolution): void => {
        const payload = {
            locationId,
            fromDate: from,
            toDate: to,
            withOpeningHours,
            resolution: res,
        };
        analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_FETCHED, { pageType: PageType.Building, payload });
        dispatch(fetchThresholdsBreachAllInsights(payload));
    };

    const toggleBetweenViews = (): void => {
        setDisplayTrendOverTime(!displayTrendOverTime);
        analyticsLogger(BUILDING_THRESHOLD_BREACH_TOGGLE_TREND, {
            pageType: PageType.Building,
            payload: { displayTrendOverTime: !displayTrendOverTime },
        });
    };

    const selectPeriod = (period: TimePeriod, from: string, to: string, res: Resolution): void => {
        setSelectedPeriod({
            fromDate: from,
            toDate: to,
            resolution: res,
            name: period,
        });
        fetchComponentData(from, to, res);
    };

    useEffect(() => {
        fetchComponentData(selectedPeriod.fromDate, selectedPeriod.toDate, selectedPeriod.resolution);
    }, [withOpeningHours]);

    const getDataForNewThresholdSettings = (
        selectedSensor: SensorTypes,
        thresholds: { value: number; type: BreachType }[],
        chartId: string
    ): void => {
        dispatch(
            fetchThresholdsBreachSingleInsight(
                {
                    locationId,
                    fromDate: selectedPeriod.fromDate,
                    toDate: selectedPeriod.toDate,
                    withOpeningHours,
                    resolution: selectedPeriod.resolution,
                },
                chartId,
                thresholds
            )
        );
    };

    const onAddNewInsight = (selectedSensor: SensorTypes): void => {
        const payload = {
            locationId,
            fromDate: selectedPeriod.fromDate,
            toDate: selectedPeriod.toDate,
            withOpeningHours,
            resolution: selectedPeriod.resolution,
        };
        analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_ADDED, {
            pageType: PageType.Building,
            payload: { ...payload, selectedSensor },
        });
        dispatch(createThresholdBreachInsight(selectedSensor, payload));
    };
    const onRefresh = (): void => {
        fetchComponentData(selectedPeriod.fromDate, selectedPeriod.toDate, selectedPeriod.resolution);
    };

    return (
        <div className="container">
            <div className="building-insight__header">
                <h2>
                    {txt('BuildingInsight.TimeOverThreshold')}
                    <InputInfo infoText="BuildingInsight.TimeOverThresholdExplained" />
                </h2>
                {downloadModalOpen && (
                    <ThresholdBreachCsvDownload
                        dateFormat={dateFormat}
                        buildingThresholdBreaches={buildingThresholdBreaches}
                        selectedPeriod={selectedPeriod}
                        setDownloadModalOpen={setDownloadModalOpen}
                        timezone={building?.timezone}
                        buildingName={building?.name || ''}
                    />
                )}
                <div className="building-insight__header__selector">
                    <FlipButton
                        onClick={toggleBetweenViews}
                        leftSelected={displayTrendOverTime}
                        leftText="BuildingInsight.TrendOverTime"
                        rightText="BuildingInsight.TotalByDevice"
                    />
                    {!loading && (
                        <PrimaryButton color="tertiary" onClick={onRefresh} icon={<MaterialIcon name="refresh" />} />
                    )}
                </div>
            </div>
            <div className="building-insight__time-selector">
                <BuildingInsightTimeSelector
                    buildingHasUsageHours={buildingHasUsageHours}
                    setWithOpeningHours={setWithOpeningHours}
                    withOpeningHours={withOpeningHours}
                    selectedPeriod={selectedPeriod.name}
                    setSelectedPeriod={selectPeriod}
                />
                <PrimaryButton
                    color="secondary"
                    disabled={loading}
                    onClick={onDownloadCsv}
                    title="CsvStrings.Csv"
                    icon={<MaterialIcon name="download" />}
                />
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={thresholdBreachElement}>
                {buildingThresholdBreaches.map((insight, i) => (
                    <BuildingSensorInsight
                        numberOfPhysicalDevicesInBuilding={numberOfPhysicalDevicesInBuilding}
                        displayTrendOverTime={displayTrendOverTime}
                        key={insight.id}
                        insight={insight}
                        isFirst={i === 0}
                        locationId={locationId}
                        getDataForNewThresholdSettings={getDataForNewThresholdSettings}
                        timeZone={building.timezone}
                        selectedPeriod={selectedPeriod}
                        withOpeningHours={withOpeningHours}
                    />
                ))}
                {error && <ResponseBox subtext={error.error} text="SomethingWentWrong" />}
                <AddInsightElement onSave={onAddNewInsight} />
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        thresholdBreachInsight: { thresholdBreachInsights, locationId: locationIdForData },
        devices: { devicesWithKeyInfo },
        requests: {
            [RequestType.FetchThresholdBreachAllInsights]: { loading, error },
        },
    } = state;
    return {
        thresholdBreachInsights,
        locationIdForData,
        loading,
        error,
        devicesWithKeyInfo,
        dateFormat,
    };
};

export default connect(mapStateToProps)(BuildingInsightComponent);
