import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BusinessRequestType as RequestType } from 'business-dashboard/src/reducers/BusinessRequestType';
import { clearError } from 'commons/src/actions/requestActions';
import { mapDeviceType } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import spinner from 'commons/src/img/spinner';
import { AnyDeviceType, LocationType } from 'commons/src/models/commonTypeScript';
import { fetchBuildingHealthDeviceStats } from '../../../actions/buildingHealthActions';
import { fetchBuilding } from '../../../actions/locationActions';
import { paths } from '../../../constants';
import { Store } from '../../../reducers';
import GeneratePdf from './CalibrationPdf';
import { allDevicesSelector } from './calibrationSelector';
import styles from './CreateCalibrationCertificate.module.scss';

type StateProps = {
    locationsLoading: boolean;
    locations: LocationType[];
    dateFormat: keyof typeof dateFormats;
};

const CreateCalibrationCertificate = ({ locationsLoading, locations, dateFormat }: StateProps): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [displayResultSection, setDisplayResultSection] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<LocationType | undefined>(undefined);

    const { offlineDevices, onlineDevices, request } = useSelector((state: Store) =>
        allDevicesSelector(state, selectedLocation?.id)
    );

    const selectBuilding = ({ id }: { id: string; inputValue: string }): void => {
        const newLocation = locations.find(location => location.id === id);

        dispatch(clearError(RequestType.FetchBuildingHealthDevicesStats));
        dispatch(clearError(RequestType.FetchBuilding));
        dispatch(fetchBuildingHealthDeviceStats(id));
        dispatch(fetchBuilding(id));

        setSelectedLocation(newLocation);
    };

    const goBack = (): void => {
        navigate({ pathname: `/${paths.reports}` });
    };

    const locationOptions = locations.map(location => ({ id: location.id, inputValue: location.name }));

    const onGenerateReport = (): void => {
        setDisplayResultSection(true);
    };

    let errorMessage = request.error ? `ErrorCodes.${request.error.error}` : undefined;
    if (selectedLocation && !onlineDevices.length) {
        errorMessage = 'Calibration.NoOnlineDevicesInBuilding';
    }

    return (
        <div className="radon-insight-view">
            <PageHeader title={txt('Calibration.CalibrationReport')} />
            <div className="page-wrapper-flex">
                <SubHeader onGoBack={goBack} backTitle="Reports.Reports" actionButtons={[]} />
            </div>
            <div className="page-wrapper-flex page-wrapper__medium--white">
                <ReactPlaceholder ready={!locationsLoading} customPlaceholder={mediumFormLoader}>
                    <div className="form form__wide-container">
                        <h2 className="header-2">
                            <span className="text-large">{txt('Calibration.ReportDescription')}</span>
                        </h2>
                        <div className={styles.pageContent}>
                            <div className={styles.dropdownWrapper}>
                                <Dropdown
                                    id="buildingSelector"
                                    title="Building.Building"
                                    options={locationOptions}
                                    loading={false}
                                    defaultOption="Select"
                                    value={selectedLocation?.name}
                                    onSelect={selectBuilding}
                                    testAttr="select-building"
                                    testId="select-building"
                                />
                                {request.loading && <div className={styles.spinnerWrapper}>{spinner}</div>}
                            </div>
                            <PrimaryButton
                                onClick={onGenerateReport}
                                color="primary"
                                disabled={!selectedLocation || onlineDevices.length === 0 || request.loading}
                                title="GenerateReport"
                            />
                        </div>
                        {errorMessage && !request.loading && (
                            <div className={styles.errorWrapper}>
                                <ResponseBox text={errorMessage} />
                            </div>
                        )}
                        {offlineDevices.length > 0 && (
                            <div>
                                <h3 className={styles.excludedDevicesHeader}>
                                    {txt('Calibration.ExcludedOfflineDevices')}
                                </h3>
                                {offlineDevices?.map(device => (
                                    <div key={device.serialNumber} className={styles.excludedDevice}>
                                        {device.segmentName}
                                        <div className="tile-header__info--chip">
                                            {txt(mapDeviceType(device.type as AnyDeviceType))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
            <div>
                {selectedLocation && displayResultSection && (
                    <ReactPdfDownloadModal title="Download" onClose={(): void => setDisplayResultSection(false)}>
                        <GeneratePdf dateFormat={dateFormat} building={selectedLocation} devices={onlineDevices} />
                    </ReactPdfDownloadModal>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        locations: { locations, loading },
        userSettings: { dateFormat },
        app: { loading: loadingApp },
    } = store;
    return {
        locations,
        locationsLoading: loadingApp || (loading && locations.length === 0),
        dateFormat,
    };
};

export default connect(mapStateToProps)(CreateCalibrationCertificate);
