import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { limitSubscription, trialSubscriptionActive } from '../../commonFunctions';
import { dateFormats } from '../../constants';
import { Store } from '../../reducers';
import { userIsHbs } from '../findUserType';
import MaterialIcon from '../MaterialIcon';
import styles from './NoSubscription.module.scss';

type ParentProps = {
    navigationType?: string;
};

type Props = ParentProps;

const NoSubscription = ({ navigationType }: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const userIsB2B = userIsHbs();
    const { subscriptionForGroup, dateFormat } = useSelector((store: Store) => store.userSettings);
    const limitingToggle = useSelector((store: Store) => store.toggles.enableDowngradeViewToggleOn);
    const subscriptionIsLimited = limitingToggle && limitSubscription(subscriptionForGroup);
    const subscriptionIsTrial = trialSubscriptionActive(subscriptionForGroup);
    if (!userIsB2B) return null;
    if (subscriptionIsTrial) {
        return (
            <div>
                <div className={styles.trialSubscription}>{txt('Subscription.Trial')}</div>
                {navigationType === 'large' ? (
                    <div className={styles.trialExpiryInfo}>
                        {txt('Subscription.TrialExpiresOn', {
                            date: dayjs(subscriptionForGroup?.limitingDate)
                                .subtract(1, 'day')
                                .format(dateFormats[dateFormat as keyof typeof dateFormats].format),
                        })}
                    </div>
                ) : null}
            </div>
        );
    }
    if (subscriptionIsLimited) {
        return (
            <div className={styles.limitedSubscription}>
                {navigationType === 'slim' ? <MaterialIcon name="money_off" /> : txt('Subscription.LimitedPlan')}
            </div>
        );
    }
    return null;
};

export default NoSubscription;
