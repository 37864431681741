import fetch from 'commons/src/api/fetch';
import getFetchHeaders from 'commons/src/api/getFetchHeaders';
import { SensorTypes, ThresholdType, ThresholdsResourceScope } from 'commons/src/models/commonEnums';

export interface ThresholdConfig {
    value: number;
    thresholdType: ThresholdType;
}

export interface ThresholdConfigSensor {
    sensor: keyof typeof SensorTypes;
    thresholds: ThresholdConfig[];
}

export interface UpdateThresholdsRequest {
    resourceScope: ThresholdsResourceScope;
    id: string;
    sensors: ThresholdConfigSensor[];
}

export const updateThresholdsApi = async (request: UpdateThresholdsRequest): Promise<void> =>
    fetch(`/thresholds`, {
        method: 'PUT',
        headers: await getFetchHeaders(),
        body: JSON.stringify(request),
    });
