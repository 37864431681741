import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchApiClients } from '../../../actions/apiClientActions';
import PageHeader from '../../../components/headers/PageHeader';
import SubHeader from '../../../components/headers/SubHeader';
import { paths } from '../../../constants';
import { ApiClient } from '../../../models/apiClient';
import { Store } from '../../../reducers';
import ApiClientForm from './ApiClientForm';

type Props = {
    apiClients: ApiClient[];
    loadingApiClients: boolean;
    loadingLocations: boolean;
};

export const AddEditApiClientViewComponent = ({
    apiClients,
    loadingApiClients,
    loadingLocations,
}: Props): React.ReactElement => {
    const { clientId } = useParams<'clientId'>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const [editingApiClient] = useState(!!clientId);

    useEffect(() => {
        if (apiClients.length === 0 && !loadingApiClients) {
            dispatch(fetchApiClients());
        }
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (clientId && !loadingApiClients && !loadingLocations) {
            const clientExists = apiClients.find(client => client.id === clientId);
            if (!clientExists) navigate({ pathname: `/${paths.apiIntegration}` });
        }
    }, [clientId, loadingApiClients, loadingLocations]);

    const close = (): void => navigate(`/${paths.apiIntegration}`);

    const actionButton: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-add-edit-api-client',
            id: 'CloseAddEditApiClient',
            title: 'Close',
            color: 'secondary',
            requiredGroupTypes: [],
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
        },
    ];

    return (
        <>
            <PageHeader title={txt(editingApiClient ? 'ApiIntegration.EditClient' : 'ApiIntegration.CreateClient')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButton} />
            </div>
            <ReactPlaceholder ready={!(loadingLocations || loadingApiClients)} customPlaceholder={mediumFormLoader}>
                <ApiClientForm />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        apiClients: { apiClients },
        commonRequests: {
            FETCH_API_CLIENTS: { loading: loadingApiClients },
        },
        locations: { loading: loadingLocations },
    } = state;
    return { apiClients, loadingLocations, loadingApiClients };
};

export default connect(mapStateToProps)(AddEditApiClientViewComponent);
