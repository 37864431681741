import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDotColorFromRating, sensorRating } from 'commons/src/commonFunctions';
import { SensorStatusCircleWithIcon } from 'commons/src/components/sensors/SensorStatusDot';
import { sensorUnits } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

type Props = {
    ranges: ThresholdRange[];
    value: number | undefined;
    unit: keyof typeof sensorUnits;
    sensorType: SensorTypes;
    headerText: string;
    hideCircle?: boolean;
};

export const CircledAverageComponent = (props: Props): React.ReactElement => {
    const { ranges, value, unit, sensorType, headerText, hideCircle } = props;

    const { t: txt } = useTranslation();
    const sensorHasValue = value !== undefined && value !== null && !Number.isNaN(value);
    const valueRange: Rating | undefined = sensorRating(ranges, value);
    const displayedUnit =
        sensorType === SensorTypes.temp ? `${sensorUnits[unit]}${(unit as string).toUpperCase()}` : sensorUnits[unit];
    const displayedValue = value && (displayedUnit === sensorUnits.pci ? value.toFixed(1) : value.toFixed(0));
    const dotColor = getDotColorFromRating(valueRange);
    return (
        <div
            className={classNames('insight-tile__content', 'insight-tile__content--sensor', {
                'insight-tile__content--sensor--small-padded': hideCircle,
            })}
        >
            {!hideCircle && <SensorStatusCircleWithIcon color={dotColor} sensor={sensorType} />}
            <div className="sensor__data">
                <div
                    className={classNames('insight-sensor__top-text', {
                        'insight-sensor__top-text--small': hideCircle,
                    })}
                >
                    {txt(headerText)}
                </div>
                <span className={classNames('insight-sensor__value', { 'insight-sensor__value--small': hideCircle })}>
                    {sensorHasValue ? displayedValue : txt('NotAvailableAbbreviation')}
                    <span className="sensor__unit">{displayedUnit}</span>
                </span>
            </div>
        </div>
    );
};

export default CircledAverageComponent;
