import React from 'react';
import { connect } from 'react-redux';
import { deviceCount } from 'commons/src/commonFunctions';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, Group } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import StartSubscription from './StartSubscription/StartSubscription';

type ParentProps = {
    subscriptionStartDate?: string;
};

type StateProps = {
    loading: boolean;
    dateFormat: keyof typeof dateFormats;
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    selectedGroup?: Group;
};

type Props = ParentProps & StateProps;

const CreateSubscription = ({
    loading,
    dateFormat,
    hubsWithKeyInfo,
    devicesWithKeyInfo,
    selectedGroup,
    subscriptionStartDate,
}: Props): React.ReactElement => {
    const selectedGroupType = selectedGroup?.groupType;
    const userAllowedToManageSubscription =
        (selectedGroupType === GroupType.business && selectedGroup && !selectedGroup.managedByUserGroup) || false;
    const customerDeviceCounts = deviceCount(devicesWithKeyInfo, hubsWithKeyInfo);

    return (
        <StartSubscription
            loading={loading}
            dateFormat={dateFormat}
            selectedGroupType={selectedGroupType}
            userAllowedToManageSubscription={userAllowedToManageSubscription}
            deviceCounts={customerDeviceCounts}
            subscriptionStartDate={subscriptionStartDate}
        />
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        locations: { loading: loadingLocations },
        devices: { loading: loadingDevices, devicesWithKeyInfo, hubsWithKeyInfo },
        userSettings: { loading: loadingSettings, dateFormat, selectedGroup },
    } = store;

    return {
        loading: loadingSettings || loadingLocations || loadingDevices,
        dateFormat,
        hubsWithKeyInfo,
        devicesWithKeyInfo,
        selectedGroup,
    };
};

export default connect(mapStateToProps)(CreateSubscription);
