import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectorList from 'commons/src/components/lists/SelectorList';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import DisplaySelector from 'commons/src/features/devicePage/dropdownOptions/DisplaySelector';
import { ScreenOptions, ViewDisplayUnits } from 'commons/src/models/commonEnums';
import { DisplayScreenOptions, DisplaySelectedScreen } from 'commons/src/models/commonTypeScript';

export type Props = {
    deviceType: string;
    configuration: {
        screenOptions: DisplayScreenOptions[];
        selectedScreens: DisplaySelectedScreen[];
        measurementUnits: ViewDisplayUnits;
    };
    updateScreenConfig: (deviceType: string, selectedScreens: DisplaySelectedScreen[]) => void;
};

export const ViewDisplaySelectorComponent = ({
    configuration,
    deviceType,
    updateScreenConfig,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const screenNumber = 0;
    const { screenOptions, selectedScreens } = configuration;
    const singleScreenOptions = screenOptions.filter(option => option.singleScreenOption).map(option => option.insight);

    const updateSelection = (id: string): void => {
        const newScreenSelection = selectedScreens;
        const selectedScreenOptions: string[] = selectedScreens[screenNumber].insights;

        let updatedScreenOptions = selectedScreenOptions;
        if (selectedScreenOptions.includes(id)) {
            updatedScreenOptions = selectedScreenOptions.filter(selectedId => selectedId !== id);
        } else if (!selectedScreenOptions.includes(id) && selectedScreenOptions.length < 2) {
            if (singleScreenOptions.includes(id as ScreenOptions)) updatedScreenOptions = [id];
            else if (selectedScreenOptions.some(option => singleScreenOptions.includes(option))) return;
            else updatedScreenOptions = [...selectedScreenOptions, id];
        }
        newScreenSelection[0] = { screenIndex: screenNumber, insights: updatedScreenOptions };
        updateScreenConfig(deviceType, newScreenSelection);
    };

    const listOption = (
        option: DisplayScreenOptions
    ): { id: string; name: string; icon: React.ReactElement; subText?: string; disabled?: boolean } => {
        const hasSubtext = !!option.subtitle || option.insight === ScreenOptions.noise;
        const sensorNotAvailable = option.insight === ScreenOptions.publicQr;

        let subText = hasSubtext ? `DisplaySettings.${option.insight}Description` : undefined;
        if (sensorNotAvailable) {
            subText = `DisplaySettings.${option.insight}Disabled`;
        }

        const icon =
            option.insight === ScreenOptions.blank ? (
                <MaterialIcon extraClass="text-paragraph" name="visibility_off" />
            ) : (
                <SensorIcon sensor={option.iconName} />
            );
        return {
            id: option.id,
            name: txt(`DisplaySettings.${option.title.key}`),
            icon,
            subText,
            disabled: sensorNotAvailable,
        };
    };

    const singleWidthOptions = screenOptions
        .filter(option => option.optionOnScreen.includes(screenNumber))
        .filter(option => option.insight !== ScreenOptions.publicQr)
        .map(option => listOption(option));

    const shouldHaveSingleSelectableOption = selectedScreens
        .map(screen => screen.insights[0])
        .some(option => singleScreenOptions.includes(option as ScreenOptions));

    return (
        <div className="page-wrapper__inner">
            <div className="change-location__form">
                <DisplaySelector
                    selectedOptions={selectedScreens[screenNumber].insights || []}
                    screenOptions={screenOptions}
                    publicUrl=""
                />
                <h3 className="centered">{txt('DeviceSettings.EditScreen')}</h3>
                <p className="text-small centered">{txt('DeviceSettings.EditScreenGuideline')}</p>
                <SelectorList
                    options={singleWidthOptions}
                    selectedOptions={selectedScreens[screenNumber].insights || []}
                    onSelect={updateSelection}
                    fullHeight
                    maxSelectable={shouldHaveSingleSelectableOption ? 1 : 2}
                    center
                />
            </div>
        </div>
    );
};

export default ViewDisplaySelectorComponent;
