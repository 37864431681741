import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { organizationInviteResponse } from '../../actions/OrganizationInvitesActions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import ModalHeader from '../../components/modals/ModalHeader';
import { organizationInviteResponses } from '../../constants';
import { OrganizationInviteType } from '../../models/commonTypeScript';
import { Store } from '../../reducers';

type Props = {
    invites: Array<OrganizationInviteType>;
    acceptInviteLoading: boolean;
    email: string;
};

const OrganizationInvitation = ({
    invite,
    email,
}: {
    invite: OrganizationInviteType;
    email: string;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <>
            <div className="modal__content__description">
                {txt('InviteOrganizationMessage')}
                <span className="modal__content__description--bold">{invite.userGroupName}</span>
                {txt('OrganizationAccount')}
            </div>
            <div className="modal__content__description modal__content__description--bold modal__content__description--padded-large">
                {email}
                {' ➞ '}
                {invite.userGroupName}
            </div>
        </>
    );
};

export const JoinInvitationComponent = ({ acceptInviteLoading, invites, email }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const invite = invites[0];

    const sendResponse = (response: string): void => {
        dispatch(organizationInviteResponse(invite, response));
    };
    const onDeny = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        sendResponse(organizationInviteResponses.deny);
    };

    const onAccept = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        sendResponse(organizationInviteResponses.accept);
    };

    return (
        <div>
            <ModalHeader headerText="Invitation" />
            <div className="modal__content__body modal__content__body--large" data-organization-invite>
                <OrganizationInvitation email={email} invite={invite} />
                <div className="modal__content__buttons modal__content__buttons--flex-end">
                    <PrimaryButton
                        title={txt('Deny')}
                        loading={false}
                        onClick={onDeny}
                        disabled={acceptInviteLoading}
                        color="alert"
                        autoFocus
                        testAttr="deny-org-invite-button"
                    />
                    <PrimaryButton
                        title={txt('Accept')}
                        loading={acceptInviteLoading}
                        onClick={onAccept}
                        disabled={acceptInviteLoading}
                        autoFocus
                        color="primary"
                        testAttr="accept-org-invite-button"
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        organizationInvites: { invites, acceptInviteLoading },
        userSettings: { email },
    } = state;
    return {
        invites,
        acceptInviteLoading,
        email,
    };
};

export default connect(mapStateToProps)(JoinInvitationComponent);
