import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { fetchApiClients } from 'commons/src/actions/apiClientActions';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { Store } from 'commons/src/reducers';
import ConsumerIntegrationMenuConfig from './ConsumerIntegrationMenuConfig';

type Props = {
    loadingApiClients: boolean;
};

export const ConsumerIntegrationsViewComponent = ({ loadingApiClients }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadingApiClients) {
            dispatch(fetchApiClients());
        }
    }, []);

    return (
        <>
            <PageHeader title={txt('Integrations.Integrations')} tabs={ConsumerIntegrationMenuConfig} />
            <div className="page-wrapper-flex settings-container">
                <Outlet />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        commonRequests: {
            FETCH_API_CLIENTS: { loading: loadingApiClients },
        },
    } = state;
    return {
        loadingApiClients,
    };
};

export default connect(mapStateToProps)(ConsumerIntegrationsViewComponent);
