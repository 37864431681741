import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from '../../constants';
import { EnumStatusColors, SensorTypes } from '../../models/commonEnums';
import SensorIcon from './SensorIcon';
import SensorStatusDot from './SensorStatusDot';

type Props = {
    sensorValue: number | null | undefined;
    unit: string;
    sensor: string;
    displayStatusBar: boolean;
    unitTop: boolean;
    dotColor: EnumStatusColors;
    isRadonAvg?: boolean;
};

export const roundSensorValue = (value: number | undefined | null, unit: string): string | undefined | null => {
    if (value !== undefined && value !== null) {
        if (value > 9999) {
            return `${(value / 1000).toFixed(0)}K`;
        }
        if ((unit === 'pci' && value % 1 !== 0) || unit === 'inhg') {
            return value.toFixed(1);
        }
        return value.toFixed(0);
    }
    return value;
};

export const SensorUnitOnlyName = ({
    name,
    sensorType,
}: {
    name: string;
    sensorType: SensorTypes;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="sensor__block sensor__block__only-name">
            <SensorIcon sensor={sensorType} />
            <span className="sensor__name">{txt(name)}</span>
        </div>
    );
};

const SensorUnit = ({
    sensor,
    sensorValue,
    unit,
    displayStatusBar,
    unitTop,
    dotColor,
    isRadonAvg,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = sensorValue !== undefined && sensorValue !== null && !Number.isNaN(sensorValue);
    const displayStatusDot = sensorHasValue && (dotColor === 'red' || dotColor === 'yellow' || displayStatusBar);

    const sensorUnit = sensorUnits[unit as keyof typeof sensorUnits];
    const value = roundSensorValue(sensorValue, unit);
    return (
        <div className={classNames('sensor__block', { 'sensor__block--no-unit': !unitTop })}>
            {isRadonAvg && (
                <div className="sensor__unit--top">
                    {sensorUnit} {isRadonAvg && `(${txt('24hourAvg')})`}
                </div>
            )}
            <div className={classNames('sensor__icon', { 'sensor__icon--unit-top': unitTop && !isRadonAvg })}>
                <SensorIcon sensor={sensor} />
            </div>
            <div className="sensor__data">
                {unitTop && !isRadonAvg && <div className="sensor__unit--top">{sensorUnit}</div>}
                <span className="sensor__value">
                    {sensorHasValue ? value : txt('NotAvailableAbbreviation')}
                    {sensorHasValue && !unitTop && (
                        <span
                            className={classNames({
                                sensor__unit: unit === 'pct',
                                'sensor__unit--risk': unit === 'riskIndex',
                            })}
                        >
                            {sensorUnit}
                        </span>
                    )}
                </span>
            </div>
            <div className={classNames('sensor__description', { 'sensor__description--padded': !displayStatusDot })}>
                {displayStatusDot && <SensorStatusDot color={dotColor} hideOkColorCoding={!displayStatusBar} />}
                <span className="sensor__name">{txt(sensor)}</span>
            </div>
        </div>
    );
};

export default SensorUnit;
