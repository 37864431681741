import React from 'react';
import { useTranslation } from 'react-i18next';
import FlipButton from 'commons/src/components/buttons/FlipButton';
import InputInfo from 'commons/src/components/input/InputInfo';
import InputLabel from 'commons/src/components/input/InputLabel';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { sensorFullNameKey } from 'commons/src/constants';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';

type Props = {
    onSelect: (sensorName: string, value: number) => void;
    interval: {
        sensorType: string;
        options: number[];
        current: number;
    };
    selectedInterval: number;
    disabled?: boolean;
    deviceType: string;
};

const ToggleSensorOnOff = (props: Props): React.ReactElement => {
    const { disabled, onSelect, interval, selectedInterval, deviceType } = props;
    const { t: txt } = useTranslation();
    const changeSelection = (leftSelected: boolean): void => {
        const sensorFrequency = leftSelected ? interval.options.find(opt => opt !== 0) : 0;
        onSelect(interval.sensorType, sensorFrequency || 0);
    };

    const sensorName =
        interval.sensorType === SensorTypes.pm25 ? 'DeviceSettings.Pm25And1' : sensorFullNameKey(interval.sensorType);
    return (
        <div className="input-container--large-margin-bottom">
            <InputLabel
                htmlFor={`toggle-${interval.sensorType}`}
                customHeader={
                    <>
                        <div className="device-svg-icons--margin-right">
                            <SensorIcon sensor={interval.sensorType} />
                        </div>
                        {txt(sensorName)}
                        {deviceType === DeviceTypeNames.viewPlusBusiness && interval.sensorType === SensorTypes.sla && (
                            <InputInfo infoText="DeviceSettings.NotAvailableForDevicesWithoutNoise" />
                        )}
                    </>
                }
            />
            <FlipButton
                id={`toggle-${interval.sensorType}`}
                onClick={changeSelection}
                leftSelected={selectedInterval !== 0}
                disabled={disabled}
                leftText="On"
                rightText="Off"
                capitalized
            />
        </div>
    );
};

export default ToggleSensorOnOff;
