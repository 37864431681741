import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from 'commons/src/constants';
import SensorStatusDot from '../../components/sensors/SensorStatusDot';
import { EnumStatusColors, SensorTypes } from '../../models/commonEnums';
import { getWeatherIcon } from '../outdoorAirQualityPage/WeatherAttrs';

type Props = {
    sensorValue: number;
    weatherIcon?: number;
    dotColor?: EnumStatusColors;
    sensorUnit: keyof typeof sensorUnits;
    sensorType: string;
    isIndoor: boolean;
};

const CompareCard = ({
    sensorType,
    sensorValue,
    sensorUnit,
    dotColor,
    weatherIcon,
    isIndoor,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const sensorHasValue = sensorValue !== undefined && !Number.isNaN(sensorValue);
    const value: string | number = Math.round(sensorValue);

    return (
        <div className={classNames('compare-card', isIndoor ? 'compare-card--indoor' : 'compare-card--outdoor')}>
            <div className="compare-card__title">
                <i
                    className={classNames(
                        'material-symbols-outlined',
                        'compare-card__icon',
                        isIndoor ? 'icon-home' : 'icon-nature_people'
                    )}
                >
                    {isIndoor ? 'home' : 'nature_people'}
                </i>
                {isIndoor ? 'Indoor' : 'Outdoor'}
                {dotColor && <SensorStatusDot color={dotColor} />}
            </div>
            <div className="sensor__data">
                <span
                    className={classNames(
                        'compare-card__sensor-value',
                        sensorType === SensorTypes.pressure && 'compare-card__sensor-value--pressure'
                    )}
                >
                    {sensorHasValue ? (
                        <div>
                            <span className="compare-card__icon--weather">
                                {weatherIcon && sensorType === SensorTypes.temp && getWeatherIcon(weatherIcon)}
                            </span>
                            <div
                                className={classNames(
                                    'compare-card__sensor-data',
                                    { 'compare-card__sensor-data--temp': sensorType === SensorTypes.temp },
                                    {
                                        'compare-card__sensor-data--temp--outdoor':
                                            sensorType === SensorTypes.temp && !isIndoor,
                                    }
                                )}
                            >
                                {value}
                                <span
                                    className={classNames(
                                        'compare-card__sensor-unit',
                                        { 'compare-card__sensor-unit--pressure': sensorType === SensorTypes.pressure },
                                        { sensor__unit: sensorType === SensorTypes.humidity }
                                    )}
                                >
                                    {sensorUnits[sensorUnit]}
                                </span>
                            </div>
                        </div>
                    ) : (
                        txt('NotAvailableAbbreviation')
                    )}
                </span>
            </div>
        </div>
    );
};

export default CompareCard;
