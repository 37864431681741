import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { IntercomAPI } from 'commons/src/components/Intercom';
import { addMqttClient } from '../../../actions/mqttIntegrationActions';
import { mqttClientNameValidation } from '../../../constants';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';
import AddEditFormComponent from './AddEditFormComponent';

type Props = {
    loadingUserSettings: boolean;
    addLoading: boolean;
};

export const MqttCreateFormComponent = ({ loadingUserSettings, addLoading }: Props): React.ReactElement => {
    const dispatch = useDispatch();

    const [clientName, setClientName] = useState('');
    const [description, setDescription] = useState('');
    const [validClient, setValidClient] = useState(true);

    const validateClient = (): boolean => {
        const validationResult =
            clientName.length > 0 &&
            clientName.indexOf(' ') === -1 &&
            RegExp(mqttClientNameValidation).test(clientName);
        setValidClient(validationResult);
        return validationResult;
    };

    const updateInput = (newName: string): void => {
        setClientName(newName);
        if (!validClient) {
            validateClient();
        }
    };

    const saveClient = (): void => {
        if (!loadingUserSettings && validateClient()) {
            IntercomAPI('trackEvent', 'add-mqtt', {
                clientName,
            });
            dispatch(
                addMqttClient({
                    clientName,
                    description,
                })
            );
        }
    };

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="settings-details-container mqtt-create-form">
                <form>
                    <AddEditFormComponent
                        onNameChanged={updateInput}
                        onDescriptionChanged={setDescription}
                        inputValid={validClient}
                    />
                    <div className="form__attr--element centered">
                        <PrimaryButton
                            onClick={saveClient}
                            type="button"
                            title="Save"
                            loading={addLoading}
                            color="primary"
                            testAttr="add-mqtt-client"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { loading: loadingUserSettings },
        requests: {
            [BusinessRequestType.AddMqttClient]: { loading: addLoading },
        },
    } = state;
    return {
        loadingUserSettings,
        addLoading,
    };
};
export default connect(mapStateToProps)(MqttCreateFormComponent);
