import React, { ReactElement, useRef, useState } from 'react';
import L, { latLngBounds, LatLngExpression } from 'leaflet';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import Tooltip from 'commons/src/components/tooltip/Tooltip';
import { excludedFilterSensors } from 'commons/src/DeviceAndSensorLists';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { FloorPlanData, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { CreateSpacePayload } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import FloorMapImage from '../FloorMapImage';
import FloorEditor from './FloorEditor';
import styles from './FloorMap.module.scss';
import FloorMapSensorsFilter from './FloorMapSensorsFilter';
import FloorMapSensorValues from './FloorMapSensorValues';
import FloorMapSpaceSelector from './FloorMapSpaceSelector';
import SpaceZone from './SpaceZone';

type Props = {
    floorData: FloorPlanData;
    mode?: SpaceFloorPlanMode;
    locationId: string;
    spaceZone?: number[][] | LatLngExpression[][];
    setSpaceZone: (zone: number[][] | LatLngExpression[][]) => void;
    selectedSpaceId: string | undefined;
    setSelectedSpace: (spaceId: string) => void;
    fullscreenMode: boolean;
    toggleFullScreenMode: () => void;
    setCreateSpacePayload: (payload: CreateSpacePayload) => void;
    setMode: (mode: SpaceFloorPlanMode | undefined) => void;
    floorNumber?: number;
};
const FloorMap = ({
    floorData,
    floorNumber,
    mode,
    locationId,
    setSelectedSpace,
    setSpaceZone,
    spaceZone,
    selectedSpaceId,
    fullscreenMode,
    toggleFullScreenMode,
    setCreateSpacePayload,
    setMode,
}: Props): ReactElement => {
    const [bounds, setBounds] = useState<L.LatLngBounds>(
        latLngBounds([
            [0, 0],
            [1000, 1000],
        ])
    );
    const [mapIsReady, setMapIsReady] = useState(false);
    const floorPlanEditorRef = useRef<{ zone: L.Polygon; marker: L.Marker }>();
    const {
        spaces: { availableSensors },
    } = useSelector((store: Store) => spacesSelector(store, locationId));

    const [filteredAvailableSensors] = useState<SensorTypes[]>(
        availableSensors.filter(it => !excludedFilterSensors.includes(it))
    );
    const [selectedSensorFilter, setSelectedSensorFilter] = useState<string | undefined>(
        filteredAvailableSensors.length > 0 ? filteredAvailableSensors[0] : undefined
    );

    const updateBounds = (): void => {
        const displayedImage = new Image();
        displayedImage.onload = (): void => {
            const { width, height } = displayedImage;
            const newBounds = latLngBounds([
                [0, width],
                [-height, 0],
            ]);
            setBounds(newBounds);
        };
        displayedImage.src = floorData.image;
    };

    const onMapReady = (): void => {
        updateBounds();
        setMapIsReady(true);
    };

    return (
        <div className={styles.mapWrapper}>
            {mode === SpaceFloorPlanMode.CREATE && spaceZone && (
                <FloorMapSpaceSelector
                    floorData={floorData}
                    floorNumber={floorNumber}
                    locationId={locationId}
                    setSelectedSpace={setSelectedSpace}
                    setSpacePayload={setCreateSpacePayload}
                    setMode={setMode}
                />
            )}
            <MapContainer
                zoomControl={false}
                scrollWheelZoom={false}
                crs={L.CRS.Simple}
                bounds={bounds}
                className={styles.container}
                zoomDelta={1}
                zoomSnap={1}
                minZoom={-4}
                whenReady={onMapReady}
            >
                <div className={styles.fullscreenButton}>
                    <Tooltip
                        text={fullscreenMode ? 'SpaceFloorPlan.ExitFullscreen' : 'SpaceFloorPlan.EnterFullscreen'}
                        position="left"
                    >
                        <TertiaryButton
                            noColor
                            onClick={toggleFullScreenMode}
                            icon={<MaterialIcon name={fullscreenMode ? 'fullscreen_exit' : 'fullscreen'} />}
                        />
                    </Tooltip>
                </div>
                {floorData.spaces.length > 0 && (
                    <FloorMapSensorsFilter
                        availableSensors={filteredAvailableSensors}
                        selectedSensor={selectedSensorFilter}
                        setSelectedSensor={setSelectedSensorFilter}
                    />
                )}
                <FloorMapImage
                    bounds={bounds}
                    floorPlanImage={floorData.image}
                    mapReady={mapIsReady}
                    onMapReady={onMapReady}
                />
                {!!mode && (
                    <FloorEditor
                        onZoneUpdated={setSpaceZone}
                        innerRef={floorPlanEditorRef}
                        spaceZone={spaceZone}
                        mode={mode}
                    />
                )}
                {floorData.spaces.map(floorSpace => (
                    <SpaceZone
                        mode={mode}
                        zone={floorSpace}
                        key={`space-zone-${floorSpace.id}`}
                        setMode={setMode}
                        setSelectedSpace={setSelectedSpace}
                        selectedSpaceId={selectedSpaceId}
                        selectedSensor={selectedSensorFilter}
                        locationId={locationId}
                    />
                ))}
                <ZoomControl position="bottomright" />
                {selectedSpaceId && mode === SpaceFloorPlanMode.INSPECT && (
                    <FloorMapSensorValues
                        locationId={locationId}
                        selectedSpaceId={selectedSpaceId}
                        onClose={(): void => setMode(undefined)}
                    />
                )}
            </MapContainer>
        </div>
    );
};

export default FloorMap;
