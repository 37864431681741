import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_SAVED_SETTINGS } from 'commons/src/analytics/AnalyticsEvents';
import { BuildingType, ErrorType, LocationDetails } from 'commons/src/models/commonTypeScript';
import { updateLocationDetails } from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import BuildingForm from './BuildingForm';

export type ParentProps = {
    onClose: () => void;
    building: BuildingType;
    locationId: string;
    closeOnSave: boolean;
};

interface StateProps {
    updateLoading: boolean;
    updateError?: ErrorType;
}

export type Props = StateProps & ParentProps;

export const EditBuildingComponent = ({
    updateLoading,
    updateError,
    onClose,
    building,
    locationId,
    closeOnSave,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const onSubmit = (locationDetails: LocationDetails): void => {
        const updatedLocation = { ...locationDetails, id: locationId };
        dispatch(updateLocationDetails(updatedLocation));
        analyticsLogger(BUILDING_SAVED_SETTINGS, {
            pageType: PageType.Building,
            hasUsageHours: Boolean(locationDetails.usageHours),
            ...locationDetails,
        });
    };

    return (
        <BuildingForm
            building={building}
            updateLoading={updateLoading}
            updateError={updateError}
            saveLocation={onSubmit}
            onClose={onClose}
            closeOnSave={closeOnSave}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            UPDATE_LOCATION_DETAILS: { loading: updateLoading, error: updateError },
        },
    } = state;
    return {
        updateLoading,
        updateError,
    };
};

export default connect(mapStateToProps)(EditBuildingComponent);
