import React from 'react';
import { useTranslation } from 'react-i18next';
import CircledSensorUnit from 'commons/src/components/sensors/CircledSensorUnit';
import { sensorUnits } from 'commons/src/constants';
import { SensorTypes } from 'commons/src/models/commonEnums';

type Props = {
    header: string;
    thresholds: number[];
    value: number | undefined;
    unit: keyof typeof sensorUnits;
    std: number | undefined;
    lld: number | undefined;
    description: string;
};

export const InsightTileComponent = ({
    header,
    thresholds,
    value,
    unit,
    description,
    std,
    lld,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className="insight-tile__measurement-result">
            <h4>{txt(header)}</h4>
            <div className="insight-tile__content">
                <CircledSensorUnit
                    value={value}
                    lld={lld}
                    sensorType={SensorTypes.radon}
                    thresholds={thresholds}
                    unit={unit}
                />
                <div className="insight-tile__content__text insight-tile__content__text-block">
                    {txt(description, {
                        std: std ? `${std.toFixed(2)} ${sensorUnits[unit]}` : ' ',
                        lld: lld ? `${lld.toFixed(2)} ${sensorUnits[unit]}` : ' ',
                    })}
                </div>
            </div>
        </div>
    );
};

export default InsightTileComponent;
