import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import EnergyInsightPage from '../../insights/energyInsight/EnergyInsightPage';
import PresenceInsightsPage from '../../insights/presenceInsights/PresenceInsightsPage';
import BuildingInsight from '../buildingInsight/BuildingInsights';

type StateProps = {
    buildings: { [buildingId: string]: BuildingType };
    fetchBuildingLoading: boolean;
};

export type Props = StateProps;

export const BuildingOverviewComponent = ({ buildings, fetchBuildingLoading }: Props): React.ReactElement => {
    const { buildingId } = useParams<'buildingId'>() as { buildingId: string };
    const building = buildings[buildingId];
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [selectedSubNavigation, setSelectedSubNavigation] = useState(searchParams.get('subPage'));
    useEffect(() => {
        const newSubPage = searchParams.get('subPage');
        if (newSubPage !== selectedSubNavigation) {
            setSelectedSubNavigation(newSubPage);
        }
    }, [location]);

    const pageContent = (): React.ReactElement => {
        if (selectedSubNavigation === 'energy') {
            return (
                <EnergyInsightPage
                    locationName={building?.name}
                    openingHoursEnabled={building?.usageHours && Object.keys(building?.usageHours).length > 0}
                />
            );
        }
        if (selectedSubNavigation === 'presence') {
            return <PresenceInsightsPage building={building} buildingId={buildingId} />;
        }
        return <BuildingInsight building={building} locationId={buildingId} />;
    };

    return (
        <ReactPlaceholder
            ready={!!building && !fetchBuildingLoading}
            className="container"
            customPlaceholder={fullwidthListElement}
        >
            {pageContent()}
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildings: { buildings },
        requests: {
            [RequestType.FetchBuilding]: { loading: fetchBuildingLoading },
        },
    } = state;

    return { buildings, fetchBuildingLoading };
};

export default connect(mapStateToProps)(BuildingOverviewComponent);
