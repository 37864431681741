import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDotColorFromRating, sensorRating } from 'commons/src/commonFunctions';
import { SensorStatusCircle } from 'commons/src/components/sensors/SensorStatusDot';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

type Props = {
    ranges: ThresholdRange[];
    average: number | undefined;
    roomType: string | undefined;
    name: string;
};

const TableRowDeviceNameAndStatus = (props: Props): React.ReactElement => {
    const { ranges, average, roomType, name } = props;
    const { t: txt } = useTranslation();
    const valueRating = sensorRating(ranges, average);
    const dotColor = getDotColorFromRating(valueRating);

    return (
        <div className="list-tile__header list-tile__header--full-width">
            <div className="list-tile__header__section">
                <SensorStatusCircle color={dotColor} />
            </div>
            <div className="list-tile__header__section">
                <div className="list-tile__header__title">
                    <h2 className="list-tile__header__title--name">{name}</h2>
                    <span className="tile-header__title--address">
                        {roomType ? txt(`RoomTypeOptions.${roomType}`) : ''}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TableRowDeviceNameAndStatus;
