import React, { useEffect, FocusEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setMobileSettingsMenuIsOpen as setMobileSettingsMenuIsOpenAction } from '../../actions/SettingsActions';
import { TabOption } from '../../models/menuModels';
import { Store } from '../../reducers';
import NavigationItem from '../menu/NavigationItem';
import SubMenuMobile from './SubMenuMobile';

type StateProps = { mobileMenuOpen: boolean };

type PassedProps = {
    menus: TabOption[];
    getSelectedMenuByPath: (path: string) => TabOption;
};

type Props = StateProps & PassedProps;

const TabMenuMobile = ({ getSelectedMenuByPath, menus, mobileMenuOpen }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();

    const selectedMenu = getSelectedMenuByPath(pathname);
    const selectedSubMenu = selectedMenu?.subNavigation?.find(element => {
        const elementPath = `${selectedMenu.path}?subPage=${element.queryParam}`;
        const elementPathWithEmptyQuery = element.queryParam.length === 0 ? selectedMenu.path : elementPath;
        return (
            elementPath === pathname ||
            elementPath === `${pathname}${search}` ||
            elementPathWithEmptyQuery === `${pathname}${search}`
        );
    });

    useEffect(() => {
        if (mobileMenuOpen && selectedMenu) {
            const focusedItem = document.getElementById(selectedMenu.text);
            if (focusedItem) {
                focusedItem.focus();
            }
        }
    }, [mobileMenuOpen]);

    const setMobileSettingsMenuIsOpen = (isOpen: boolean): void => {
        dispatch(setMobileSettingsMenuIsOpenAction(isOpen));
    };
    const closeMenu = (): void => {
        // Need the timer to allow mobile to redirect before closing the menu
        setTimeout(() => {
            setMobileSettingsMenuIsOpen(false);
        }, 100);
    };
    const navItemBlur = (event: FocusEvent<HTMLElement>): void => {
        const enterTarget = event.relatedTarget;
        const settingsMenu: Node | null = document.querySelector('.tab-menu-mobile');
        if (!enterTarget || !settingsMenu || !settingsMenu.contains(enterTarget)) {
            closeMenu();
        }
    };
    const openCloseMenu = (): void => {
        setMobileSettingsMenuIsOpen(!mobileMenuOpen);
    };

    return (
        <div className="tab-menu-mobile">
            <div
                role="button"
                id="settings-menu"
                className="tab-menu-mobile__button"
                tabIndex={0}
                onBlur={navItemBlur}
                onClick={openCloseMenu}
                onKeyUp={(event): void => {
                    if (event.key !== 'Enter') return;
                    openCloseMenu();
                }}
            >
                <div className="sidebar-navigation__items-container__item sidebar-navigation__items-container__item--active">
                    <li className="sidebar-navigation__items-container__element">
                        <span>{txt(selectedSubMenu?.title || selectedMenu.text)}</span>
                    </li>
                </div>
                <i className="material-symbols-outlined">keyboard_arrow_down</i>
            </div>
            <div className="position-root">
                <ul className={classNames('tab-menu-mobile__dropdown', { 'fixed-menu--active': mobileMenuOpen })}>
                    {menus.map(menuItem => {
                        if (menuItem.subNavigation) {
                            return (
                                <SubMenuMobile
                                    key={menuItem.text}
                                    tab={menuItem}
                                    subNavigation={menuItem.subNavigation}
                                    closeMenu={closeMenu}
                                />
                            );
                        }
                        return (
                            <NavigationItem
                                onBlur={navItemBlur}
                                key={menuItem.text}
                                path={menuItem.path}
                                onClick={closeMenu}
                                title={menuItem.text}
                                navigationType="large"
                                id={menuItem.id}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => ({
    mobileMenuOpen: store.userSettings.mobileMenuIsOpen,
});

export default connect(mapStateToProps)(TabMenuMobile);
