import { useSelector } from 'react-redux';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../../reducers';
import { buildingSelector } from '../../../../reducers/selectors/buildingSelector';

type UseSpaceGraphActionsProps = {
    fetchDigestForPeriod: (period: SelectedPeriod) => void;
    fetchDataForPeriod: (period: SelectedPeriod) => void;
    setSelectedPeriod: (period: SelectedPeriod) => void;
    selectedPeriod: SelectedPeriod;
    spaceId: string;
    locationId: string;
};

type UseSpaceGraphActionsReturnType = {
    spaceStartDate: string | undefined;
    timezone: string | undefined;
    dateFormat: string;
    selectGraphPeriod: (period: SelectedPeriod) => void;
    onRefreshData: () => void;
};

const useSpaceGraphActions = ({
    fetchDigestForPeriod,
    fetchDataForPeriod,
    setSelectedPeriod,
    selectedPeriod,
    spaceId,
    locationId,
}: UseSpaceGraphActionsProps): UseSpaceGraphActionsReturnType => {
    const spaceStartDate = useSelector((state: Store) => state.spaceData.space[spaceId]?.spaceStartDate);
    const { timezone } = useSelector((state: Store) => buildingSelector(state, locationId));
    const { dateFormat } = useSelector((state: Store) => state.userSettings);

    const selectGraphPeriod = (period: SelectedPeriod): void => {
        fetchDigestForPeriod(period);
        fetchDataForPeriod(period);
        setSelectedPeriod(period);
    };
    const onRefreshData = (): void => {
        fetchDataForPeriod(selectedPeriod);
    };

    return {
        spaceStartDate,
        timezone,
        dateFormat,
        selectGraphPeriod,
        onRefreshData,
    };
};

export default useSpaceGraphActions;
