import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BuildingType, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { IaqInsightData } from '../../../models/common';
import { Store } from '../../../reducers';
import BuildingInfo from '../reportSelector/BuildingInfo';
import SensorAverage from './SensorAverage';

export type ParentProps = {
    building: BuildingType;
    setDownloadModalOpen: (open: boolean) => void;
    downloadButtonEnabled: boolean;
    optionalBuildingProps: string[];
};

type StateProps = {
    sensorReports: { [sensor: string]: IaqInsightData };
    thresholds: { [sensor: string]: SensorThresholds };
};

export type Props = ParentProps & StateProps;

export const IAQBuildingSummaryComponent = (props: Props): React.ReactElement => {
    const { building, sensorReports, thresholds, setDownloadModalOpen, downloadButtonEnabled, optionalBuildingProps } =
        props;
    const { t: txt } = useTranslation();

    const sensorReportArray = Object.keys(sensorReports);

    return (
        <div id="buildingResultSummary" className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header insight-tile__header--no-border">
                <h3 className="insight-tile__header__name insight-tile__header__name--full-text">
                    {txt('InsightIaq.IAQReport')}
                </h3>
                <div className="form__button-container">
                    <PrimaryButton
                        onClick={(): void => setDownloadModalOpen(true)}
                        disabled={!downloadButtonEnabled}
                        title="Download"
                        color="primary"
                    />
                </div>
            </div>
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <BuildingInfo building={building} validate={false} optionalBuildingProps={optionalBuildingProps} />
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <div className="text-large text-bold form__row--padded-small">
                {txt('InsightIaq.AverageMeasurementOpeningHours')}
            </div>
            <div className="insight-tile__content insight-tile__content--wrap">
                {sensorReportArray
                    .sort((sensorA, sensorB) => sensorA.localeCompare(sensorB))
                    .map(sensor => (
                        <SensorAverage
                            key={`building-${building.id}-${sensor}-average`}
                            sensor={sensor as SensorTypes}
                            sensorReports={sensorReports}
                            thresholds={thresholds}
                        />
                    ))}
            </div>
            <p>{txt('InsightIaq.MeasurementResultExplained')}</p>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
        iaqInsight: { sensorReports },
    } = state;
    return {
        sensorReports,
        thresholds,
    };
};

export default connect(mapStateToProps)(IAQBuildingSummaryComponent);
