import React, { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectGroup as selectGroupAction } from 'commons/src/actions/selectGroupActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { getOrganizationId, getSelectedGroupForCustomer, userHasAccessToGroup } from 'commons/src/features/group';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { ErrorType, Group } from 'commons/src/models/commonTypeScript';
import { updatePartnerCustomer } from '../../../actions/partnerCustomerActions';
import { getSubscriptionForForPartnerCustomer } from '../../../actions/subscriptionActions';
import { KeyValuePairType } from '../../../models/common';
import { SubAccountDetails, UpdatePartnerSubAccountRequest } from '../../../models/partner';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { removeEmptyKeyValuePair } from '../../../sagas/segmentPropertiesSaga';
import MapInput from '../../integrations/webhooks/MapInput';
import { PendingChip } from '../customerList/CustomerListElement';
import PartnerCustomerSubscription from './PartnerCustomerSubscription';
import styles from './PartnerSubAccountDetails.module.scss';
import { PartnerUrlParams } from './PartnerSubAccountMenus';

type Props = {
    dateFormat: keyof typeof dateFormats;
    customers: SubAccountDetails[];
    loading: boolean;
    groups: Group[];
    updateCustomerLoading: boolean;
    updateCustomerError?: ErrorType;
    userRole?: Role;
};

const PartnerSubAccountDetails = ({
    customers,
    loading,
    dateFormat,
    groups,
    updateCustomerError,
    updateCustomerLoading,
    userRole,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { childUserGroupId } = useParams<PartnerUrlParams>();
    const dispatch = useDispatch();

    const customer = customers.find(customerDetails => customerDetails.userGroupId === childUserGroupId);
    const [labels, setLabels] = useState<KeyValuePairType[]>(customer ? customer.labels : []);

    const accessToDashboard = !!customer && userHasAccessToGroup(groups, customer.userGroupId);
    const canEdit = userRole && userRoleAboveRequiredLevel(userRole, RequiredRoleLevel.ABOVE_EDITOR);

    useEffect(() => {
        if (childUserGroupId) {
            dispatch(getSubscriptionForForPartnerCustomer(childUserGroupId));
        }
    }, [childUserGroupId]);

    useEffect(() => {
        if (customer?.labels && customer.labels.length > 0) {
            setLabels(customer.labels);
        }
    }, [loading, customer]);

    const redirectToDashboard = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch(selectGroupAction(getSelectedGroupForCustomer(customer)));
    };

    const onUpdateCustomer = (): void => {
        if (customer) {
            const payload: UpdatePartnerSubAccountRequest = {
                userGroupId: customer.userGroupId,
            };
            dispatch(
                updatePartnerCustomer({
                    ...payload,
                    organizationId: getOrganizationId(customer),
                    labels: removeEmptyKeyValuePair(labels),
                })
            );
        }
    };

    if (!loading && !customer) {
        return <div />;
    }

    return (
        <div className="page-wrapper__medium">
            <ReactPlaceholder ready={!loading} customPlaceholder={fullwidthListElement}>
                <h2 className="settings__header">{txt('Organization')}</h2>
                <div className="settings-details-container">
                    <div className={styles.organizationInfo}>
                        <div className={styles.dateCreated}>
                            <div className={styles.accountInfo}>
                                <div className={styles.dateCreatedHeader}>{txt('OrganizationProfile.AccountId')}</div>
                                {customer?.userGroupId}
                            </div>
                            <div className={styles.accountInfo}>
                                <div className={styles.dateCreatedHeader}>{txt('DateCreated')}: </div>
                                {customer?.createdAt
                                    ? moment(customer.createdAt).format(dateFormats[dateFormat].calendarFormat)
                                    : '-'}
                            </div>
                            {customer?.pending && (
                                <div className={styles.pending}>
                                    <PendingChip />
                                </div>
                            )}
                        </div>
                        <div className={styles.organizationAccess}>
                            <PrimaryButton
                                color="secondary"
                                onClick={redirectToDashboard}
                                type="button"
                                icon={<MaterialIcon name="arrow_forward" />}
                                title="Organization"
                                disabled={!accessToDashboard}
                                loading={loading}
                            />
                            {!accessToDashboard && (
                                <div className={styles.missingOrgAccess}>{txt('Partner.CustomerAccessRequired')}</div>
                            )}
                        </div>
                    </div>
                </div>
                <PartnerCustomerSubscription
                    deviceCounts={customer?.numberOfDevices || {}}
                    subscriptionPlan={customer?.subscriptionPlan}
                />

                <h2 className="settings__header">{txt('Partner.Labels')}</h2>
                <div className="settings-details-container">
                    <MapInput
                        id="organization-labels-selector"
                        updatePair={setLabels}
                        pairs={labels}
                        displayValidation={false}
                        keyHeader="CustomLabels.LabelKey"
                        keyHint="CustomLabels.LabelKeyRequiredHint"
                        valueHeader="CustomLabels.LabelValue"
                        valueHint=""
                        addButtonText="CustomLabels.AddLabel"
                        maxValueLength={255}
                        maxKeyLength={255}
                        maxRows={5}
                    />
                    {updateCustomerError && <ResponseBox text={txt(`ErrorCodes.${updateCustomerError.error}`)} />}
                    {customer && canEdit && (
                        <div className="form__row form__button-container">
                            <PrimaryButton
                                type="button"
                                id="updateCustomer"
                                testAttr="save-button"
                                title="Save"
                                loading={updateCustomerLoading}
                                onClick={onUpdateCustomer}
                                color="primary"
                                testId="save"
                            />
                        </div>
                    )}
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { dateFormat, loading: userSettingsLoading, groups, selectedGroup },
        partner: { customers },
        requests: {
            [RequestType.FetchPartnerDetails]: { loading: fetchPartnerDetailsLoading },
            [RequestType.UpdatePartnerCustomer]: { loading: updateCustomerLoading, error: updateCustomerError },
        },
    } = store;

    return {
        dateFormat,
        loading: fetchPartnerDetailsLoading || userSettingsLoading,
        updateCustomerLoading,
        updateCustomerError,
        groups,
        customers: customers || [],
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(PartnerSubAccountDetails);
