import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sensorUnits } from '../../constants';
import SensorIcon from './SensorIcon';
import { roundSensorValue } from './SensorUnit';

type Props = {
    sensorValue: number | null | undefined;
    unit: string;
    sensor: string;
};

const SensorValueLarge = ({ sensor, sensorValue, unit }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [sensorValueFontSize, setSensorValueFontSize] = useState('60px');

    useEffect(() => {
        let newFontSize = '60px';
        if (sensorValue && sensorValue > 99) newFontSize = '50px';
        if (sensorValue && sensorValue > 999) newFontSize = '40px';
        setSensorValueFontSize(newFontSize);
    }, [sensorValue]);

    const sensorHasValue = sensorValue !== undefined && sensorValue !== null && !Number.isNaN(sensorValue);
    const highUnit = unit === 'c' || unit === 'f';
    const value = roundSensorValue(sensorValue, unit);
    const sensorString = `${txt(sensor).toUpperCase()}${
        !highUnit ? ` (${sensorUnits[unit as keyof typeof sensorUnits]})` : ''
    }`;
    return (
        <div className="sensor--large">
            <div className="sensor__grid sensor__grid--top">
                <div className="sensor__grid-item">
                    <SensorIcon sensor={sensor} />
                </div>
                <div id="sensorGridItem" className="sensor__grid-item sensor__grid-item--right">
                    <div id="sensorValue" className="sensor--large__value" style={{ fontSize: sensorValueFontSize }}>
                        {sensorHasValue ? value : txt('NotAvailableAbbreviation')}
                        {sensorHasValue && highUnit && <span>{sensorUnits[unit as keyof typeof sensorUnits]}</span>}
                    </div>
                </div>
            </div>
            <div className="sensor__grid sensor__grid--bottom">
                <div className="sensor__grid-item" />
                <div className="sensor__grid-item sensor__grid-item--right sensor__grid-item--name">{sensorString}</div>
            </div>
        </div>
    );
};

export default SensorValueLarge;
