import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { changeLocation } from 'commons/src/actions/DeviceActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import PickExistingLocation from 'commons/src/features/addNewDevice/PickExistingLocation';
import { LocationType, DeviceType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import NewLocation from './NewLocation';

type StateProps = {
    devices: { [serialNumber: string]: DeviceType };
    locations: LocationType[];
    devicePageLoading: boolean;
    updateSegmentDetailsError?: string;
};

type ParentProps = {
    goBack: (e: SyntheticEvent<HTMLElement>) => void;
    serialNumber: string;
};

type Props = StateProps & ParentProps;

export const ChangeLocationComponent = ({
    devices,
    locations,
    serialNumber,
    goBack,
    devicePageLoading,
    updateSegmentDetailsError,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const device = devices[serialNumber];
    const deviceInitLocation = locations.find(loc => loc.devices.includes(serialNumber));
    const [updatedRoomName, setRoomName] = useState(device.segmentName || '');
    const [locationName, setLocationName] = useState(device.locationName || '');
    const [locationCoords, setLocationCoords] = useState<{ lat: number; lng: number } | undefined>();
    const [roomNameIsValid, setRoomNameAsValid] = useState(false);
    const [displayRoomNameValidation, setDisplayRoomNameValidation] = useState(false);
    const [locationNameIsValid, setLocationNameIsValid] = useState(false);
    const [displayLocationNameValidation, setDisplayLocationNameValidation] = useState(false);
    const [pickNewLocation, setPickNewLocation] = useState(false);
    const [locationId, setLocationId] = useState(deviceInitLocation ? deviceInitLocation.id : '');
    const [error, setError] = useState<string | undefined>(undefined);
    const [loadingChangeLocation, setLoadingChangeLocation] = useState(false);
    const [displayLocationValidation, setDisplayLocationValidation] = useState(false);

    useEffect(() => {
        if (!devicePageLoading && updateSegmentDetailsError) {
            setError(updateSegmentDetailsError);
        }
    }, [devicePageLoading, updateSegmentDetailsError]);

    useEffect(() => {
        if (!devicePageLoading) {
            setLoadingChangeLocation(false);
        }
    }, [devicePageLoading]);

    const onChangeRoomName = (e: SyntheticEvent<HTMLInputElement>): void => {
        const newRoomName = e.currentTarget.value.trim();
        setRoomName(newRoomName);
        setDisplayRoomNameValidation(false);
    };

    const onChangeLocationName = (e: SyntheticEvent<HTMLInputElement>): void => {
        const newLocationName = e.currentTarget.value.trim();
        setLocationName(newLocationName);
        setDisplayLocationNameValidation(false);
        setLocationNameIsValid(true);
        setLocationId('');
    };

    const selectLocationFromList = (location: { id: string; locationName: string }): void => {
        setLocationId(location.id);
        setLocationName(location.locationName);
        setDisplayLocationNameValidation(false);
        setLocationNameIsValid(true);
    };

    const validateForm = (): boolean => {
        const unusedLocationName =
            pickNewLocation &&
            !locations.map(location => location.name.toLowerCase()).includes(locationName.toLowerCase());
        const invalidLocationName = !locationName || (pickNewLocation && !unusedLocationName);
        const invalidNewLocation = pickNewLocation && !locationCoords;
        setDisplayLocationValidation(invalidNewLocation);
        setLocationNameIsValid(!invalidLocationName);
        setDisplayLocationNameValidation(invalidLocationName);
        setDisplayRoomNameValidation(!updatedRoomName);
        setRoomNameAsValid(!!updatedRoomName);
        return !invalidLocationName && !!updatedRoomName && !invalidNewLocation;
    };

    const switchLocationType = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setPickNewLocation(!pickNewLocation);
        setLocationName(pickNewLocation ? device.locationName : '');
    };

    const submitNewLocation = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const formInputValid = validateForm();
        if (formInputValid) {
            let location;
            if (pickNewLocation && locationCoords) {
                location = {
                    name: locationName,
                    lat: locationCoords.lat,
                    lng: locationCoords.lng,
                };
            }
            const payload = {
                name: updatedRoomName,
                locationName,
                serialNumber,
                locationId,
                location,
                segmentId: device.segmentId,
            };
            setLoadingChangeLocation(true);
            dispatch(changeLocation(payload));
        }
    };
    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="change-location__form">
                <Input
                    type="text"
                    id="room_name"
                    label="RoomName"
                    defaultValue={updatedRoomName}
                    required
                    maxLength={50}
                    hint="RoomNameHint"
                    isValid={roomNameIsValid}
                    validate={displayRoomNameValidation}
                    onChange={onChangeRoomName}
                />
                {pickNewLocation ? (
                    <NewLocation
                        onClick={switchLocationType}
                        validateLocationName={displayLocationNameValidation && !locationNameIsValid}
                        locationNameIsValid={locationNameIsValid}
                        defaultLocationName={locationName}
                        onInputChange={onChangeLocationName}
                        locations={locations}
                        setLocationCoords={setLocationCoords}
                        displayLocationValidation={displayLocationValidation}
                    />
                ) : (
                    <PickExistingLocation
                        value={locationName}
                        displayValidation={displayLocationNameValidation}
                        validSelection={locationNameIsValid}
                        onSelectLocation={selectLocationFromList}
                        buttonOnClick={switchLocationType}
                    />
                )}
                <h3 className="segment-properties-form__header">
                    {txt('DeviceSettingsConsumer.AboutChangingLocation')}
                </h3>
                <div className="change-location__description text-small">{txt('EditLocationDescription')}</div>
                {error && <ResponseBox text={`ErrorCodes.${error}`} />}
                <div className="change-location__form__buttons">
                    <PrimaryButton
                        color="secondary"
                        id="cancel"
                        title="Cancel"
                        loading={false}
                        disabled={loadingChangeLocation}
                        onClick={goBack}
                    />
                    <PrimaryButton
                        id="submit"
                        type={pickNewLocation ? 'button' : 'submit'}
                        title="SaveChanges"
                        loading={loadingChangeLocation}
                        color="primary"
                        onClick={submitNewLocation}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations },
        devices: { devices },
        devicePage: { loading: devicePageLoading, updateSegmentDetailsError },
    } = state;

    return {
        locations,
        devicePageLoading,
        devices,
        updateSegmentDetailsError,
    };
};

export default connect(mapStateToProps)(ChangeLocationComponent);
