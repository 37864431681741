import React from 'react';
import { useTranslation } from 'react-i18next';
import SensorSlider from 'commons/src/components/input/SensorSlider';
import { sensorUnits } from 'commons/src/constants';
import { EnumStatusColors } from 'commons/src/models/commonEnums';

type Props = {
    sensor: string;
    setThresholdValue: (value: number, sensor: string, color: string) => void;
    ranges: {
        [color: string]: {
            min: number;
            max: number;
        };
    };
    unit: keyof typeof sensorUnits;
    thresholds: { yellow: number; red: number };
};

export const SensorAlertConfigComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { ranges, setThresholdValue, unit, thresholds, sensor } = props;

    const updateSlider = (thresholdValue: number, rangeColor: string): void => {
        if (rangeColor === 'yellow' && thresholdValue < thresholds.red) {
            setThresholdValue(thresholdValue, sensor, 'yellow');
        } else if (rangeColor === 'red' && thresholdValue > thresholds.yellow) {
            setThresholdValue(thresholdValue, sensor, 'red');
        }
    };

    return (
        <div>
            <p className="building-config__text text-medium">{txt('LedBehaviour.ConfigureCo2Thresholds')}</p>
            <SensorSlider
                sensor={sensor}
                unit={unit}
                value={thresholds.yellow}
                min={ranges.yellow.min}
                max={ranges.yellow.max}
                updateSlider={updateSlider}
                color={EnumStatusColors.yellow}
            />
            <SensorSlider
                sensor={sensor}
                unit={unit}
                value={thresholds.red}
                min={ranges.red.min}
                max={ranges.red.max}
                updateSlider={updateSlider}
                color={EnumStatusColors.red}
            />
        </div>
    );
};

export default SensorAlertConfigComponent;
