import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchWebhookEvents, fetchWebhooks } from '../../../actions/integrationActions';
import { paths } from '../../../constants';
import { Webhook } from '../../../models/common';
import { Store } from '../../../reducers';
import WebhookForm from './WebhookForm';

type Props = {
    webhooks: Webhook[];
    loadingWebhooks: boolean;
    loadingLocations: boolean;
};

export const AddEditWebhookView = ({ webhooks, loadingWebhooks, loadingLocations }: Props): React.ReactElement => {
    const { webhookId } = useParams<'webhookId'>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const [editingWebhook] = useState(!!webhookId);
    useEffect(() => {
        if (webhooks.length === 0 && !loadingWebhooks) {
            dispatch(fetchWebhooks());
        }
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (webhookId && !loadingWebhooks && !loadingLocations) {
            const webhookExists = webhooks.find(webhook => webhook.id === webhookId);
            if (!webhookExists) navigate(`/${paths.webhooks}`);
            else dispatch(fetchWebhookEvents(webhookExists.id));
        }
    }, [webhookId, loadingWebhooks, loadingLocations]);

    const close = (): void => {
        navigate(`/${paths.webhooks}`);
    };

    const actionButton: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-add-edit-webhook',
            title: 'Close',
            id: 'closeAddEditWebhook',
            color: 'secondary',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt(editingWebhook ? 'Webhooks.EditWebhook' : 'Webhooks.CreateWebhook')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButton} />
            </div>
            <ReactPlaceholder ready={!(loadingLocations || loadingWebhooks)} customPlaceholder={mediumFormLoader}>
                <WebhookForm />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        webhooks: { webhooks },
        requests: {
            FETCH_WEBHOOKS: { loading: loadingWebhooks },
        },
        locations: { loading: loadingLocations },
    } = state;

    return { webhooks, loadingLocations, loadingWebhooks };
};

export default connect(mapStateToProps)(AddEditWebhookView);
