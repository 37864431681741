import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { unsubscribeFromEmailAlert } from '../../actions/UnsubscribeAlertEmailActions';
import PageHeader from '../../components/headers/PageHeader';
import MaterialIcon from '../../components/MaterialIcon';
import { mediumFormLoader } from '../../components/placeholder';
import ResponseBox from '../../components/responseMessages/ResponseBox';
import { dashboardUri } from '../../constants';
import { Store } from '../../reducers';

type Props = {
    loading: boolean;
    error: string | undefined;
};

export const UnsubscribePage = ({ loading, error }: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const [queryEmail, setQueryEmail] = useState('');
    const [search] = useSearchParams();

    const { t: txt } = useTranslation();

    const getUrlParams = (): { email: string | null; id: string | null; externalAuthId: string | null } => {
        const email = search.get('email');
        const id = search.get('id');
        const externalAuthId = search.get('externalAuthId');
        return { email, id, externalAuthId };
    };

    useEffect((): void => {
        const { email, id, externalAuthId } = getUrlParams();
        if (email && id && externalAuthId) {
            setQueryEmail(email);
            dispatch(unsubscribeFromEmailAlert(email, id, externalAuthId));
        } else {
            window.location.href = dashboardUri;
        }
    }, []);

    useEffect((): (() => void) => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            rootElement.className = 'no-margin';
        }
        return (): void => {
            if (rootElement) rootElement.className = '';
        };
    }, []);

    return (
        <div>
            <PageHeader title={txt('Unsubscribe')} />
            <div className="page-wrapper page-wrapper__medium page-wrapper__medium--white">
                <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                    <div className="form__wide-container unsubscribe-page">
                        <MaterialIcon extraClass="unsubscribe-icon" name="unsubscribe" />
                        {error && !loading ? (
                            <ResponseBox text={`ErrorCodes.${error}`} />
                        ) : (
                            <p>{txt('NotificationAlerts.UnsubscriptionSuccessful', { email: queryEmail })}</p>
                        )}
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        unsubscribeEmail: { loading, error },
    } = state;

    return {
        loading,
        error: error && error.error,
    };
};

export default connect(mapStateToProps)(UnsubscribePage);
