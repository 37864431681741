import React from 'react';
import WeatherIcon from '../../img/forecast-icons/forecast-icon-partially-cloudy';
import IconOfflineDevice from '../../img/icon-offline-device';
import IconCo2 from '../../img/sensorIcons/IconCo2';
import IconControlSignal from '../../img/sensorIcons/IconControlSignal';
import IconFlashOn from '../../img/sensorIcons/IconFlashOn';
import IconHumidity from '../../img/sensorIcons/IconHumidity';
import IconLight from '../../img/sensorIcons/IconLight';
import IconMoistGuard from '../../img/sensorIcons/IconMoistGuard';
import IconMold from '../../img/sensorIcons/IconMold';
import IconNoise from '../../img/sensorIcons/IconNoise';
import IconOccupancy from '../../img/sensorIcons/IconOccupancy';
import IconPM from '../../img/sensorIcons/IconPM';
import IconPressure from '../../img/sensorIcons/IconPressure';
import IconRadon from '../../img/sensorIcons/IconRadon';
import IconStraighten from '../../img/sensorIcons/IconStraighten';
import IconTemp from '../../img/sensorIcons/IconTemp';
import IconVentilationRate from '../../img/sensorIcons/IconVentilationRate';
import IconVirusRisk from '../../img/sensorIcons/IconVirusRisk';
import IconVoc from '../../img/sensorIcons/IconVoc';
import { SensorTypes } from '../../models/commonEnums';
import MaterialIcon from '../MaterialIcon';

const iconsForSensorType: { [key in SensorTypes]: React.ReactElement } = {
    humidity: <IconHumidity />,
    co2: <IconCo2 />,
    temp: <IconTemp />,
    radon: <IconRadon />,
    radonShortTermAvg: <IconRadon />,
    hourlyRadon: <IconRadon />,
    hourlyRadonStandardDeviation: <MaterialIcon name="iso" />,
    pressure: <IconPressure />,
    voc: <IconVoc />,
    light: <IconLight />,
    lux: <IconLight />,
    pm1: <IconPM />,
    pm10: <IconPM />,
    pm25: <IconPM />,
    mold: <IconMold />,
    virusRisk: <IconVirusRisk />,
    staleAir: <IconVirusRisk />,
    transmissionEfficiency: <IconVirusRisk />,
    virusSurvivalRate: <IconVirusRisk />,
    ventilationAmount: <IconVentilationRate />,
    ventilationRate: <IconVentilationRate />,
    airExchangeRate: <IconVentilationRate />,
    ventilationRunning: <IconVentilationRate />,
    historicVentilationRunning: <IconVentilationRate />,
    occupancy: <IconOccupancy />,
    occupants: <IconOccupancy />,
    presence: <IconOccupancy />,
    noise: <IconNoise />,
    sla: <IconNoise />,
    outdoorTemp: <IconTemp />,
    outdoorHumidity: <IconHumidity />,
    outdoorPressure: <IconPressure />,
    outdoorPm1: <IconPM />,
    outdoorPm10: <IconPM />,
    outdoorPm25: <IconPM />,
    totalPowerLost: <IconFlashOn />,
    moistGuard: <IconMoistGuard />,
    potentialPowerSaved: <IconFlashOn />,
    potentialPowerSavedPercent: <IconFlashOn />,
    zeroHeightPercent: <IconStraighten />,
    controlSignal: <IconControlSignal />,
    regulationHeight: <IconStraighten />,
    regulationPressure: <IconPressure />,
};
const SensorIcon = ({ sensor }: { sensor: string }): React.ReactElement => {
    const icons = {
        ...iconsForSensorType,
        radonLongTermAvg: iconsForSensorType.radonShortTermAvg,
        radon: iconsForSensorType.radonShortTermAvg,
        weather: <WeatherIcon />,
        deviceOffline: <IconOfflineDevice />,
        dominantPollutant: <IconOccupancy />,
        public: <MaterialIcon name="remove_red_eye" />,
    };
    return icons[sensor as keyof typeof icons] || <MaterialIcon name="help" />;
};

export default SensorIcon;
