import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import {
    BUILDING_THRESHOLD_BREACH_CREATED_REPORT,
    BUILDING_THRESHOLD_BREACH_INSIGHT_DELETED,
    BUILDING_THRESHOLD_BREACH_INSIGHT_SAVE_NEW_THRESHOLDS,
    BUILDING_THRESHOLD_BREACH_INSIGHT_SELECT_THRESHOLDS,
} from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import spinner from 'commons/src/img/spinner';
import { Resolution, Role, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import {
    deleteThresholdBreachInsight,
    updateThresholdBreachConfig,
} from '../../../actions/thresholdBreachInsightActions';
import { BreachType, BuildingInsight } from '../../../models/buildingModels';
import { SensorBreachThresholds } from '../../../models/common';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import { highChartsTimeZoneOffset } from '../../reports/insightFunctions';
import ThresholdInsightRule, { getThresholdsForSelectedSensor } from '../ThresholdInsightRule';
import BuildingInsightEmptyState from './BuildingInsightEmptyState';
import BuildingInsightTotalByDevice from './BuildingInsightTotalByDevice';
import BuildingInsightTrendOverTime from './BuildingInsightTrendOverTime';

type StateProps = {
    updateThresholdConfigLoading: boolean;
    deleteLoading: boolean;
    fetchValuesForThresholdLoading: boolean;
    thresholdOptions?: SensorBreachThresholds;
    userRole?: Role;
};
export type ParentProps = {
    numberOfPhysicalDevicesInBuilding: number;
    insight: BuildingInsight;
    displayTrendOverTime: boolean;
    isFirst: boolean;
    locationId: string;
    timeZone?: string;
    selectedPeriod: {
        toDate: string;
        fromDate: string;
        resolution: Resolution;
        name: TimePeriod;
    };
    withOpeningHours: boolean;
    getDataForNewThresholdSettings: (
        selectedSensor: SensorTypes,
        thresholds: { value: number; type: BreachType }[],
        chartId: string
    ) => void;
};

export type Props = ParentProps & StateProps;

export const BuildingSensorInsightComponent = (props: Props): React.ReactElement => {
    const {
        insight,
        numberOfPhysicalDevicesInBuilding,
        locationId,
        isFirst,
        getDataForNewThresholdSettings,
        deleteLoading,
        fetchValuesForThresholdLoading,
        updateThresholdConfigLoading,
        timeZone,
        selectedPeriod,
        thresholdOptions,
        displayTrendOverTime,
        userRole,
        withOpeningHours,
    } = props;
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { sensor, thresholds, totalDeviceData } = insight;
    const sensorThresholds = thresholdOptions && thresholdOptions[sensor];
    const { aboveThresholds, belowThresholds } = getThresholdsForSelectedSensor(sensorThresholds);

    const requiredUserRoleLevelForEdit =
        userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editBuilding);

    const [isInLoadingState, setIsInLoadingState] = useState(false);
    const [initialThresholds, setInitialThresholds] = useState(thresholds);
    const [initialStatesSet, setInitialStatesSet] = useState(false);
    const upperThreshold = thresholds.find(threshold => threshold.type === BreachType.over);
    const lowerThreshold = thresholds.find(threshold => threshold.type === BreachType.under);
    const [selectedHighThreshold, setSelectedHighThreshold] = useState<Option>(
        aboveThresholds.find(threshold => upperThreshold && threshold.id === upperThreshold.value.toString()) ||
            aboveThresholds[0]
    );
    const [selectedLowThreshold, setSelectedLowThreshold] = useState<Option | undefined>(
        thresholds.length > 1
            ? belowThresholds.find(threshold => lowerThreshold && threshold.id === lowerThreshold.value.toString()) ||
                  belowThresholds[0]
            : undefined
    );

    useEffect((): void => {
        if (timeZone) highChartsTimeZoneOffset(timeZone, selectedPeriod.resolution === Resolution.hour);
    }, [timeZone, selectedPeriod]);

    useEffect(() => {
        if (!deleteLoading && !fetchValuesForThresholdLoading && !updateThresholdConfigLoading) {
            setIsInLoadingState(false);
        }
    }, [deleteLoading, fetchValuesForThresholdLoading, updateThresholdConfigLoading]);

    const getNewThresholds = (): { value: number; type: BreachType }[] => {
        return thresholds.map(threshold => {
            let newThresholdValue = threshold.value;
            if (threshold.type === BreachType.over) {
                newThresholdValue = parseInt(selectedHighThreshold.id, 10);
            } else if (threshold.type === BreachType.under && selectedLowThreshold) {
                newThresholdValue = parseInt(selectedLowThreshold.id, 10);
            }
            return { type: threshold.type, value: newThresholdValue };
        });
    };

    useEffect(() => {
        if (initialStatesSet) {
            const newThresholds = getNewThresholds();
            setIsInLoadingState(true);
            analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_SELECT_THRESHOLDS, {
                pageType: PageType.Building,
                sensor,
                thresholds: newThresholds,
            });
            getDataForNewThresholdSettings(sensor, newThresholds, insight.id);
        } else {
            setInitialStatesSet(true);
        }
    }, [selectedHighThreshold, selectedLowThreshold]);

    const numberOfDevicesWithDataOutsideForTimeFrame = totalDeviceData && totalDeviceData.devicesInTimeFrame.length;
    const totalTimeOutside =
        insight &&
        insight.trendData &&
        insight.trendData.aggregatedDevices.reduce(
            (acc, timeSpan) => acc + timeSpan.overThresholdsTime + timeSpan.underThresholdsTime,
            0
        );

    const thresholdsChanged = thresholds.find(
        threshold =>
            !initialThresholds.find(
                initThreshold => initThreshold.value === threshold.value && initThreshold.type === threshold.type
            )
    );

    const setThresholdAsDefault = (): void => {
        if (!requiredUserRoleLevelForEdit) return;
        const newThresholdValues = getNewThresholds();
        setInitialThresholds(newThresholdValues);
        setIsInLoadingState(true);
        analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_SAVE_NEW_THRESHOLDS, {
            pageType: PageType.Building,
            sensor,
            thresholds: newThresholdValues,
        });
        dispatch(updateThresholdBreachConfig(newThresholdValues, sensor, insight.id, locationId));
    };

    const deleteInsight = (insightId: string): void => {
        setIsInLoadingState(true);
        analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_DELETED, {
            pageType: PageType.Building,
            sensor,
        });
        dispatch(deleteThresholdBreachInsight(insightId, locationId));
    };

    const renderGraphSection = (): React.ReactElement => {
        if (displayTrendOverTime) {
            return (
                <BuildingInsightTrendOverTime
                    insight={insight}
                    timeZone={timeZone}
                    selectedHighThreshold={selectedHighThreshold}
                    selectedLowThreshold={selectedLowThreshold}
                    selectedPeriod={selectedPeriod}
                />
            );
        }
        return (
            <BuildingInsightTotalByDevice
                insight={insight}
                timeZone={timeZone}
                selectedHighThreshold={selectedHighThreshold}
                selectedLowThreshold={selectedLowThreshold}
                selectedPeriod={selectedPeriod}
                sensor={sensor}
            />
        );
    };

    const redirectToReport = (): void => {
        analyticsLogger(BUILDING_THRESHOLD_BREACH_CREATED_REPORT, {
            sensor,
            withOpeningHours,
            selectedPeriod: selectedPeriod.name,
        });
        navigate(
            `threshold-breach?sensor=${sensor}&from=${selectedPeriod.fromDate}&to=${selectedPeriod.toDate}&opening-hours=${withOpeningHours}`
        );
    };

    return (
        <div className="building-insight__card">
            <div className="flex--spaced building-insight__card__header">
                <div className="flex flex--wrap">
                    <ThresholdInsightRule
                        sensor={sensor}
                        selectedHighThreshold={selectedHighThreshold}
                        selectedLowThreshold={selectedLowThreshold}
                        setSelectedHighThreshold={setSelectedHighThreshold}
                        setSelectedLowThreshold={setSelectedLowThreshold}
                        aboveThresholds={aboveThresholds}
                        belowThresholds={belowThresholds}
                        boldText
                    />
                    <div className="building-insight__card__header building-insight__card__header--padded">
                        {!(fetchValuesForThresholdLoading && isInLoadingState) &&
                            thresholdsChanged &&
                            requiredUserRoleLevelForEdit && (
                                <PrimaryButton
                                    onClick={setThresholdAsDefault}
                                    title="BuildingInsight.SetAsDefault"
                                    color="primary"
                                    loading={updateThresholdConfigLoading && isInLoadingState}
                                />
                            )}
                        {fetchValuesForThresholdLoading && isInLoadingState && (
                            <div className="small-padding-bottom">{spinner}</div>
                        )}
                    </div>
                </div>
                <div className="text-large building-insight__card__header building-insight__card__header--padded">
                    {numberOfDevicesWithDataOutsideForTimeFrame > 0 && (
                        <span>
                            {txt('BuildingInsight.TotalTimeOutsideBoundary')}{' '}
                            <span className="text-bold">
                                {numberOfDevicesWithDataOutsideForTimeFrame}/{numberOfPhysicalDevicesInBuilding}{' '}
                                {txt('DevicesLowerCase')}
                            </span>
                        </span>
                    )}
                    {!thresholdsChanged && (
                        <div className="small-padding-left">
                            <PrimaryButton
                                color="secondary"
                                onClick={redirectToReport}
                                title="BuildingInsight.CreateReport"
                            />
                        </div>
                    )}
                    {!isFirst && (
                        <PrimaryButton
                            color="tertiary"
                            onClick={(): void => deleteInsight(insight.id)}
                            icon={<MaterialIcon name="delete" />}
                            loading={deleteLoading && isInLoadingState}
                        />
                    )}
                </div>
            </div>
            {totalTimeOutside > 0 ? (
                renderGraphSection()
            ) : (
                <BuildingInsightEmptyState
                    selectedHighThreshold={selectedHighThreshold.inputValue}
                    selectedLowThreshold={selectedLowThreshold && selectedLowThreshold.inputValue}
                    loading={isInLoadingState}
                />
            )}
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        thresholdBreachInsight: { thresholdOptions },
        userSettings: { selectedGroup },
        requests: {
            [RequestType.UpdateThresholdBreachConfig]: { loading: updateThresholdConfigLoading },
            [RequestType.DeleteThresholdBreachInsight]: { loading: deleteLoading },
            [RequestType.FetchThresholdBreachSingleInsight]: { loading: fetchValuesForThresholdLoading },
        },
    } = store;
    return {
        updateThresholdConfigLoading,
        deleteLoading,
        fetchValuesForThresholdLoading,
        thresholdOptions,
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapStateToProps)(BuildingSensorInsightComponent);
