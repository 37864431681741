import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { signUpNewsletter } from '../../actions/SettingsActions';
import { analyticsLogger, PageType } from '../../analytics';
import { ACCOUNT_SUBSCRIBED_NEWSLETTER } from '../../analytics/AnalyticsEvents';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import TertiaryButton from '../../components/buttons/TertiaryButton';
import CheckBox from '../../components/input/Checkbox';
import { hubSpotNewsletterSubscriptionTypeId } from '../../constants';
import { Group } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { isB2B } from '../group';
import NewsletterSignUpConsent from './NewsletterSignupConsent';

type Props = {
    email: string;
    userName: string;
};

export const NewsletterSignUpComponent = ({ email, userName }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [agreedToTerms, setAgreeToTerms] = useState(false);
    const [validateTerms, setValidateTerms] = useState(false);
    const [openConsentModal, setOpenConsentModal] = useState(false);

    const selectedGroup = useSelector((store: Store) => store.userSettings.selectedGroup) as Group;
    const userIsB2B = isB2B(selectedGroup);

    const onChangeAgreeToTerms = (): void => {
        setAgreeToTerms(!agreedToTerms);
        setValidateTerms(false);
    };

    const sendForm = (): void => {
        const data = {
            fields: [
                { name: 'firstname', value: userName },
                { name: 'email', value: email },
            ],
            context: {
                pageUri: 'dashboard.airthings.com',
                pageName: 'Airthings dashboard',
            },
            legalConsentOptions: {
                legitimateInterest: {
                    value: true,
                    subscriptionTypeId: hubSpotNewsletterSubscriptionTypeId,
                    legalBasis: 'CUSTOMER',
                    text: txt('NewsletterConsentDescriptionSafe'),
                },
            },
        };
        dispatch(signUpNewsletter(data, userIsB2B));
    };

    const clickSignUp = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setValidateTerms(!agreedToTerms);
        if (agreedToTerms) {
            sendForm();
            analyticsLogger(ACCOUNT_SUBSCRIBED_NEWSLETTER, { pageType: PageType.Account });
        }
    };

    const openCloseModal = (): void => {
        setOpenConsentModal(!openConsentModal);
    };

    return (
        <div className="form">
            <h2 className="settings__header">{txt('Newsletter')}</h2>
            {openConsentModal && <NewsletterSignUpConsent onClose={openCloseModal} />}
            <div className="settings-details-container">
                <form noValidate>
                    <p className="text-large">{txt('SubscribeToNewsletter')}</p>
                    <div className="form__row form__attr--element">
                        <CheckBox
                            name="gdpr[785]"
                            label="EmailConsent"
                            id="gdpr_785"
                            checked={agreedToTerms}
                            hint="AgreeToTermsHint"
                            validate={validateTerms}
                            onChange={onChangeAgreeToTerms}
                            testId="agree-to-terms"
                        />
                        <TertiaryButton onClick={openCloseModal} title="ReadMore" testId="read-more" />
                    </div>
                    <PrimaryButton
                        color="secondary"
                        title="Subscribe"
                        type="submit"
                        disabled={false}
                        onClick={clickSignUp}
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        testId="subscribe"
                    />
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        userSettings: { userName, email },
    } = state;
    return { userName, email };
};

export default connect(mapStateToProps)(NewsletterSignUpComponent);
