import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import NoContent from '../../components/emptyStates/NoContent';
import { userIsHbs } from '../../components/findUserType';
import { fullwidthListElement, sensorPlaceholder } from '../../components/placeholder';
import { customPeriodName, dateFormats, mobileMax, sensorOrder } from '../../constants';
import { riskFactorSensors, virtualSensors as virtualSensorTypes } from '../../DeviceAndSensorLists';
import { DeviceTypeNames, SensorTypes } from '../../models/commonEnums';
import { FullDeviceData, SelectedPeriod } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import { CommonRequestType } from '../../reducers/requestReducer';
import CurrentValuesTile from './CurrentValuesTile';
import DeviceInfo from './DeviceInfo';
import LindabSection from './LindabTile';
import OptionsRow from './OptionsRow';
import { zoomOut } from './SensorConfig';
import SensorTile from './SensorTile';

interface Sensor {
    type: SensorTypes;
    unit: string;
    deviceId: string;
    currentValue?: number;
    thresholds: number[];
}

type StateProps = {
    devicePageDevices: {
        [serialNumber: string]: FullDeviceData;
    };
    loadingVirtualSensorData: boolean;
};

export type ParentProps = {
    fetchCustomSegment: (
        serialNumber: string,
        selectedPeriod: SelectedPeriod,
        extraParams?: { [extraParam: string]: string | undefined }
    ) => void;
    updateSelectedPeriod: (selectedPeriod: SelectedPeriod) => void;
    endedSegment: boolean;
    devicePageLoading: boolean;
    fetchedDevices: { [serialNumber: string]: FullDeviceData };
    deviceId: string;
    dateFormat: string;
    device: FullDeviceData;
    selectedPeriod: SelectedPeriod;
    serialNumber: string;
    fetching: boolean;
    lastRecord?: string;
    onPollDeviceData?: (
        data: {
            serialNumber: string;
            selectedInterval: SelectedPeriod;
            fetching: boolean;
            loading: boolean;
        },
        withPolling?: boolean
    ) => void;
    stopPollingDeviceData?: () => void;
    macAddress?: string;
    timeZone?: string;
    withPolling?: boolean;
};

export type Props = ParentProps & StateProps;

export const DevicePageBodyComponent = (props: Props): React.ReactElement => {
    const {
        deviceId,
        selectedPeriod,
        fetchedDevices,
        fetchCustomSegment,
        updateSelectedPeriod,
        onPollDeviceData,
        stopPollingDeviceData,
        endedSegment,
        devicePageLoading,
        dateFormat,
        device,
        serialNumber,
        fetching,
        lastRecord,
        devicePageDevices,
        macAddress,
        timeZone,
        loadingVirtualSensorData,
        withPolling = true,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [isZoomActive, setIsZoomActive] = useState(false);
    const [width, setWidth] = useState<number>(window.innerWidth);

    const {
        segmentStart,
        segmentEnd,
        sensors,
        currentSensorValues,
        deviceType,
        relayDevice,
        isHubConnectionLost,
        virtualSensors,
    } = device;

    const hubSyncAndConnectionLost = relayDevice === DeviceTypeNames.hub && isHubConnectionLost;
    const { t: txt } = useTranslation();

    const onWindowSizeChange = (): void => {
        setWidth(window.innerWidth);
    };

    useEffect((): (() => void) => {
        window.addEventListener('resize', onWindowSizeChange);
        return (): void => {
            window.removeEventListener('resize', onWindowSizeChange);
        };
    }, []);

    const isMobile = width <= mobileMax;

    const onResize = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
        setIsZoomActive(false);
    };

    const selectGraphPeriod = (newSelectedPeriod: SelectedPeriod, isCustom?: boolean): void => {
        if (newSelectedPeriod === undefined) {
            throw Error('selecterPeriodUndefined');
        }

        const { startDate: prevStartDate, endDate: prevEndDate } = selectedPeriod;
        const { startDate, endDate } = newSelectedPeriod;

        const hasPeriod = fetchedDevices[deviceId] && fetchedDevices[deviceId].fetchedIntervals[newSelectedPeriod.name];
        const customPeriodHasChanged = startDate !== prevStartDate || endDate !== prevEndDate;

        if (isCustom && customPeriodHasChanged) {
            fetchCustomSegment(deviceId, newSelectedPeriod);
        }
        if (stopPollingDeviceData && onPollDeviceData) {
            if (isCustom && selectedPeriod.name !== customPeriodName) {
                stopPollingDeviceData();
            }
            if (!isCustom && selectedPeriod.name !== newSelectedPeriod.name) {
                stopPollingDeviceData();
                onPollDeviceData(
                    {
                        serialNumber: deviceId,
                        selectedInterval: newSelectedPeriod,
                        fetching: !hasPeriod,
                        loading: false,
                    },
                    withPolling
                );
            }
        }
        if (selectedPeriod.name !== newSelectedPeriod.name || isCustom) {
            updateSelectedPeriod(newSelectedPeriod);
            setIsZoomActive(false);
        }
    };

    const orderSensors = (sensorsToSort: Sensor[]): Sensor[] =>
        sensorsToSort.sort(
            (a, b) => sensorOrder.indexOf(a.type as SensorTypes) - sensorOrder.indexOf(b.type as SensorTypes)
        );

    const resetZoom = (): void => {
        zoomOut();
        setIsZoomActive(false);
    };

    const displayResetZoom = (): void => setIsZoomActive(true);

    const distributeSensors = (): { orderedSensors: Sensor[]; orderedVirtualSensors: Sensor[] } => {
        const groupSensors =
            (device &&
                device.childGroups &&
                device &&
                device.childGroups
                    .flatMap(childGroup => {
                        const deviceSensors = devicePageDevices[childGroup] && devicePageDevices[childGroup].sensors;
                        return deviceSensors && deviceSensors.map(sensor => ({ deviceId: childGroup, ...sensor }));
                    })
                    .filter(sensor => !!sensor)) ||
            [];

        const sensorsWithSerialNumber = [...(sensors || []).map(sensor => ({ ...sensor, deviceId })), ...groupSensors];
        const orderedSensors = sensorsWithSerialNumber
            .filter(sensor => !virtualSensorTypes.includes(sensor.type))
            .filter(sensor => !riskFactorSensors.includes(sensor.type))
            .filter(sensor => Object.keys(SensorTypes).includes(sensor.type));
        const virtualSensorsFromSamplesEndpoint = sensorsWithSerialNumber.filter(sensor =>
            virtualSensorTypes.includes(sensor.type)
        );

        if (userIsHbs()) {
            // if user us AfB replace radonShortTermAvg with hourlyRadon
            const hourlyRadon = sensorsWithSerialNumber.find(sensor => sensor.type === SensorTypes.hourlyRadon);
            const avgRadonIndex = orderedSensors.findIndex(sensor => sensor.type === SensorTypes.radonShortTermAvg);
            if (hourlyRadon && avgRadonIndex > -1) {
                orderedSensors[avgRadonIndex] = hourlyRadon;
            }
        }

        const orderedVirtualSensors = (virtualSensors || []).map(sensor => ({ ...sensor, deviceId }));
        const allVirtualSensors = [...virtualSensorsFromSamplesEndpoint, ...orderedVirtualSensors].filter(
            sensor => sensor.type !== SensorTypes.hourlyRadon // prevent showing radon hourly twice as it replaces radonShortTermAvg above
        );
        return { orderedSensors, orderedVirtualSensors: allVirtualSensors };
    };

    const renderRows = (sensorList: Sensor[]): React.ReactElement[] =>
        orderSensors(sensorList).map(sensor => (
            <SensorTile
                timeZone={timeZone}
                isExpanded={isExpanded}
                isMobile={isMobile}
                selectedInterval={selectedPeriod}
                key={`sensor-tile-${serialNumber}-${sensor.type}`}
                sensor={sensor}
                deviceId={sensor.deviceId}
                dateFormat={dateFormat}
                displayStatusBar
                displayResetZoom={displayResetZoom}
            />
        ));

    const { orderedSensors, orderedVirtualSensors } = distributeSensors();
    const displayGraphHeader = devicePageLoading || (sensors && sensors.length > 0);

    return (
        <div className="single-device">
            <div>
                <DeviceInfo
                    dateFormat={dateFormat as keyof typeof dateFormats}
                    lastRecord={lastRecord}
                    loading={devicePageLoading}
                    endedSegment={endedSegment}
                    device={device}
                    serialNumber={serialNumber}
                />
                {macAddress && <LindabSection macAddress={macAddress} />}
                {!endedSegment && (
                    <CurrentValuesTile
                        hubSyncAndConnectionLost={hubSyncAndConnectionLost}
                        serialNumber={serialNumber}
                        deviceType={deviceType}
                        currentSensorValues={currentSensorValues}
                    />
                )}
                {displayGraphHeader && (
                    <OptionsRow
                        selectGraphPeriod={selectGraphPeriod}
                        selectedPeriod={selectedPeriod}
                        onResize={onResize}
                        isExpanded={isExpanded}
                        isMobile={isMobile}
                        segmentStartDate={segmentStart}
                        segmentEndDate={segmentEnd}
                        dateFormat={dateFormat}
                        loading={devicePageLoading}
                        endedSegment={endedSegment}
                        zoomActive={isZoomActive}
                        resetZoom={resetZoom}
                        timeZone={timeZone}
                    />
                )}
                <ReactPlaceholder
                    ready={!devicePageLoading && !fetching}
                    customPlaceholder={isMobile ? fullwidthListElement : sensorPlaceholder}
                >
                    {!devicePageLoading && device.sensors && device.sensors.length > 0 ? (
                        <>
                            {renderRows(orderedSensors)}
                            {orderedVirtualSensors.length > 0 && (
                                <>
                                    <h4 className="virtual-sensors-header">{txt('VirtualSensors')}</h4>
                                    <ReactPlaceholder
                                        ready={!loadingVirtualSensorData}
                                        customPlaceholder={isMobile ? fullwidthListElement : sensorPlaceholder}
                                    >
                                        {renderRows(orderedVirtualSensors)}
                                    </ReactPlaceholder>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="page-wrapper">
                            <NoContent noContentText="AddDeviceUsingPhone" />
                        </div>
                    )}
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        devicePage: { devices: devicePageDevices },
        commonRequests: {
            [CommonRequestType.FetchCustomSegmentVirtualSensors]: { loading: loadingCustomVirtualSensors },
            [CommonRequestType.PollVirtualDeviceSensorData]: { loading: loadingVirtualSensorData },
        },
    } = store;
    return {
        devicePageDevices,
        loadingVirtualSensorData: loadingCustomVirtualSensors || loadingVirtualSensorData,
    };
};

export default connect(mapStateToProps)(DevicePageBodyComponent);
