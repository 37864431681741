import React, { useEffect } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { deviceCount } from 'commons/src/commonFunctions';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { dateFormats } from 'commons/src/constants';
import { GroupType } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, ErrorType, Group } from 'commons/src/models/commonTypeScript';
import { getSubscriptionForEndCustomer } from '../../../actions/subscriptionActions';
import { Subscription } from '../../../models/subscriptionModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import AddSeatsPage from './addToSubscription/AddSeatsPage';

type StateProps = {
    error?: ErrorType;
    loading: boolean;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    dateFormat: keyof typeof dateFormats;
    customerSubscription?: Subscription[];
    selectedGroup?: Group;
};

type Props = StateProps;

const UpdateSubscription = ({
    error,
    loading,
    devicesWithKeyInfo,
    hubsWithKeyInfo,
    dateFormat,
    customerSubscription,
    selectedGroup,
}: Props): React.ReactElement => {
    const selectedGroupType = selectedGroup?.groupType;
    const dispatch = useDispatch();
    const userAllowedToManageSubscription =
        (selectedGroupType === GroupType.business && selectedGroup && !selectedGroup.managedByUserGroup) || false;
    const directCustomerDeviceCounts = deviceCount(devicesWithKeyInfo, hubsWithKeyInfo);

    useEffect(() => {
        if (userAllowedToManageSubscription) {
            dispatch(getSubscriptionForEndCustomer());
        }
    }, [userAllowedToManageSubscription]);

    return (
        <ReactPlaceholder ready customPlaceholder={fullwidthListElement}>
            <AddSeatsPage
                error={error}
                loading={loading}
                dateFormat={dateFormat}
                customerSubscription={customerSubscription}
                selectedGroupType={selectedGroupType}
                userAllowedToManageSubscription={userAllowedToManageSubscription}
                deviceCounts={directCustomerDeviceCounts}
            />
        </ReactPlaceholder>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { loading: loadingSettings, dateFormat, selectedGroup },
        devices: { devicesWithKeyInfo, hubsWithKeyInfo },
        requests: {
            [RequestType.GetSubscriptionForEndCustomer]: { error, loading },
        },
        subscription: { subscriptions },
    } = store;

    return {
        error,
        loading: loading || loadingSettings,
        devicesWithKeyInfo,
        hubsWithKeyInfo,
        dateFormat,
        customerSubscription: subscriptions,
        selectedGroup,
    };
};

export default connect(mapStateToProps)(UpdateSubscription);
