import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteDevice, fetchDevice, fetchDeviceConfig } from 'commons/src/actions/DeviceActions';

import { analyticsLogger, PageType } from 'commons/src/analytics';
import { DEVICE_UNPAIRED_DEVICE, DEVICE_VIEWED_SETTINGS } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import DeleteModal from 'commons/src/features/devicePage/dropdownOptions/DeleteModal';
import { DeviceConfig, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { fetchSegmentLabels, fetchSegmentProperties } from '../../../actions/segmentPropertiesActions';
import { deviceHasFeaturesInSettings } from '../../../constants';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import DeviceDetails from './DeviceDetails';
import DeviceFeatures from './DeviceFeatures';
import DeviceLabels from './DeviceLabels';
import GroupDetails from './GroupDetails';

export type Props = {
    loading: boolean;
    propertiesLoading: boolean;
    demoMode: boolean;
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    deviceConfigs: {
        [serialNumber: string]: DeviceConfig;
    };
    devicesLoading: boolean;
};

export const DeviceSettingsComponent = ({
    loading,
    propertiesLoading,
    devices,
    deviceConfigs,
    demoMode,
    devicesLoading,
}: Props): React.ReactElement => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const dispatch = useDispatch();
    const selectedDevice: DeviceWithKeyInfo = devices[serialNumber];
    const deviceConfig = deviceConfigs[serialNumber];
    const virtualDevice = selectedDevice && isVirtualDevice(selectedDevice.type);
    const [deviceDetailsOpen, setDeviceDetailsOpen] = useState(false);
    const [deviceLabelsOpen, setDeviceLabelsOpen] = useState(false);
    const [deviceFeaturesOpen, setDeviceFeaturesOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (!selectedDevice) {
            dispatch(fetchDevice(serialNumber, 'latest'));
        }
    }, []);

    useEffect((): void => {
        if (!deviceConfig && selectedDevice) {
            dispatch(fetchDeviceConfig(serialNumber, selectedDevice.segmentId || 'latest'));
        }
    }, [selectedDevice]);

    useEffect((): void => {
        if (selectedDevice) {
            analyticsLogger(DEVICE_VIEWED_SETTINGS, { pageType: PageType.Device, deviceType: selectedDevice.type });
        }
    }, [selectedDevice]);

    useEffect((): void => {
        dispatch(fetchSegmentProperties(serialNumber));
        dispatch(fetchSegmentLabels(serialNumber));
        dispatch(fetchDeviceConfig(serialNumber, (selectedDevice && selectedDevice.segmentId) || 'latest')); // use actual segmentID;
    }, []);

    const onDelete = (): void => {
        analyticsLogger(DEVICE_UNPAIRED_DEVICE, { pageType: PageType.Device });
        if (!demoMode) {
            dispatch(deleteDevice(serialNumber, selectedDevice.locationId));
        }
    };

    const onDeleteModalOpenClose = (): void => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };
    const waitForSegmentPropsWhenRegularDevice = virtualDevice ? !loading : !loading && !propertiesLoading;
    const deviceHasFeatures = selectedDevice && deviceHasFeaturesInSettings(selectedDevice.type);
    return (
        <div className="flex-column">
            <div className="page-wrapper__medium">
                <ReactPlaceholder ready={waitForSegmentPropsWhenRegularDevice} customPlaceholder={mediumFormLoader}>
                    {isDeleteModalOpen && (
                        <DeleteModal
                            locationId={selectedDevice.locationId}
                            isB2B
                            isEndedSegment={false}
                            onDeleteDevice={onDelete}
                            isModalOpen={isDeleteModalOpen}
                            onModalClose={onDeleteModalOpenClose}
                            segmentName={selectedDevice.segmentName}
                        />
                    )}
                    <div className="settings-details-container settings-details-container--center settings-details-container--margin">
                        <DeviceInfo
                            serialNumber={serialNumber}
                            deviceType={selectedDevice && selectedDevice.type}
                            segmentStart={selectedDevice && selectedDevice.segmentStarted}
                            noMargin
                        />
                    </div>
                    <div className="settings-details-container settings-details-container--margin">
                        <CollapsableSectionHeader
                            openSection={setDeviceDetailsOpen}
                            headerIsOpen={deviceDetailsOpen}
                            headerText="DeviceSettings.DeviceSettings"
                            subtext="DeviceSettings.DeviceSettingsSubHeader"
                            loading={deviceDetailsOpen && devicesLoading}
                        />
                        {deviceDetailsOpen &&
                            !!selectedDevice &&
                            (virtualDevice ? (
                                <GroupDetails
                                    onClose={(): void => setDeviceDetailsOpen(false)}
                                    serialNumber={serialNumber}
                                />
                            ) : (
                                <DeviceDetails
                                    onClose={(): void => setDeviceDetailsOpen(false)}
                                    serialNumber={serialNumber}
                                    segmentId={selectedDevice.segmentId}
                                />
                            ))}
                    </div>
                    <div className="settings-details-container settings-details-container--margin">
                        <CollapsableSectionHeader
                            openSection={setDeviceLabelsOpen}
                            headerIsOpen={deviceLabelsOpen}
                            headerText="CustomLabels.CustomLabels"
                            subtext="DeviceSettings.LabelsSubHeader"
                            loading={false}
                        />
                        {deviceLabelsOpen && (
                            <DeviceLabels goBack={(): void => setDeviceLabelsOpen(false)} serialNumber={serialNumber} />
                        )}
                    </div>
                    {deviceHasFeatures && (
                        <div className="settings-details-container settings-details-container--margin">
                            <CollapsableSectionHeader
                                openSection={setDeviceFeaturesOpen}
                                headerIsOpen={deviceFeaturesOpen}
                                headerText="DeviceSettings.Features"
                                subtext="DeviceSettings.FeaturesSubHeader"
                                loading={false}
                            />
                            {deviceFeaturesOpen && <DeviceFeatures serialNumber={serialNumber} />}
                        </div>
                    )}
                    <div className="change-location__form__buttons">
                        <PrimaryButton onClick={onDeleteModalOpenClose} color="alert" title="DeleteDevice" />
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        deviceConfig: { deviceConfigs },
        devices: { devicesWithKeyInfo: devices, loading: devicesLoading },
        userSettings: { demoMode },
        locations: { loading: locationsLoading, locations },
        requests: {
            [RequestType.FetchSegmentProperties]: { loading: propertiesLoading },
        },
        commonRequests: {
            [CommonRequestType.FetchDeviceConfig]: { loading: configLoading },
            [CommonRequestType.FetchDevice]: { loading: fetchDeviceLoading },
        },
    } = store;

    return {
        devices,
        demoMode,
        devicesLoading,
        deviceConfigs,
        loading: (locationsLoading && locations.length === 0) || configLoading || fetchDeviceLoading,
        propertiesLoading,
    };
};

export default connect(mapStateToProps)(DeviceSettingsComponent);
