import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import styles from './HighImpactFactor.module.scss';

export type Props = {
    riskSolution: string;
    setInfoModalOpen: (props: { open: boolean; selectedTab: number }) => void;
    warningLevel: Rating;
};

const getSolutionIcon = (solutionName: string): React.ReactElement => {
    switch (solutionName) {
        case 'decreaseOccupancy':
            return <SensorIcon sensor={SensorTypes.occupants} />;
        case 'ventilationRate':
            return <SensorIcon sensor={SensorTypes.ventilationRate} />;
        case 'retainHumidity':
            return <SensorIcon sensor={SensorTypes.humidity} />;
        case 'warmerTemperature':
            return <SensorIcon sensor={SensorTypes.temp} />;
        default:
            return <SensorIcon sensor={SensorTypes.virusRisk} />;
    }
};

const HighImpactFactorComponent = ({ riskSolution, setInfoModalOpen, warningLevel }: Props): React.ReactElement => {
    const openInfoModal = (): void => setInfoModalOpen({ open: true, selectedTab: 1 });
    const { t: txt } = useTranslation();
    return (
        <div className="insight-tile__content__button-row-element">
            <button type="button" className={styles.button} onClick={openInfoModal}>
                {getSolutionIcon(riskSolution)}
                <span>{txt(`VirusInsight.Solution-${riskSolution}`)}</span>
                <div
                    className={classNames({
                        [styles.okDot]: warningLevel === Rating.GOOD,
                        [styles.alertDot]: warningLevel === Rating.POOR,
                        [styles.warningDot]: warningLevel === Rating.FAIR,
                    })}
                />
            </button>
        </div>
    );
};

export default HighImpactFactorComponent;
