import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from '../../constants';
import InputLabel from '../input/InputLabel';
import styles from './MultipleAttrDropdown.module.scss';

export type Option = { id: string; inputValue: string };

export type Props = {
    title: string;
    customHeader?: React.ReactElement;
    options: Array<Option>;
    optionsAlreadySorted?: boolean;
    loading?: boolean;
    hint?: string;
    isValid?: boolean;
    validate?: boolean;
    disabled?: boolean;
    defaultOption: string;
    value: string | null | undefined;
    onSelect: (selected: { id: string; inputValue: string }) => void;
    testAttr: string;
    isRequired?: boolean;
    inputInfo?: string;
    testId?: string;
    id: string;
    noBottomMargin?: boolean;
    slim?: boolean;
};

const Dropdown = (props: Props): React.ReactElement => {
    const {
        title,
        options,
        loading,
        hint,
        isValid,
        validate,
        disabled,
        defaultOption,
        value,
        onSelect,
        optionsAlreadySorted,
        testAttr,
        isRequired = false,
        inputInfo,
        customHeader,
        testId,
        id,
        noBottomMargin = false,
        slim,
    } = props;
    const { t: txt } = useTranslation();

    const setDropdownValue = (e: SyntheticEvent<HTMLSelectElement>): void => {
        const element = e.currentTarget;
        const elementId = element.options[element.selectedIndex].id;
        const inputValue = element.value;
        onSelect({ id: elementId, inputValue });
    };

    const locale: string = localStorage.getItem(LocalStorageKeys.language) || navigator.language || 'en-US';
    const sortedOptions = optionsAlreadySorted
        ? options
        : options.sort((a: Option, b: Option) => {
              if (a.id.toLowerCase() === 'other') return 1;
              return a.inputValue.localeCompare(b.inputValue, locale);
          });

    return (
        <div className="input-container input-container--dropdown">
            {(title || customHeader) && (
                <InputLabel
                    htmlFor={id}
                    mandatory={isRequired}
                    label={title}
                    infoText={inputInfo}
                    customHeader={customHeader}
                />
            )}
            <div
                className={classNames('dropdown', {
                    'dropdown--no-bottom-margin': noBottomMargin,
                })}
            >
                <select
                    id={id}
                    className={classNames('dropdown__list', {
                        'dropdown__list--invalid': !isValid && validate,
                        'dropdown__list--disabled': disabled,
                        [styles.slim]: slim,
                    })}
                    onChange={setDropdownValue}
                    value={value || txt(defaultOption)}
                    disabled={disabled}
                    {...{ [`data-${testAttr}`]: true }}
                    {...{ 'data-testid': testId }}
                >
                    {!value && <option>{txt(defaultOption)}</option>}
                    {!loading &&
                        options &&
                        sortedOptions.map(option => (
                            <option
                                key={option.id}
                                id={option.id}
                                className="dropdown__list__option"
                                disabled={option.disabled}
                            >
                                {option.inputValue}
                            </option>
                        ))}
                </select>
            </div>
            {hint && validate && !isValid && <span className="input-container__error">{txt(hint)}</span>}
        </div>
    );
};

export default Dropdown;
