import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_HEATING_COOLING_GO_TO_SPACE } from 'commons/src/analytics/AnalyticsEvents';
import { dateFormats } from 'commons/src/constants';
import { EnergyEvents } from 'commons/src/models/commonEnums';
import { Store } from '../../../reducers';
import styles from './HeatingCoolingSelectedBar.module.scss';

type HeatingCoolingEvents = {
    timestamp: string;
    events: {
        eventType: EnergyEvents;
        devices: {
            serialNumber: string;
            deviceName: string;
        }[];
    }[];
};

export type Props = {
    heatingCoolingData: HeatingCoolingEvents[];
    selectedPoint?: string;
    dateFormat: keyof typeof dateFormats;
    locationId: string;
};

const HeatingCoolingSelectedBar = ({
    selectedPoint,
    heatingCoolingData,
    dateFormat,
    locationId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState<string>('');
    const eventsForPoint: HeatingCoolingEvents | undefined = selectedPoint
        ? heatingCoolingData.find(day => day.timestamp.includes(selectedPoint))
        : undefined;

    const devicesWithKeyInfo = useSelector((state: Store) => state.devices.devicesWithKeyInfo);
    const selectDevice = (serialNumber: string): void => {
        setSelectedDevice(serialNumber);
        const deviceInfo = devicesWithKeyInfo[serialNumber];
        if (deviceInfo) {
            analyticsLogger(BUILDING_HEATING_COOLING_GO_TO_SPACE, {
                locationId,
                spaceId: deviceInfo.spaceId,
                serialNumber,
            });
            const link = `/buildings/${locationId}/spaces/${deviceInfo.spaceId}?from=${selectedPoint}&to=${selectedPoint}`;
            const newWindow = window.open(link, '_blank', 'noopener noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.subWrapper}>
                <h3 className={styles.dateHeader}>
                    {selectedPoint
                        ? moment(selectedPoint, 'YYYY-MM-DD').format(dateFormats[dateFormat].format)
                        : txt('BuildingEnergy.SelectBar')}
                </h3>
                {eventsForPoint?.events.map(event => (
                    <div key={event.eventType}>
                        <div className={styles.eventName}>
                            <div key={event.eventType} className={styles[event.eventType]} />
                            {txt(`BuildingEnergy.${event.eventType}`)}
                        </div>
                        {event.devices.map(device => (
                            <div key={device.serialNumber}>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className={
                                        selectedDevice === device.serialNumber
                                            ? styles.clickableDeviceNameSelected
                                            : styles.clickableDeviceName
                                    }
                                    onKeyUp={(e): void => {
                                        if (e.key === 'Enter') selectDevice(device.serialNumber);
                                    }}
                                    onClick={(): void => selectDevice(device.serialNumber)}
                                >
                                    {device.deviceName}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HeatingCoolingSelectedBar;
