import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { pageNotFoundUrl } from 'commons/src/components/errorComponents/PageNotFound';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { GroupType } from 'commons/src/models/commonEnums';
import { Group } from 'commons/src/models/commonTypeScript';
import { getOrganizationLogo } from '../../actions/organizationPropertiesActions';
import { fetchPartnerOfflineDevices, fetchPartnerDetails } from '../../actions/partnerActions';
import { Store } from '../../reducers';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';
import PartnerMenuConfig from './PartnerMenuConfig';

export type Props = {
    loading: boolean;
    getOfflineLoading: boolean;
    logoImage?: string;
    selectedGroup?: Group;
    partnerUserGroupId?: string;
};

const PartnerPageComponent = ({
    loading,
    getOfflineLoading,
    logoImage,
    selectedGroup,
    partnerUserGroupId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isPartnerOrg = selectedGroup && selectedGroup.groupType === GroupType.partner;

    useEffect((): void => {
        dispatch(fetchPartnerDetails());

        if (!logoImage) {
            dispatch(getOrganizationLogo());
        }
        if (!getOfflineLoading) {
            dispatch(fetchPartnerOfflineDevices());
        }
    }, []);

    useEffect((): void => {
        if (!!selectedGroup && !loading && !isPartnerOrg) {
            navigate(pageNotFoundUrl('notAPartnerOrg'));
        }
    }, [isPartnerOrg, loading, partnerUserGroupId]);

    return (
        <>
            <PageHeader title={txt('Partner.Customers')} tabs={PartnerMenuConfig} />
            <Outlet />
        </>
    );
};

const mapStateToProps = (store: Store): Props => {
    const {
        userSettings: { loading: userSettingsLoading, selectedGroup },
        organizationProperties: { logoImage },
        requests: {
            [RequestType.GetOrganizationLogo]: { loading: getLogoLoading },
            [RequestType.FetchPartnerDetails]: { loading },
            [RequestType.FetchPartnerOfflineDevices]: { loading: getOfflineLoading },
        },
        partner: { customers },
    } = store;

    return {
        loading: (loading || userSettingsLoading || getLogoLoading) && customers.length === 0,
        getOfflineLoading,
        logoImage,
        selectedGroup,
        partnerUserGroupId: selectedGroup && selectedGroup.id,
    };
};
export default connect(mapStateToProps)(PartnerPageComponent);
