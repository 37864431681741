import React, { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MultipleAttrDropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import NumberInput from 'commons/src/components/input/Number';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import SensorIcon from 'commons/src/components/sensors/SensorIcon';
import CustomSlider from 'commons/src/components/slider/CustomSlider';
import { ThresholdType, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { SpaceThresholds } from '../../../../models/spaceModels';
import styles from './SpaceThresholdsModal.module.scss';
import useSpaceThresholdsModal from './useSpaceThresholdsModal';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    sensorType: SensorTypes;
    thresholds: SpaceThresholds;
    locationId: string;
    spaceId: string;
    selectedPeriod: SelectedPeriod;
};

const SpaceThresholdsModalComponent = ({
    onClose,
    sensorType,
    thresholds,
    locationId,
    spaceId,
    selectedPeriod,
    isOpen,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const {
        thresholdScopeOptions,
        sortedThresholdOptions,
        selectedValues,
        thumbColors,
        sliderConfig,
        loading,
        error,
        resetToDefault,
        handleThresholdScopeChange,
        selectedThresholdScope,
        descriptionKey,
        isValidNumberInput,
        validateInputValues,
        determineColorClass,
        updateThreshold,
        onSave,
        normalizeRanges,
        setSelectedValues,
        isModified,
        setIsModified,
        getButtonText,
    } = useSpaceThresholdsModal({ onClose, sensorType, thresholds, locationId, spaceId, selectedPeriod });

    const getDotColor = (thresholdType: ThresholdType): ReactElement => (
        <div className={determineColorClass(thresholdType)} />
    );

    const customHeader: ReactElement = (
        <div className={styles.header}>
            <div className={styles.headerSensor}>
                <SensorIcon sensor={sensorType} />
                <div>{txt('Space.SensorThresholds', { sensor: txt(sensorType) })}</div>
            </div>
            <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" data-testid="close-button" />} />
        </div>
    );

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} customHeader={customHeader} size="small">
            <div className={styles.body}>
                <div className={styles.description}>{txt(descriptionKey)}</div>
                <div className={styles.fullWidth}>
                    <MultipleAttrDropdown
                        id="thresoldScopeSelector"
                        options={thresholdScopeOptions}
                        onSelect={handleThresholdScopeChange}
                        value={selectedThresholdScope?.inputValue}
                        slim
                        title=""
                        customHeader={<div className={styles.customHeader}>{txt('Spaces.ThresholdSettings')}</div>}
                        defaultOption="Select"
                        testAttr="threshold-scope-dropdown"
                        data-testid="threshold-scope-dropdown"
                    />
                </div>
                <div className={styles.inputs}>
                    <div className={styles.thresholdOptions}>
                        {sortedThresholdOptions?.map((option, index) => {
                            return (
                                <div key={option.type}>
                                    <div className={styles.threshold}>
                                        <div>{getDotColor(option.type)}</div>
                                        <div className={styles.thresholdType}>
                                            {sensorType === SensorTypes.temp
                                                ? txt(`ThresholdTypeTemp.${option.type}`)
                                                : txt(`ThresholdType.${option.type}`)}
                                        </div>
                                    </div>
                                    <div className={styles.marginTop}>
                                        <NumberInput
                                            id={option.type}
                                            validate={!isValidNumberInput(index)}
                                            currentValue={selectedValues[index]?.toString() || ''}
                                            size="small"
                                            minValue={thresholds?.customOptions?.minSelectableValue}
                                            maxValue={thresholds?.customOptions?.maxSelectableValue}
                                            step={thresholds.customOptions?.thresholdDelta || 1}
                                            onChange={(event: SyntheticEvent<HTMLInputElement>): void =>
                                                updateThreshold(event, index)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.slider}>
                    <CustomSlider
                        value={selectedValues}
                        onChange={(event: Event, newValue: number[]): void => {
                            setSelectedValues(newValue);
                            setIsModified(true);
                        }}
                        ranges={normalizeRanges(thresholds?.ranges, sliderConfig.min, sliderConfig.max)}
                        min={sliderConfig.min}
                        max={sliderConfig.max}
                        step={sliderConfig.step}
                        thumbColors={thumbColors}
                        showGradient
                        valueLabelDisplay="on"
                        aria-label={txt('CustomThresholds.Slider')}
                    />
                </div>
                {error && (
                    <div className={styles.errorWrapper}>
                        <ResponseBox text={`ErrorCodes.${error.error}`} />
                    </div>
                )}
                <div />

                <div className={styles.button}>
                    <PrimaryButton
                        title={getButtonText()}
                        disabled={!validateInputValues() || !selectedThresholdScope || !isModified}
                        onClick={onSave}
                        color="primary"
                        loading={loading}
                    />
                </div>
                <div className={styles.resetButton}>
                    <TertiaryButton
                        onClick={resetToDefault}
                        title={txt('CustomThresholds.ResetToDefault')}
                        icon={<MaterialIcon name="refresh" />}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default SpaceThresholdsModalComponent;
