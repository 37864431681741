import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchApiClientSecret } from '../../../actions/apiClientActions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import InputLabel from '../../../components/input/InputLabel';
import MaterialIcon from '../../../components/MaterialIcon';
import { ClientSecret } from '../../../models/apiClient';
import { Store } from '../../../reducers';

type Props = {
    secretLoading: boolean;
    clientSecret: ClientSecret | undefined;
};

export const ApiClientSecretComponent = ({ secretLoading, clientSecret }: Props): React.ReactElement => {
    const { clientId } = useParams<'clientId'>();
    const dispatch = useDispatch();
    const clickDisplaySecret = (): void => {
        if (clientId) {
            dispatch(fetchApiClientSecret(clientId));
        }
    };

    const hasClientSecret = clientSecret && clientId && clientId === clientSecret.clientId;

    return (
        <div className="form__row">
            <div className="form__attr--element">
                <InputLabel htmlFor="accessType" label="ApiIntegration.Secret" />
                <div className="form__attr--element centered">
                    {hasClientSecret && clientSecret ? (
                        <div
                            id="clientSecret"
                            className="accounts-view__form__message accounts-view__form__message--left
                                    accounts-view__form__message--break-word"
                        >
                            {clientSecret.secret}
                        </div>
                    ) : (
                        <PrimaryButton
                            color="secondary"
                            onClick={clickDisplaySecret}
                            type="button"
                            title="ApiIntegration.DisplaySecret"
                            loading={secretLoading}
                            testAttr="display-secret"
                            icon={<MaterialIcon name="remove_red_eye" />}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        apiClients: { clientSecret },
        commonRequests: {
            FETCH_API_CLIENT_SECRET: { loading: secretLoading },
        },
    } = state;
    return {
        secretLoading,
        clientSecret,
    };
};

export default connect(mapStateToProps)(ApiClientSecretComponent);
